import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { SubmissionError } from 'redux-form'
import { processApiErrors } from 'utils'
import {
  adminGetFaqSection,
  adminCreateFaqEntry,
} from 'actions/api/admin/faq'

import AdminBase from 'components/main/admin/AdminBase'
import AdminFaqEntryEditForm from 'components/main/admin/faq/AdminFaqEntryEditForm'
import ConfirmationButton from 'components/main/ConfirmationButton'

let mounted

const AdminNewFaqEntryPage = ({
  match,
  adminGetFaqSection,
  adminCreateFaqEntry,
}) => {
  const history = useHistory()
  const faqSectionId = _.get(match, 'params.id')

  const [isLoading, setIsLoading] = useState(false)
  const [faqSection, setFaqSection] = useState({})

  const onCreateFaqEntry = (values) => adminCreateFaqEntry(faqSectionId, { faq_entry: values }).then((res) => {
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      history.push(`${_.get(faqSection, 'url')}/edit`)
    }
  })

  useEffect(() => {
    mounted = true
    setIsLoading(true)
    adminGetFaqSection(faqSectionId).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (!res.error) {
          setFaqSection(_.get(res, 'payload.faqSection'))
        }
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  return (
    <AdminBase breadcrumbs={[
      { label: "Admin", url: '/admin' },
      { label: "FAQ", url: '/admin/faq' },
      { label: "New Entry", active: true },
    ]}>
      <h1 className="mb-3">Create FAQ Entry</h1>

      <div className="mb-2 ">
        <AdminFaqEntryEditForm onSubmit={onCreateFaqEntry} buttonText="Create" />
      </div>
    </AdminBase>
  )
}

export default connect(null, {
  adminGetFaqSection,
  adminCreateFaqEntry,
})(AdminNewFaqEntryPage)
