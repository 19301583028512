import React from 'react'
import classnames from 'classnames'

export default ({ type, input, meta, children, classes }) => {
  const isError = meta.touched && meta.error
  switch (type) {
    case 'textarea':
      return (
        <React.Fragment>
          <textarea className={classnames(classes, { 'is-invalid': isError })} {...input} />
          {isError && (<div className='invalid-feedback'>{meta.error}</div>)}
        </React.Fragment>

      )
    case 'select':
      return (
        <React.Fragment>
          <select className={classnames(classes, { 'is-invalid': isError })} {...input}>
            {children}
          </select>
          {isError && (<div className='invalid-feedback'>{meta.error}</div>)}
        </React.Fragment>
      )
    // ALL INPUT TYPES
    default:
      return (
        <React.Fragment>
          <input type={type} className={classnames(classes, { 'is-invalid': isError })} {...input} />
          {isError && (<div className='invalid-feedback'>{meta.error}</div>)}
        </React.Fragment>
      )
  }
}
