import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import NavBar from 'components/main/NavBarV2'

export default () => {
  const history = useHistory()
  const [countdown, setCountdown] = useState(5)
  const redirectCountdown = () => {
    if (countdown <= 0) {
      history.push("/")
    } else {
      setCountdown(countdown - 1)
    }
  }

  useEffect(() => {
    setTimeout(() => redirectCountdown(), 1000)
  }, [countdown])

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffcf5a" />

      <div className="min-vh-100 width-100 background-yellow absolute-top-left">
        <div className='navbar-spacer'></div>
        <div className="container py-3">

          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8">
              <div className="mx-3">
                <h2 className="my-3">Email Confirmed</h2>
                Your email address has been confirmed!  You will automatically be redirected to the homepage in {countdown} seconds.
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

