import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Resources } from 'schema'
import { Link } from 'react-router-dom'
import { selectModel } from 'selectors'
import { getAlbum } from 'actions/api/albums'

import NavBar from 'components/main/NavBarV2'
import Breadcrumbs from 'components/main/Breadcrumbs'

let mounted

const AlbumPage = ({
  album={},
  match,
  getAlbum,
}) => {
  const albumId = _.get(match, 'params.id')

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    mounted = true
    setIsLoading(true)
    getAlbum(albumId).then((res) => {
      if (mounted) {
        setIsLoading(false)
      }
    })

    return () => {
      mounted = false
    }
  }, [albumId])


  return (
    <>
      <NavBar backgroundColor="#ffffff" />

      <div className='container pb-5'>
        <Breadcrumbs crumbs={[
          { label: "Home", url: "/artist" },
          { label: album.name, active: true }
        ]} />

        <h1 className="mb-3">{_.get(album, 'name')}</h1>
        <Link className="d-block mb-2" to={`${album.url}/edit`}>Edit Album</Link>

        {_.get(album, 'isHidden') && (
          <div className="alert alert-warning">
            <i className="fa fa-eye-slash mr-2"></i>
            This album is hidden
          </div>
        )}

        <h3>Songs</h3>
        <div className="list-group mb-2">
          {_.map(album.songs, (s) => !_.isNil(s) && (
            <Link key={s.id} className="list-group-item list-group-item-action" to={s.url}>
              {s.name}
              {_.get(s, 'isHidden') && (<span className='badge badge-warning ml-2'>hidden</span>)}
            </Link>
          ))}
        </div>

        <Link className="btn btn-primary mr-2" to={`${album.url}/songs/new`}>
          Add Song
        </Link>

        <Link className="btn btn-secondary mr-2" to={`${album.url}/edit`}>
          Change Song Order
        </Link>

      </div>

    </>
  )
}

export default connect((state, ownProps) => ({
  album: selectModel('albums', _.get(ownProps, 'match.params.id'), Resources.album, state),
}), {
  getAlbum,
})(AlbumPage)
