import React from 'react'

import NavBar from 'components/main/NavBarV2'

const WaitlistPage = () => (
  <>
    <NavBar backgroundColor="#ffcf5a" />

    <div className="min-vh-100 width-100 background-yellow absolute-top-left">
      <div className='navbar-spacer'></div>
      <div className="container text-center py-3">
        <h1 className="my-3">Artist Waitlist</h1>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfps0R5tK9dYWYPC7LrQzxdLrIDY_19OttWebqkmKbRBrfAWQ/viewform?embedded=true" className="wait-list" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
      </div>

    </div>
  </>
)

export default WaitlistPage
