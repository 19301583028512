export const HTTP_REGEX = /https?:\/\//i
export const URL_PARAM_REGEX = /(.*)\?(.+)$/
export const URL_BASE_REGEX = /(https?:\/\/.+)\??.*/i

export function setUrlParams(url, data) {
  const data_arr = _.map(_.keys(data), (k) => {
    return `${k}=${data[k]}`
  })
  return `${url}?${data_arr.join('&')}`
}

export function parseUrlParams(url) {
  if (_.isNil(url)) { return null }

  const params_match = url.match(URL_PARAM_REGEX)

  if (params_match) {
    const params = params_match[2]
    const params_arr = params.split('&')
    const params_obj = {}
    _.each(params_arr, (param) => {
      const key_value = param.split('=')
      params_obj[key_value[0]] = key_value[1]
    })

    return {
      url: params_match[1],
      params: params_obj
    }

  } else {
    return {
      url,
      params: {}
    }
  }
}
