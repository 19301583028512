import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { SubmissionError } from 'redux-form'
import { processApiErrors } from 'utils'
import {
  adminCreateFaqSection,
} from 'actions/api/admin/faq'

import AdminBase from 'components/main/admin/AdminBase'
import AdminFaqSectionEditForm from 'components/main/admin/faq/AdminFaqSectionEditForm'
import ConfirmationButton from 'components/main/ConfirmationButton'

const AdminNewFaqSectionPage = ({
  adminCreateFaqSection,
}) => {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)

  const onCreateFaqSection = (values) => adminCreateFaqSection({ faq_section: values }).then((res) => {
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      history.push(`${_.get(res, 'payload.faqSection.url')}/edit`)
    }
  })

  return (
    <AdminBase breadcrumbs={[
      { label: "Admin", url: '/admin' },
      { label: "FAQ", url: '/admin/faq' },
      { label: "New Section", active: true },
    ]}>
      <h1 className="mb-3">Create FAQ Section</h1>

      <div className="mb-2 ">
        <AdminFaqSectionEditForm onSubmit={onCreateFaqSection} buttonText="Create" />
      </div>
    </AdminBase>
  )
}

export default connect(null, {
  adminCreateFaqSection,
})(AdminNewFaqSectionPage)
