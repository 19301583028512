import React from 'react'
import _ from 'lodash'

import { Field, reduxForm } from 'redux-form'
import FormInput from 'components/main/FormInput'
import LoadingButton from 'components/main/LoadingButton'

import { EMAIL_REGEX } from 'utils'

const LoginForm = ({ handleSubmit, submitting }) => (
  <form className="mb-2" onSubmit={handleSubmit}>
    <div className="form-group">
      <label htmlFor="email">Email address</label>
      <Field name="email" component={FormInput} type="email" classes="form-control" />
    </div>

    <div className="form-group">
      <label htmlFor="password">Password</label>
      <Field name="password" component={FormInput} type="password" classes="form-control" />
    </div>

    <div className="form-group form-check">
      <label>
        <Field name="save_login" component={FormInput} type="checkbox" classes="form-check-input" />
        Stay logged in
      </label>
    </div>

    <div>
      <LoadingButton ownProps={{ className: 'btn btn-light', type: "submit"}} isLoading={submitting}>
        Login
      </LoadingButton>
    </div>
  </form>
)

const validate = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = "Email is required"
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = "Email is invalid"
  }

  if (!values.password) {
    errors.password = "Password is required"
  }
  return errors
}

export default reduxForm({
  form: 'loginForm',
  validate,
})(LoginForm)
