import { get } from 'actions/api'

export const EXPLORE_ARTISTS_ACTION = 'EXPLORE_ARTISTS'
export function exploreData(page) {
  return get('/api/explore_data', EXPLORE_ARTISTS_ACTION, { page })
}

export const SEARCH_ACTION = 'SEARCH_ACTION'
export function search(query, page) {
  return get('/api/search', SEARCH_ACTION, { query, page })
}
