import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'

import apiReducer from './api'
import currentUserReducer from './currentUser'

export default combineReducers({
  api: apiReducer,
  currentUserId: currentUserReducer,
  form: reduxFormReducer,
})
