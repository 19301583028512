import React from 'react'
import _ from 'lodash'

import { Field, reduxForm } from 'redux-form'
import FormInput from 'components/main/FormInput'
import LoadingButton from 'components/main/LoadingButton'

import { EMAIL_REGEX, USERNAME_REGEX } from 'utils'

const RegisterArtistForm = ({ handleSubmit, submitting }) => (
  <form className="mb-2" onSubmit={handleSubmit}>
    <h4>Artist Details</h4>

    <div className="form-group">
      <label htmlFor="artist.artist_name">Artist Name</label>
      <Field name="artist.artist_name" component={FormInput} type="text" classes="form-control" />
    </div>

    <div className="form-group">
      <label htmlFor="artist.artist_url">Artist URL</label>
      <div className="input-group">
        <div className="input-group-prepend">
          <div className="input-group-text">{window.jsonVars.baseUrl}/</div>
        </div>
        <Field name="artist.artist_url" component={FormInput} type="text" classes="form-control" />
      </div>
      <small id="artistUrlDesc" className="form-text">This is your unique splitter.fm URL</small>
    </div>


    <h4>User Details</h4>
    <div className="form-group">
      <label htmlFor="user.username">Username</label>
      <Field name="user.username" component={FormInput} type="text" classes="form-control" />
      <small id="usernameDesc" className="form-text">
        This will be your unique identifier on splitter.fm. <br/>
        It may only contain numbers, letters, dashes, underscores, and periods. <br/>
      </small>
    </div>

    <div className="form-group">
      <label htmlFor="user.email">Email address</label>
      <Field name="user.email" component={FormInput} type="email" classes="form-control" />
    </div>

    <div className="form-group">
      <label htmlFor="user.password">Password</label>
      <Field name="user.password" component={FormInput} type="password" classes="form-control" />
    </div>

    <div className="form-group">
      <label htmlFor="user.password_confirmation">Confirm Password</label>
      <Field name="user.password_confirmation" component={FormInput} type="password" classes="form-control" />
    </div>

    <div>
      <LoadingButton ownProps={{ className: 'btn btn-primary', type: "submit"}} isLoading={submitting}>
        Sign Up
      </LoadingButton>
    </div>
  </form>
)

const validate = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = "Email is required"
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = "Email is invalid"
  }

  if (!values.username) {
    errors.username = "Username is required"
  } else if (!USERNAME_REGEX.test(values.username)) {
    errors.username = "Username is invalid"
  }

  if (!values.password) {
    errors.password = "Password is required"
  }
  return errors
}

export default reduxForm({
  form: 'registerArtistForm',
  validate,
})(RegisterArtistForm)
