import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import 'whatwg-fetch'

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { apiMiddleware } from 'redux-api-middleware'
import logMiddleware from 'middleware/logMiddleware'

import ErrorHandler from 'components/ErrorHandler'
import AppRoutes from 'components/main/routes'

import appReducer from 'reducers'

let middleware = applyMiddleware(apiMiddleware)
if (window.isDevelopment) {
  middleware = applyMiddleware(apiMiddleware, logMiddleware)
}

const store = createStore(appReducer, {}, middleware)

export default () => {
  return (
    <Provider store={store}>
      <ErrorHandler>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ErrorHandler>
    </Provider>
  )
}
