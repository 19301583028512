import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Resources } from 'schema'
import { Link, useHistory } from 'react-router-dom'
import { SubmissionError } from 'redux-form'
import { processApiErrors } from 'utils'
import { selectModel } from 'selectors'
import { getStem, updateStem, uploadStem, deleteStem } from 'actions/api/stems'
import { getArtist } from 'actions/api/artists'

import NavBar from 'components/main/NavBarV2'
import Breadcrumbs from 'components/main/Breadcrumbs'
import StemEditForm from 'components/main/stems/StemEditForm'
import FileUploader from 'components/main/FileUploader'
import ConfirmationButton from 'components/main/ConfirmationButton'

import { AUDIO_FILE_TYPES, CONVERSION_STATUS } from 'static'
import { getSongTypeData } from 'utils'

const PENDING_REFRESH_TIME = 5000
let mounted, pendingTimeout

const StemEditPage = ({
  match,
  stem,
  song,
  album,
  getStem,
  updateStem,
  uploadStem,
  deleteStem,
  getArtist,
}) => {
  const history = useHistory()
  const stemId = _.get(match, 'params.id')

  const [isLoading, setIsLoading] = useState(false)
  const [stemData, setStemData] = useState(null)

  const breadcrumbs = [{ label: "Home", url: "/artist" }]
  if (!_.isEmpty(album)) { breadcrumbs.push({ label: album.name, url: album.url }) }
  breadcrumbs.push({ label: _.get(song, 'name'), url: _.get(song, 'url') })
  breadcrumbs.push({ label: _.get(stem, 'name'), active: true })

  const onUpdateStem = (values) => updateStem(stemId, { stem: values }).then((res) => {
    if (mounted) {
      setIsLoading(false)
    }
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      if (_.get(values, 'saveAndNext') && _.get(stem, 'nextStemUrl')) {
        history.push(`${_.get(stem, 'nextStemUrl')}/edit`)
      } else {
        history.push(_.get(song, 'url'))
      }
    }
  })

  const onUploadStem = (signedIds) => uploadStem(stemId, { audio_file: _.first(signedIds) }).then((res) => {
    if (!res.error) { reloadPendingStem(res) }
  })

  const onDeleteStem = () => {
    setIsLoading(true)
    deleteStem(stemId).then((res) => {
      if (mounted) { setIsLoading(false) }
      if (!res.error) {
        history.push(_.get(song, 'url'))
      }
    })
  }

  const reloadPendingStem = (res) => {
    const resStem = _.get(res, 'payload.raw.stem', {})
    if (_.get(resStem, 'conversionStatus') === CONVERSION_STATUS.PENDING) {
      pendingTimeout = setTimeout(() => {
        getStem(stemId).then((res2) => {
          if (mounted && !res2.error) {
            reloadPendingStem(res2)
          }
        })
      }, PENDING_REFRESH_TIME)
    }
  }

  useEffect(() => {
    mounted = true
    getArtist()
    getStem(stemId).then((res) => reloadPendingStem(res))

    return () => {
      mounted = false
    }
  }, [ stemId ])

  const songTypeData = getSongTypeData(_.get(song, 'songType'))

  return (
    <>
      <NavBar backgroundColor="#ffffff" />

      <div className='container pb-5'>
        <Breadcrumbs crumbs={breadcrumbs} />

        <h1 className="mb-3">{_.get(stem, 'name')}</h1>
        <h3 className="mb-3">Edit {songTypeData.stemTitle}</h3>

        {_.get(stem, 'conversionStatus') === CONVERSION_STATUS.PENDING && (
          <div className='alert alert-warning'>
            <i className='fa fa-clock mr-2'></i> Converting in progress...
          </div>
        )}
        {_.get(stem, 'conversionStatus') === CONVERSION_STATUS.FAILED && (
          <div className='alert alert-danger'>
            <i className="fa fa-exclamation-circle mr-2 grey"></i> Failed conversion!
          </div>
        )}

        <div className="mb-2">
          <StemEditForm onSubmit={onUpdateStem} stem={stem} album={album} song={song} buttonText="Update" />
        </div>

        <ConfirmationButton
          ownProps={{ className: 'btn btn-danger' }}
          confirmText={`Really Delete ${songTypeData.stemTitle}?`}
          isLoading={isLoading}
          onSuccess={onDeleteStem}
        >Delete {songTypeData.stemTitle}</ConfirmationButton>

        {_.get(stem, 'audioFileUrl') && (
          <>
            <div className='border-bottom border-dark my-3'></div>

            <div className="mb-3">
              <h3>Current Audio File</h3>
              {_.get(stem, 'originalFilename.length') > 0 && (
                <p>
                  <strong>Original Filename:</strong> {_.get(stem, 'originalFilename')}
                </p>
              )}

              <audio controls>
                <source src={_.get(stem, 'audioFileUrl')} type="audio/mpeg" />
              </audio>

              <img className='w-100' src={_.get(stem, 'imageFileUrl')} />
            </div>

            <h3>Change Audio File</h3>
            <div className="alert alert-info">
              The following file types are accepted
              <ul className="mb-0">
                <li><strong>.wav</strong> smaller than 120MB</li>
                <li><strong>.aif</strong> smaller than 120MB</li>
                <li><strong>.mp3</strong> smaller than 12MB</li>
              </ul>
            </div>
            <FileUploader
              onUploadSuccess={onUploadStem}
              setFileData={setStemData}
              fileData={stemData}
              fileTypes={AUDIO_FILE_TYPES}
            />
          </>
        )}
      </div>

    </>
  )
}

export default connect((state, ownProps) => {
  const stem = selectModel('stems', _.get(ownProps, 'match.params.id'), Resources.stem, state)
  let song = null
  let album = null
  if (!_.isNil(_.get(stem, 'songId'))) { song = selectModel('songs', stem.songId, Resources.song, state) }
  if (!_.isNil(_.get(song, 'albumId'))) { album = selectModel('albums', song.albumId, Resources.album, state) }
  return {
    stem,
    song,
    album,
  }
}, {
  getArtist,
  getStem,
  updateStem,
  uploadStem,
  deleteStem,
})(StemEditPage)
