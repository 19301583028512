import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import FormInput from 'components/main/FormInput'
import LoadingButton from 'components/main/LoadingButton'

import { EMAIL_REGEX } from 'utils'

let AccountForm = ({ user, handleSubmit, submitting, isSuccess }) => (
  <form className="mb-2" onSubmit={handleSubmit}>
    <div className="form-group mb-4">
      <label htmlFor="username">Username</label>
      <Field name="username" component={FormInput} type="text" classes="form-control" />
      <small id="usernameDesc" className="form-text">
        This will be your unique identifier on splitter.fm. <br/>
        It may only contain numbers, letters, dashes, underscores, and periods. <br/>
      </small>
    </div>

    <h3>Email Notification Settings</h3>
    <div className="form-check mb-3">
      <label>
        <Field name="is_comment_reply_notifications" component={FormInput} type="checkbox" classes="form-check-input" />
        Receive notifications for replies to your comments
      </label>
    </div>

    {!_.isNil(user.artistId) && (
      <fieldset className='fieldset mb-3'>
        <legend>Artist Comment Notifications</legend>
        <div className="form-check">
          <label className="form-check-label">
            <Field component={FormInput} type="radio" classes="form-check-input"
              name="artist_comment_notifications"
              value="all_comments"
            />
            Separate email notifications for every individual comment on one of your songs
          </label>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <Field component={FormInput} type="radio" classes="form-check-input"
              name="artist_comment_notifications"
              value="digest"
            />
            One email notification per day containing a summary of comments on your songs for that day
          </label>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <Field component={FormInput} type="radio" classes="form-check-input"
              name="artist_comment_notifications"
              value="no_comments"
            />
            No email notifications for any comments on your songs
          </label>
        </div>
      </fieldset>
    )}


    <div>
      <LoadingButton
        ownProps={{ className: 'btn btn-light', type: "submit"}}
        isLoading={submitting}
        isSuccess={isSuccess}
        successText="Updated!"
      >
        Update
      </LoadingButton>
    </div>
  </form>
)

const validate = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = "Email is required"
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = "Email is invalid"
  }

  if (!values.password) {
    errors.password = "Password is required"
  }
  return errors
}

AccountForm = reduxForm({
  form: 'accountForm',
  validate,
  enableReinitialize: true,
})(AccountForm)

export default connect((state, props) => ({
  initialValues: _.mapKeys(_.get(props, 'user', {}), (v, k) => _.snakeCase(k)),
}))(AccountForm)
