import React, { useEffect, useState } from 'react'

import { useLocation, useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { resetPassword } from 'actions/api/users'
import { SubmissionError } from 'redux-form'
import { processApiErrors } from 'utils'

import { AUTH_PARAM } from 'static'

import { parseUrlParams } from 'utils'

import NavBar from 'components/main/NavBarV2'
import ResetPasswordForm from 'components/main/register/ResetPasswordForm'

let mounted, successTimeout
const SUCCESS_TIME = 4000

const ResetPasswordPage = ({ resetPassword }) => {
  const location = useLocation()
  const history = useHistory()

  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const onResetPassword = (values) => {
    const urlData = parseUrlParams(location.search)
    const urlParams = urlData.params || {}
    if (mounted) { setIsError(false) }
    return resetPassword({ user: values, [AUTH_PARAM]: urlParams[AUTH_PARAM] }).then((res) => {
      if (res.error) {
        const status = _.get(res, 'payload.requestStatus')
        if (status === 401) {
          setIsError(true)
        } else {
          throw new SubmissionError(processApiErrors(res.payload.errors))
        }
      } else {
        if (mounted) {
          setIsSuccess(true)
          successTimeout = setTimeout(() => {
            if (mounted) { setIsSuccess(false) }
            history.push("/login")
          }, SUCCESS_TIME)
        }
      }
    })
  }

  useEffect(() => {
    mounted = true
    return () => {
      clearTimeout(successTimeout)
      mounted = false
    }
  }, [])

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffcf5a" />

      <div className="min-vh-100 width-100 background-yellow absolute-top-left">
        <div className='navbar-spacer'></div>
        <div className="container mt-5 py-3">

          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-8 col-lg-6">
              <div className="mx-3">
                <h2 className="my-3">Reset Password</h2>
                <div className="mb-3">
                  <ResetPasswordForm onSubmit={onResetPassword} />
                </div>
                {isSuccess && (
                  <div className="alert alert-success">
                    Password updated!  You will be redirected to the login page shortly.
                  </div>
                )}
                {isError && (
                  <div className="alert alert-danger">
                    Something's not right... <br/>
                    Your password reset link may have expired!
                    You can <Link to='/send_password_reset'>request a new one here</Link>.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}


export default connect(null, {
  resetPassword
})(ResetPasswordPage)
