import React from 'react'

export default ({ isLoading, isSuccess, successText, ownProps={}, children }) => (
  <button {...ownProps} disabled={isLoading || isSuccess}>
    {isSuccess ? (
      <React.Fragment>{successText}</React.Fragment>
    ) :
      isLoading ? (
        <React.Fragment>
          <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
          Loading...
        </React.Fragment>
      ): (
        <React.Fragment>{children}</React.Fragment>
      )
    }
  </button>
)
