import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { Link, useHistory } from 'react-router-dom'
import { Resources } from 'schema'

//import Breadcrumbs from 'components/main/Breadcrumbs'
import { selectCurrentUser, selectModel } from 'selectors'
import { getArtist, updateArtist, artistOrganizeSongs, artistCommentsIndex } from 'actions/api/artists'

import NavBar from 'components/main/NavBarV2'
import ArtistEditForm from 'components/main/artist/ArtistEditForm'
import FileUploader from 'components/main/FileUploader'
import SortableList from 'components/main/SortableList'
import PaginatedList from 'components/main/PaginatedList'

import { IMAGE_FILE_TYPES, LIST_TIMEOUT } from 'static'

import { processApiErrors } from 'utils'
import { secondsToMinutes } from 'utils/time'
import moment from "moment/moment";

let copiedTimeout, mounted, successTimeout
let listUpdateTimeout = null

const ArtistDashboard = ({
  user,
  artist,
  getArtist,
  updateArtist,
  artistOrganizeSongs,
  artistCommentsIndex,
}) => {
  const history = useHistory()

  const shareLinkEl = useRef(null)
  const [isCopiedSuccess, setIsCopiedSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [isOrderingAlbums, setIsOrderingAlbums] = useState(false)
  const [isOrderingSongs, setIsOrderingSongs] = useState(false)

  const [isListLoading, setIsListLoading] = useState(false)
  const [isListError, setIsListError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [artistImageFile, setArtistImageFile] = useState(null)
  const [singlesList, setSinglesList] = useState([])
  const [albumsList, setAlbumsList] = useState([])

  const loadAlbumsAndSingles = (res) => {
    const songs = _.get(res, 'payload.raw.artist.songs', [])
    setSinglesList(_.filter(songs, (s) => _.isNil(s.albumId)))
    setAlbumsList(_.get(res, 'payload.raw.artist.albums', []))
  }

  const onUpdate = (values) => updateArtist({ artist: { ...values } }).then((res) => {
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      if (mounted) {
        setIsSuccess(true)
        successTimeout = setTimeout(() => setIsSuccess(false), 5000)
      }
    }
  })

  const onUpdateArtistImage = (signedIds) => {
    updateArtist({ artist_image: _.first(signedIds) }).then((res) => {
      if (!res.error && mounted) {
        setArtistImageFile(null)
      }
    })
  }

  const onUpdateLists = () => {
    setIsListLoading(true)
    setIsListError(false)
    clearTimeout(listUpdateTimeout)
    listUpdateTimeout = setTimeout(() => {
      artistOrganizeSongs({
        albums: _.map(albumsList, (a, i) => ({ id: a.id, index: i })),
        songs: _.map(singlesList, (s, i) => ({ id: s.id, index: i })),
      }).then((res) => {
        if (mounted) {
          setIsListLoading(false)
          setIsListError(res.error)
          if (!res.error) { loadAlbumsAndSingles(res) }
        }
      })
    }, LIST_TIMEOUT);
  }


  const copyShareLink = () => {
    shareLinkEl.current.select()
    document.execCommand('copy')
    setIsCopiedSuccess(true)
    copiedTimeout = setTimeout(() => setIsCopiedSuccess(false), 1000)
  }

  useEffect(() => {
    mounted = true
    copiedTimeout = null
    successTimeout = null

    setIsLoading(true)
    getArtist().then((res) => {
      if (mounted) {
        setIsLoading(false)
        setIsListLoading(false)
        if (!res.error) { loadAlbumsAndSingles(res) }
      }
    })

    return () => {
      mounted = false
      clearTimeout(copiedTimeout)
      clearTimeout(successTimeout)
    }
  }, [])

  // TODO ordering -- <% @current_user.artist.albums.order(index: :asc).each do |album| %>
  // TODO ordering -- <% @current_user.artist.songs.no_album.order(index: :asc).each do |song| %>
  const albums = _.filter(_.get(artist, 'albums', []), (a) => !_.isNil(a))
  const songs = _.filter(_.get(artist, 'songs', []), (s) => !_.isNil(s))
  const singles = _.filter(songs, (song) => _.isNil(song.albumId))

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffffff" />

      <div className='container pb-5'>
        <h1>Artist Dashboard</h1>

        {_.isNil(artist.artistImageSmallUrl) && (
          <div className="alert alert-danger">
            <strong>Warning!</strong>  Your profile isn't finished, you are missing a profile image.  Use the "Edit Artist Profile" link above to upload one.
          </div>
        )}

        <h5>Public Artist Profile</h5>
        <div className="d-flex flex-row">
          <div className="flex-grow-0">
            <div className='btn-group mr-2'>
              <a target="_blank" className="btn btn-secondary" href={artist.publicUrl}>View</a>
              <button className="btn btn-primary" onClick={copyShareLink}>
                {isCopiedSuccess ? 'Copied!' : 'Copy'}
              </button>
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="form-group">
              <input ref={shareLinkEl} type="text" className="share-url form-control" value={artist.fullPublicUrl} readOnly />
            </div>
          </div>
        </div>

        <div className="border-bottom border-dark my-3"></div>

        <div className="row">
          <div className="col-12 col-md-6 mb-3">
            {isOrderingAlbums ? (
              <>
                <div className="mb-3">
                  <SortableList
                    title='Albums'
                    list={albumsList}
                    setList={setAlbumsList}
                    onChange={onUpdateLists}
                    isLoading={isListLoading}
                    emptyText='You have no albums.'
                  />
                </div>
                <button
                  className='btn btn-primary'
                  onClick={() => setIsOrderingAlbums(false)}
                >Save Album Order</button>
              </>
            ) : (
              <>
                <h3>Albums</h3>
                <div className='list-group mb-3'>
                  {_.map(albumsList, (li) => (
                    <Link className='list-group-item list-group-item-action' to={li.url} key={li.id}>
                      {li.name}
                    </Link>
                  ))}
                </div>
                <Link className='btn btn-primary mr-2' to="/albums/new">Add Album</Link>
                <button
                  className='btn btn-secondary'
                  onClick={() => setIsOrderingAlbums(true)}
                >Change Album Order</button>
              </>
            )}
          </div>

          <div className="col-12 col-md-6 mb-3">
            {isOrderingSongs ? (
              <>
                <div className='mb-3'>
                  <SortableList
                    title='Singles'
                    list={singlesList}
                    setList={setSinglesList}
                    onChange={onUpdateLists}
                    isLoading={isListLoading}
                    emptyText='You have no singles.'
                  />
                </div>
                <button
                  className='btn btn-primary'
                  onClick={() => setIsOrderingSongs(false)}
                >Save Song Order</button>
              </>
            ) : (
              <>
                <h3>Singles</h3>
                <div className='list-group mb-3'>
                  {_.map(singlesList, (li) => (
                    <Link className='list-group-item list-group-item-action' to={li.url} key={li.id}>
                      {li.name}
                    </Link>
                  ))}
                </div>
                <Link className='btn btn-primary mr-2' to="/songs/new">Add Song</Link>
                <button
                  className='btn btn-secondary'
                  onClick={() => setIsOrderingSongs(true)}
                >Change Song Order</button>
              </>
            )}
          </div>
        </div>

        <div className="border-bottom border-dark my-3"></div>

        <h3 className="mb-3">Comments</h3>
        <div className="mb-3">
          <PaginatedList
            getListRequest={artistCommentsIndex}
            listName='comments'
            columns={[
              { name: 'Song', key: 'song.name', link: 'song.publicUrl', newTab: true },
              { name: 'Stem', key: 'stem.name' },
              { name: 'Time', key: 'stemTime', getValue: (s) => secondsToMinutes(s.stemTime) },
              { name: 'Username', key: 'user.username' },
              { name: 'Comment', key: 'message' },
              { name: 'Date Added', key: 'createdAt', getValue: (i) => moment(i.createdAt).fromNow() },
            ]}
          />
        </div>

        {isListError && (
          <div className="alert alert-danger">
            There was an error saving your album and single order.
          </div>
        )}

        <div className="border-bottom border-dark my-3"></div>

        <h3 className="mb-3">Artist Settings</h3>
        <ArtistEditForm onSubmit={onUpdate} artist={artist} isSuccess={isSuccess} />

        <div className="border-bottom border-dark my-3"></div>

        <h3 className="mt-3">Change Artist Image</h3>
        <div className="row">
          <div className="col-12 col-md-6">
            <img src={artist.artistImageUrl} className="img-thumbnail mb-3" />

            <div className="alert alert-info mb-3">
              The following file types are accepted
              <ul className="mb-0">
                <li><strong>.jpg</strong> smaller than 25MB</li>
                <li><strong>.gif</strong> smaller than 25MB</li>
                <li><strong>.png</strong> smaller than 25MB</li>
              </ul>
            </div>
            <FileUploader
              onUploadSuccess={onUpdateArtistImage}
              setFileData={setArtistImageFile}
              fileData={artistImageFile}
              fileTypes={IMAGE_FILE_TYPES}
            />
          </div>
        </div>

      </div>

    </React.Fragment>
  )
}

export default connect((state) => {
  const user = selectCurrentUser(state)
  return {
    user,
    artist: selectModel('artists', _.get(user, 'artistId'), Resources.artist, state),
  }
}, {
  updateArtist,
  getArtist,
  artistOrganizeSongs,
  artistCommentsIndex,
})(ArtistDashboard)
