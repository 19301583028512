import { resourceRSAA, jsonRSAA } from 'actions/api'

export const CREATE_ALBUM_ACTION = 'CREATE_ALBUM'
export function createAlbum(data) {
  return resourceRSAA(CREATE_ALBUM_ACTION, `/api/albums`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export const UPDATE_ALBUM_ACTION = 'UPDATE_ALBUM'
export function updateAlbum(id, data) {
  return resourceRSAA(UPDATE_ALBUM_ACTION, `/api/albums/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data)
  })
}

export const GET_ALBUM_ACTION = 'GET_ALBUM'
export function getAlbum(id) {
  return resourceRSAA(GET_ALBUM_ACTION, `/api/albums/${id}`, { method: 'GET' })
}

export const DELETE_ALBUM_ACTION = 'DELETE_ALBUM'
export function deleteAlbum(id) {
  return resourceRSAA(DELETE_ALBUM_ACTION, `/api/albums/${id}`, { method: 'DELETE' })
}

