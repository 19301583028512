import { jsonRSAA } from 'actions/api'

export const ADMIN_GET_SONG_INDEX_ACTION = 'ADMIN_GET_SONG_INDEX'
export function adminGetSongIndex(page) {
  return jsonRSAA(ADMIN_GET_SONG_INDEX_ACTION, `/api/admin/songs/?page=${page}`, null, { method: 'GET' })
}

export const ADMIN_GET_SONG_ACTION = 'ADMIN_GET_SONG'
export function adminGetSong(id) {
  return jsonRSAA(ADMIN_GET_SONG_ACTION, `/api/admin/songs/${id}`, null, { method: 'GET' })
}

export const ADMIN_GET_SONG_COMMENTS_ACTION = 'ADMIN_GET_SONG_COMMENTS'
export function adminGetSongComments(id, page) {
  return jsonRSAA(ADMIN_GET_SONG_COMMENTS_ACTION, `/api/admin/songs/${id}/comments?page=${page}`, null, { method: 'GET' })
}
