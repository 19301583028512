import _ from 'lodash'
import { SONG_TYPES } from 'static'

export function getSongTypeData(songType) {
  return _.find(_.values(SONG_TYPES), { enumValue: songType }) || {}
}

export function doesSongTypeMatch(songType, songTypeData) {
  return songType === _.get(songTypeData, 'enumValue')
}
