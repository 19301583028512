import React, { useEffect, useState, useRef } from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import Linkify from 'react-linkify'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { adminGetArtist } from 'actions/api/admin/artists'

import AdminBase from 'components/main/admin/AdminBase'

import { CONVERSION_STATUS } from 'static'

let mounted, copiedTimeout

const AdminArtistPage = ({
  match,
  adminGetArtist,
}) => {
  const artistId = _.get(match, 'params.id')
  const [artist, setArtist] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const shareLinkEl = useRef(null)
  const [isCopiedSuccess, setIsCopiedSuccess] = useState(false)

  const copyShareLink = () => {
    shareLinkEl.current.select()
    document.execCommand('copy')
    setIsCopiedSuccess(true)
    copiedTimeout = setTimeout(() => setIsCopiedSuccess(false), 1000)
  }

  useEffect(() => {
    mounted = true
    setIsLoading(true)
    adminGetArtist(artistId).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (!res.error) {
          setArtist(_.get(res, 'payload.artist'))
        }
      }
    })
    return () => {
      mounted = false
      clearTimeout(copiedTimeout)
    }
  }, [])

  return (
    <AdminBase breadcrumbs={[
      { label: "Admin", url: '/admin' },
      { label: "Artists", url: '/admin/artists' },
      { label: artist.artistName, active: true },
    ]}>
      <h1>{artist.artistName} ({artist.id}) <Link className="badge badge-secondary" to={`/admin${_.get(artist, 'url')}/edit`}>Edit</Link></h1>

      {_.get(artist, 'isHighlighted') && (<strong>HIGHLIGHTED ARTIST ({_.get(artist, 'highlightIndex')+1})</strong>)}

      {_.get(artist, 'isHidden') && (
        <div className="alert alert-warning">
          <i className="fa fa-eye-slash mr-2"></i>This artist is Hidden
        </div>
      )}

      <div className="mb-3">
        <div className="row">
          <div className="col-3 col-lg-2">Username:</div>
          <div className="col-9 col-lg-10">
            <Link to={`/admin${_.get(artist, 'user.url')}`}>{_.get(artist, 'user.username')}</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">User Email:</div>
          <div className="col-9 col-lg-10">
            {_.get(artist, 'user.email')}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2"># Song Views:</div>
          <div className="col-9 col-lg-10">
            {_.get(artist, 'totalSongViews')}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2"># Artist Views:</div>
          <div className="col-9 col-lg-10">
            {_.get(artist, 'totalSplashViews')}
          </div>
        </div>
        {!_.isEmpty(_.get(artist, 'patreonUrl')) && (
          <div className="row">
            <div className="col-3 col-lg-2"># Patreon Clicks:</div>
            <div className="col-9 col-lg-10">
              {_.get(artist, 'totalPatreonRedirects')}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-3 col-lg-2">Created:</div>
          <div className="col-9 col-lg-10">
            {moment(artist.createdAt).fromNow()}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">Last Edited:</div>
          <div className="col-9 col-lg-10">
            {moment(artist.updatedAt).fromNow()}
          </div>
        </div>

        {_.get(artist, 'ignoreRankCalculator') && (<strong>IGNORE RANK CALCULATOR</strong>)}

        <div className="row">
          <div className="col-3 col-lg-2">Explore Rank:</div>
          <div className="col-9 col-lg-10">
            {_.get(artist, 'exploreRank')}
          </div>
        </div>

        <div className="row">
          <div className="col-3 col-lg-2">Mix Comparisons:</div>
          <div className="col-9 col-lg-10">
            {artist.isMixComparisonEnabled ? 'Enabled' : 'Disabled'}
          </div>
        </div>
      </div>

      <div className="d-flex flex-row">
        <div className="flex-grow-0">
          <div className='btn-group mr-2'>
            <a target="_blank" className="btn btn-secondary" href={_.get(artist, 'publicUrl')}>View</a>
            <button className="btn btn-primary" onClick={copyShareLink}>
              {isCopiedSuccess ? 'Copied!' : 'Copy'}
            </button>
          </div>
        </div>
        <div className="flex-grow-1">
          <div className="form-group">
            <input ref={shareLinkEl} type="text" className="share-url form-control" value={_.get(artist, 'fullPublicUrl', '')} readOnly />
          </div>
        </div>
      </div>

      <div className="mb-3">
        <h5>Description:</h5>
        <Linkify className="border rounded prewrap break-word p-3 mt-1 d-block">
          {artist.artistBio}
        </Linkify>
      </div>



      {!_.isNil(_.get(artist, 'artistImageUrl')) ? (
        <div className="mb-3">
          <h3>Artist Image</h3>
          <img
            className="img-thumbnail"
            src={_.get(artist, 'artistImageUrl')}
            style={{
              maxWidth: "400px",
            }}
          />
        </div>
      ) : (
        <div className="mb-3">
          <h3>Artist Has No Image</h3>
        </div>
      )}

      <h3>Songs</h3>
      <table className="table table-striped table-hover mb-5">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Album</th>
            <th scope="col">Created</th>
          </tr>
        </thead>
        <tbody>
          {_.map(artist.songs, (song) => (
            <tr key={song.id}>
              <td>{song.id}</td>
              <td>
                <Link to={`/admin${song.url}`}>
                  {_.get(song, 'name')}
                </Link>
                {_.get(song, 'isHidden') && (<span className="badge badge-warning ml-2">hidden</span>)}
                {_.get(song, 'numComments', 0) > 0 && (<span className="badge badge-info ml-2">
                  <i className="fa fa-comments"></i>
                </span>)}
              </td>
              <td>
                {_.get(_.find(artist.albums, { id: song.albumId }), 'name', "N/A")}
                {_.get(_.find(artist.albums, { id: song.albumId }), 'isHidden') && (
                  <span className="badge badge-warning ml-2">hidden</span>
                )}
              </td>
              <td>{moment(song.createdAt).fromNow()}</td>
            </tr>
          ))}
          {_.isEmpty(artist.songs) && (
            <tr>
              <td colSpan="4" className="text-center">
                Artist has no songs.
              </td>
            </tr>
          )}
        </tbody>
      </table>

    </AdminBase>
  )
}

export default connect(null, {
  adminGetArtist,
})(AdminArtistPage)
