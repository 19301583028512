import React from 'react'
import _ from 'lodash'

import { Field, reduxForm } from 'redux-form'
import FormInput from 'components/main/FormInput'
import LoadingButton from 'components/main/LoadingButton'

import { EMAIL_REGEX, USERNAME_REGEX } from 'utils'

const RegisterForm = ({ handleSubmit, submitting }) => (
  <form onSubmit={handleSubmit}>
    <div className="form-group">
      <label htmlFor="username">Username</label>
      <Field name="username" component={FormInput} type="text" classes="form-control" />
      <small id="usernameDesc" className="form-text">
        This will be your unique identifier on splitter.fm. <br/>
        It may only contain numbers, letters, dashes, underscores, and periods. <br/>
      </small>
    </div>

    <div className="form-group">
      <label htmlFor="email">Email address</label>
      <Field name="email" component={FormInput} type="email" classes="form-control" />
    </div>

    <div className="form-group">
      <label htmlFor="password">Password</label>
      <Field name="password" component={FormInput} type="password" classes="form-control" />
    </div>

    <div className="form-group">
      <label htmlFor="password_confirmation">Confirm Password</label>
      <Field name="password_confirmation" component={FormInput} type="password" classes="form-control" />
    </div>

    <div>
      <LoadingButton ownProps={{ className: 'btn btn-light', type: "submit"}} isLoading={submitting}>
        Sign Up
      </LoadingButton>
    </div>
  </form>
)

const validate = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = "Email is required"
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = "Email is invalid"
  }

  if (!values.username) {
    errors.username = "Username is required"
  } else if (!USERNAME_REGEX.test(values.username)) {
    errors.username = "Username is invalid"
  }

  if (!values.password) {
    errors.password = "Password is required"
  }

  if (!values.password_confirmation) {
    errors.password_confirmation = "Password Confirmation is required"
  }

  return errors
}

export default reduxForm({
  form: 'registerForm',
  validate,
})(RegisterForm)
