import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Resources } from 'schema'
import { Link, useHistory } from 'react-router-dom'
import { SubmissionError } from 'redux-form'
import { processApiErrors } from 'utils'
import { selectModel } from 'selectors'
import { getArtist } from 'actions/api/artists'
import { uploadStems, getSong } from 'actions/api/songs'

import NavBar from 'components/main/NavBarV2'
import Breadcrumbs from 'components/main/Breadcrumbs'
import StemUploader from 'components/main/stems/StemUploader'

import { AUDIO_FILE_TYPES, SONG_MAX_STEMS } from 'static'

let mounted

const SongAddStems = ({
  song,
  match,
  album,
  uploadStems,
  getSong,
  getArtist,
}) => {
  const history = useHistory()
  const songId = _.get(match, 'params.id')
  const [stemData, setStemData] = useState(null)
  const [isUploadError, setIsUploadError] = useState(false)

  const wrappedSetStemData = (input) => {
    setStemData(input)
  }

  const onUploadFinish = () => {
    const songUrl = _.get(song, 'url')
    history.push(songUrl)
  }

  const onUploadError = () => {
    setIsUploadError(true)
  }

  const breadcrumbs = [{ label: "Home", url: "/artist" }]
  if (!_.isNil(album)) { breadcrumbs.push({ label: album.name, url: album.url }) }
  breadcrumbs.push({ label: _.get(song, 'name'), url: _.get(song, 'url') })
  breadcrumbs.push({ label: "Add Stems", active: true })

  const isTooManyStems = _.get(stemData, 'length', 0) > SONG_MAX_STEMS
  const songUrl = _.get(song, 'url')

  useEffect(() => {
    mounted = true
    getSong(songId)
    getArtist()

    return () => {
      mounted = false
    }
  }, [songId])

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffffff" />

      <div className='container pb-5'>
        <Breadcrumbs crumbs={breadcrumbs} />

        <h1 className="mb-3">{_.get(song, 'name')}</h1>

        {!_.isEmpty(_.get(song, 'stems', [])) && (
          <React.Fragment>
            <h3 className="mb-2">Current Stems</h3>
            <div className="list-group mb-3">
              {_.map(_.get(song, 'stems', []), (stem) => (
                <div key={stem.id} className="list-group-item">
                  <span className="stem-color mr-2" style={{ 'backgroundColor': stem.bgColor }}></span>
                  {stem.index + 1} {stem.name}
                </div>
              ))}
            </div>
          </React.Fragment>
        )}

        <h3 className="mb-2">Add Stems</h3>

        <div className="alert alert-info">
          The following file types are accepted
          <ul className="mb-0">
            <li><strong>.wav</strong> smaller than 150MB</li>
            <li><strong>.aif</strong> smaller than 150MB</li>
            <li><strong>.mp3</strong> smaller than 15MB</li>
          </ul>
          At most, a song can have {SONG_MAX_STEMS} stems.
        </div>

        <div className="alert alert-warning">
          <strong>NOTE:</strong> If you are uploading .wav or .aiff files, we DO NOT recommend uploading more than 2 or 3 at once.
        </div>

        <StemUploader
          songId={_.get(song, 'id')}
          onUploadFinish={onUploadFinish}
          onUploadError={onUploadError}
          setFileData={wrappedSetStemData}
          fileData={stemData}
          fileTypes={AUDIO_FILE_TYPES}
          isDisabled={isTooManyStems}
          allowMultiple
        />

        {isTooManyStems && (
          <div className="alert alert-danger">You have too many stems.  At most, a song can have {SONG_MAX_STEMS} stems.</div>
        )}

        {isUploadError && (
          <React.Fragment>
            <div className="alert alert-danger"><i className="fa fa-exclamation-triangle"></i> There was at least one error with your upload.  Use the button below to continue to the song page.</div>
            <Link to={songUrl} className="btn btn-primary mb-3">Continue</Link>
          </React.Fragment>
        )}
      </div>

    </React.Fragment>
  )
}

export default connect((state, ownProps) => {
  const song = selectModel('songs', _.get(ownProps, 'match.params.id'), Resources.song, state)
  let album = null
  if (!_.isNil(_.get(song, 'albumId'))) {
    album = selectModel('albums', song.albumId, Resources.album, state)
  }
  return { song, album }
}, {
  getSong,
  getArtist,
  uploadStems,
})(SongAddStems)
