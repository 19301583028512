import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { adminGetHighlightedArtists, adminOrderHighlightedArtists } from 'actions/api/admin/artists'
import { LIST_TIMEOUT } from 'static'

import AdminBase from 'components/main/admin/AdminBase'
import SortableList from 'components/main/SortableList'

let mounted, listUpdateTimeout

const AdminExploreList = ({
  adminGetHighlightedArtists,
  adminOrderHighlightedArtists,
}) => {
  const [artists, setArtists] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onOrderArtists = () => {
    setIsLoading(true)
    clearTimeout(listUpdateTimeout)
    listUpdateTimeout = setTimeout(() => {
      adminOrderHighlightedArtists({
        artists: [
          ..._.map(artists, (s, i) => ({ id: s.id, highlight_index: i })),
        ],
      }).then((res) => {
        if (mounted) {
          setIsLoading(false)
          if (!res.error) { setArtists(_.get(res, 'payload.artists')) }
        }
      })
    }, LIST_TIMEOUT)
  }

  useEffect(() => {
    mounted = true
    listUpdateTimeout = null
    setIsLoading(true)
    adminGetHighlightedArtists().then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (!res.error) { setArtists(_.get(res, 'payload.artists')) }
      }
    })
    return () => {
      mounted = false
    }
  }, [])

  return (
    <div className="my-3">
      <SortableList
        title='Highlighted Artists'
        list={artists}
        setList={setArtists}
        onChange={onOrderArtists}
        isLoading={isLoading}
        emptyText='There are no Highlighted Artists.'
        renderItem={(li) => (
          <div key={li.id} className="list-group-item">
            <i className="fa fa-bars mr-2" aria-hidden="true"></i> {li.artistName}
          </div>
        )}
      />
    </div>
  )
}

export default connect(null, {
  adminGetHighlightedArtists,
  adminOrderHighlightedArtists,
})(AdminExploreList)
