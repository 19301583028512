import React from 'react'
import _ from 'lodash'

import NavBar from 'components/main/NavBarV2'
import Breadcrumbs from 'components/main/Breadcrumbs'

export default ({ breadcrumbs, children }) => (
  <React.Fragment>
    <NavBar backgroundColor="#ffffff" />

    <div className='container pb-5'>
      <div className='bg-danger p-1 mb-2 text-center text-light'>admin tools</div>
      {!_.isNil(breadcrumbs) && !_.isEmpty(breadcrumbs) && (
        <Breadcrumbs crumbs={breadcrumbs} />
      )}
      {children}
    </div>
  </React.Fragment>
)
