import $ from 'jquery'
import 'whatwg-fetch'

export function fetchPost(url, method, data) {
  return fetch(url, {
    method,
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
    },
    body: JSON.stringify(data),
  })
}
