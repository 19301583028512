import { get } from 'actions/api'

export const ADMIN_COMMENTS_INDEX_ACTION = 'ADMIN_COMMENTS_INDEX'
export function adminCommentsIndex(page) {
  return get(`/api/admin/comments/?page=${page}`, ADMIN_COMMENTS_INDEX_ACTION)
}

export const ADMIN_GET_COMMENT_ACTION = 'ADMIN_GET_COMMENT'
export function adminGetComment(id) {
  return get(`/api/admin/comments/${id}`, ADMIN_GET_COMMENT_ACTION)
}
