import { resourceRSAA, jsonRSAA } from 'actions/api'

export const CURRENT_USER_ACTION = 'CURRENT_USER'
export function getCurrentUser() {
  return resourceRSAA(CURRENT_USER_ACTION, `/api/user`)
}

export const LOGOUT_ACTION = 'LOGOUT'
export function logout() {
  return jsonRSAA(LOGOUT_ACTION, `/api/logout`)
}

export const LOGIN_ACTION = 'LOGIN'
export function login(data) {
  return resourceRSAA(LOGIN_ACTION, `/api/login`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export const REGISTER_ACTION = 'REGISTER'
export function register(data) {
  return resourceRSAA(REGISTER_ACTION, `/api/register`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export const REGISTER_ARTIST_ACTION = 'REGISTER_ARTIST'
export function registerArtist(data) {
  return resourceRSAA(REGISTER_ACTION, `/api/register_artist`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export const SEND_EMAIL_CONFIRMATION_ACTION = 'SEND_EMAIL_CONFIRMATION'
export function sendEmailConfirmation() {
  return resourceRSAA(SEND_EMAIL_CONFIRMATION_ACTION, `/api/email_confirmation`, {
    method: 'PUT',
  })
}

export const CONFIRM_EMAIL_ACTION = 'CONFIRM_EMAIL'
export function confirmEmail(til) {
  return resourceRSAA(CONFIRM_EMAIL_ACTION, `/api/confirm_email`, {
    method: 'PUT',
    body: JSON.stringify({ til })
  })
}

export const SEND_PASSWORD_RESET_ACTION = 'SEND_PASSWORD_RESET'
export function sendPasswordReset(data) {
  return resourceRSAA(SEND_PASSWORD_RESET_ACTION, `/api/send_password_reset`, {
    method: 'PUT',
    body: JSON.stringify(data)
  })
}

export const RESET_PASSWORD_ACTION = 'RESET_PASSWORD'
export function resetPassword(data) {
  return resourceRSAA(RESET_PASSWORD_ACTION, `/api/reset_password`, {
    method: 'PUT',
    body: JSON.stringify(data)
  })
}

export const UPDATE_EMAIL_ACTION = 'UPDATE_EMAIL'
export function updateUserEmail(data) {
  return resourceRSAA(UPDATE_EMAIL_ACTION, `/api/user/update_email`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
}

export const UPDATE_PASSWORD_ACTION = 'UPDATE_PASSWORD'
export function updateUserPassword(data) {
  return resourceRSAA(UPDATE_PASSWORD_ACTION, `/api/user/update_password`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
}

export const UPDATE_USER_ACCOUNT = 'UPDATE_USER_ACCOUNT'
export function updateUserAccount(data) {
  return resourceRSAA(UPDATE_USER_ACCOUNT, `/api/user`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
}

export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT'
export function deleteUserAccount() {
  return resourceRSAA(DELETE_USER_ACCOUNT, `/api/user`, { method: 'DELETE' })
}

