import React from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { login } from 'actions/api/users'
import { SubmissionError } from 'redux-form'

import NavBar from 'components/main/NavBarV2'
import LoginForm from 'components/main/login/LoginForm'

import { processApiErrors } from 'utils'

import { fullStorySetUserVars } from 'utils/analytics'

const LoginPage = ({ login }) => {

  const history = useHistory()
  const loginCurrentUser = (values) => login({ user: { ...values } }).then((res) => {
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      fullStorySetUserVars(_.get(res, 'payload.raw.user'))
      history.push("/")
    }
  })

  return (
    <>
      <NavBar backgroundColor="#ffcf5a" />

      <div className="min-vh-100 width-100 background-yellow absolute-top-left">
        <div className='navbar-spacer'></div>
        <div className="container py-3">

          {/** TODO CONFIRMATION SUCCESS MESSAGE? **/}

          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-8 col-lg-6">
              <div className="mx-3">
                <h1 className="my-3">Login</h1>
                <LoginForm onSubmit={loginCurrentUser} />
                <Link className='text-dark' to="/send_password_reset">Forget your password?</Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default connect(null, { login })(LoginPage)
