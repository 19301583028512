import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { sendClientError } from 'actions/api'

class ErrorHandler extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ is_error: true })
    const stacktrace = _.trim(_.get(errorInfo, 'componentStack'))
    const trimError = _.trim(error)
    if ((!_.isNil(trimError) && !_.isEmpty(trimError)) || (!_.isNil(stacktrace) && !_.isEmpty(stacktrace))) {
      this.props.sendClientError(`message: ${trimError}\nstacktrace: ${stacktrace}`)
    }
  }

  render() {
    if (this.state.is_error) {
      return (
        <div className='row justify-content-center p-4 min-vh-100'>
          <div className='col-12 col-md-8 col-lg-6'>
            <p>
              There was an unexpected error!
            </p>

            <p>
              Please send us an email (
                <a href='mailto:splitterfm@gmail.com'>
                  splitterfm@gmail.com
                </a>
              ) with details about the error.
            </p>

            <p>
              To continue using splitter.fm, please <a onClick={() => location.reload()}>reload the page</a>.  If the error persists, please <a href="/">go to the homepage</a>.
            </p>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}


export default connect(null, {
  sendClientError
})(ErrorHandler)
