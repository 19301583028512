import { resourceRSAA, jsonRSAA } from 'actions/api'

export const CREATE_SONG_ACTION = 'CREATE_SONG'
export function createSong(data) {
  return resourceRSAA(CREATE_SONG_ACTION, `/api/songs`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export const UPLOAD_STEMS_ACTION = 'UPLOAD_STEMS'
export function uploadStems(id, data) {
  return resourceRSAA(UPLOAD_STEMS_ACTION, `/api/songs/${id}/upload_stems`, {
    method: 'PUT',
    body: JSON.stringify(data)
  })
}

export const ORDER_STEMS_ACTION = 'ORDER_STEMS'
export function orderStems(id, data) {
  return resourceRSAA(ORDER_STEMS_ACTION, `/api/songs/${id}/stem_order`, {
    method: 'PUT',
    body: JSON.stringify(data)
  })
}

export const RESET_COLORS_ACTION = 'RESET_COLORS'
export function resetStemColors(id) {
  return resourceRSAA(RESET_COLORS_ACTION, `/api/songs/${id}/reset_colors`, { method: 'PUT' })
}

export const UPDATE_SONG_ACTION = 'UPDATE_SONG'
export function updateSong(id, data) {
  return resourceRSAA(UPDATE_SONG_ACTION, `/api/songs/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data)
  })
}

export const GET_SONG_ACTION = 'GET_SONG'
export function getSong(id) {
  return resourceRSAA(GET_SONG_ACTION, `/api/songs/${id}`, { method: 'GET' })
}

export const DELETE_SONG_ACTION = 'DELETE_SONG'
export function deleteSong(id) {
  return resourceRSAA(DELETE_SONG_ACTION, `/api/songs/${id}`, { method: 'DELETE' })
}

