import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import moment from 'moment'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { adminGetComment } from 'actions/api/admin/comments'

import AdminBase from 'components/main/admin/AdminBase'
import Linkify from 'react-linkify'

import { secondsToMinutes } from 'utils/time'

let mounted

const AdminCommentPage = ({
  match,
  adminGetComment,
}) => {
  const commentId = _.get(match, 'params.id')
  const [comment, setComment] = useState({})
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    mounted = true
    setIsLoading(true)
    adminGetComment(commentId).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (!res.error) {
          setComment(_.get(res, 'payload.comment'))
        }
      }
    })
    return () => {
      mounted = false
    }
  }, [])

  return (
    <AdminBase breadcrumbs={[
      { label: "Admin", url: '/admin' },
      { label: "Comments", url: '/admin/comments' },
      { label: _.get(comment, 'id'), active: true },
    ]}>
      <h1>Comment #{comment.id}</h1>

      {_.get(comment, "isDestroyed") && (
        <div className="alert alert-danger">
          This comment has been deleted
        </div>
      )}

      <div className="mb-3">
        <div className="row">
          <div className="col-3 col-lg-2">User:</div>
          <div className="col-9 col-lg-10">
            <Link to={`/admin${_.get(comment, 'user.url')}`}>{_.get(comment, 'user.username')}</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">On Song:</div>
          <div className="col-9 col-lg-10">
            <Link to={`/admin${_.get(comment, 'song.url')}`}>{_.get(comment, 'song.name')}</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">On Stem:</div>
          <div className="col-9 col-lg-10">
            {_.get(comment, 'stem.name')}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">Song Timestamp:</div>
          <div className="col-9 col-lg-10">
            {secondsToMinutes(comment.stemTime)}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">Created:</div>
          <div className="col-9 col-lg-10">
            {moment(comment.createdAt).fromNow()}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">Last Edited:</div>
          <div className="col-9 col-lg-10">
            {moment(comment.updatedAt).fromNow()}
          </div>
        </div>
      </div>

      <div className="mb-3">
        <h5>Comment Text:</h5>
        <Linkify className="border rounded prewrap break-word p-3 mt-1 d-block">
          {comment.message}
        </Linkify>
      </div>

    </AdminBase>
  )
}

export default connect(null, {
  adminGetComment
})(AdminCommentPage)
