import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import moment from 'moment'

import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import {
  adminGetArtist,
  adminUpdateArtist
} from 'actions/api/admin/artists'

import AdminBase from 'components/main/admin/AdminBase'
import AdminArtistEditForm from 'components/main/admin/AdminArtistEditForm'

import { CONVERSION_STATUS } from 'static'

let mounted

const AdminArtistEditPage = ({
  match,
  adminGetArtist,
  adminUpdateArtist,
}) => {
  const artistId = _.get(match, 'params.id')
  const history = useHistory()
  const [artist, setArtist] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const onUpdateArtist = (values) => adminUpdateArtist(artistId, { artist: values }).then((res) => {
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      if (mounted) {
        history.push(`/admin${_.get(artist, 'url')}`)
      }
    }
  })

  useEffect(() => {
    mounted = true
    setIsLoading(true)
    adminGetArtist(artistId).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (!res.error) {
          setArtist(_.get(res, 'payload.artist'))
        }
      }
    })
    return () => {
      mounted = false
    }
  }, [])

  return (
    <AdminBase breadcrumbs={[
      { label: "Admin", url: '/admin' },
      { label: "Artists", url: '/admin/artists' },
      { label: artist.artistName, url: `/admin${artist.url}` },
      { label: "Edit", active: true },
    ]}>
      <h1>Edit Artist</h1>

      <div className="mb-2">
        <AdminArtistEditForm
          onSubmit={onUpdateArtist}
          artist={artist}
          buttonText="Update"
        />
      </div>

    </AdminBase>
  )
}

export default connect(null, {
  adminGetArtist,
  adminUpdateArtist,
})(AdminArtistEditPage)
