import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'

const PAGINATION_NUM = 3

export default ({ onPageClick, currentPageNum, numPages, className }) => (
  numPages > 1 && (
    <ul className={classnames('pagination', className)}>
      <li className={classnames("page-item", { disabled: currentPageNum <= 0 })}>
        <a className="page-link" onClick={() => onPageClick(currentPageNum-1)}>Prev</a>
      </li>

      {currentPageNum > PAGINATION_NUM && (
        <li className="page-item">
          <a className="page-link" onClick={() => onPageClick(0)}>1</a>
        </li>
      )}
      {currentPageNum > PAGINATION_NUM + 1 && (
        <li className="page-item">
          <span className="page-link disabled">...</span>
        </li>
      )}

      {currentPageNum > 0 && _.map(_.range(_.max([currentPageNum-PAGINATION_NUM, 0]), currentPageNum), (v) => (
        <li key={v} className="page-item">
          <a className="page-link" onClick={() => onPageClick(v)}>{v+1}</a>
        </li>
      ))}

      <li className="page-item active">
        <span className="page-link">{currentPageNum+1} <span className="sr-only">(current)</span></span>
      </li>

      {currentPageNum < numPages - 1 && _.map(_.range(currentPageNum+1, _.min([currentPageNum+PAGINATION_NUM+1, numPages])), (v) => (
        <li key={v} className="page-item">
          <a className="page-link" onClick={() => onPageClick(v)}>{v+1}</a>
        </li>
      ))}

      {currentPageNum < numPages - PAGINATION_NUM - 2 && (
        <li className="page-item">
          <span className="page-link disabled">...</span>
        </li>
      )}
      {currentPageNum < numPages - PAGINATION_NUM - 1 && (
        <li className="page-item">
          <a className="page-link" onClick={() => onPageClick(numPages-1)}>{numPages}</a>
        </li>
      )}

      <li className={classnames("page-item", { disabled: currentPageNum >= numPages-1 })}>
        <a className="page-link" onClick={() => onPageClick(currentPageNum+1)}>Next</a>
      </li>
    </ul>
  )
)
