import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Resources } from 'schema'
import { Link, useHistory } from 'react-router-dom'
import { SubmissionError } from 'redux-form'
import { processApiErrors } from 'utils'
import { selectModel } from 'selectors'
import { updateAlbum, deleteAlbum } from 'actions/api/albums'
import { getArtist, artistOrganizeSongs } from 'actions/api/artists'

import { LIST_TIMEOUT } from 'static'

import NavBar from 'components/main/NavBarV2'
import Breadcrumbs from 'components/main/Breadcrumbs'
import AlbumEditForm from 'components/main/albums/AlbumEditForm'
import SortableList from 'components/main/SortableList'
import ConfirmationButton from 'components/main/ConfirmationButton'

let mounted, listUpdateTimeout

const EditAlbumPage = ({
  album,
  match,
  getArtist,
  updateAlbum,
  deleteAlbum,
  artistOrganizeSongs,
}) => {
  const history = useHistory()

  const albumId = parseInt(_.get(match, 'params.id'))
  const [isLoading, setIsLoading] = useState(false)
  const [albumSongList, setAlbumSongList] = useState([])
  const [singlesList, setSinglesList] = useState([])

  const loadSongsFromRes = (res) => {
    const allSongs = _.get(res, 'payload.raw.artist.songs', [])
    setAlbumSongList(_.filter(allSongs, (s) => s.albumId === albumId))
    setSinglesList(_.filter(allSongs, (s) => _.isNil(s.albumId)))
  }

  const onDeleteAlbum = () => {
    setIsLoading(true)
    deleteAlbum(albumId).then((res) => {
      if (mounted) { setIsLoading(false) }
      if (!res.error) { history.push('/artist') }
    })
  }

  const onUpdateAlbum = (values) => updateAlbum(albumId, { album: values }).then((res) => {
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      const albumUrl = _.get(res, 'payload.raw.album.url')
      history.push(albumUrl)
    }
  })

  const onSongOrder = () => {
    setIsLoading(true)
    clearTimeout(listUpdateTimeout)
    listUpdateTimeout = setTimeout(() => {
      artistOrganizeSongs({
        songs: [
          ..._.map(singlesList, (s, i) => ({ id: s.id, index: i })),
          ..._.map(albumSongList, (s, i) => ({ id: s.id, album_id: albumId, index: i })),
        ],
      }).then((res) => {
        if (mounted) {
          setIsLoading(false)
          if (!res.error) { loadSongsFromRes(res) }
        }
      })
    }, LIST_TIMEOUT)
  }

  useEffect(() => {
    mounted = true
    setIsLoading(true)
    getArtist().then((res) => {
      if (mounted) {
        setIsLoading(false)
        loadSongsFromRes(res)
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffffff" />

      <div className='container pb-5'>
        <Breadcrumbs crumbs={[
          { label: "Home", url: "/artist" },
          { label: album.name, url: album.url },
          { label: "Edit Album", active: true },
        ]} />
        <h1 className="mb-3">{album.name}</h1>
        <h3 className="mb-3">Edit Album</h3>

        <AlbumEditForm onSubmit={onUpdateAlbum} album={album} buttonText="Update" />

        <div className="border-bottom border-dark my-3"></div>

        <h3>Order Songs</h3>

        <p>
          Drag album songs to re-order them.  You can also drag "Singles" into the "Album Songs" list
          to add them to this album, and drag "Album Songs" into "Singles" to remove them from it.
        </p>

        <div className="row">
          <div className="col-6">
            <SortableList
              title='Album Songs'
              list={albumSongList}
              setList={setAlbumSongList}
              onChange={onSongOrder}
              isLoading={isLoading}
              ownProps={{ group: "albumList" }}
              emptyText='This album has no songs.'
              renderItem={(li) => (
                <div key={li.id} className="list-group-item" >
                  <i className="fa fa-bars mr-2"></i> {li.name}
                </div>
              )}
            />
          </div>

          <div className="col-6">
            <SortableList
              title='Singles'
              list={singlesList}
              setList={setSinglesList}
              onChange={onSongOrder}
              isLoading={isLoading}
              ownProps={{ group: "albumList" }}
              emptyText='You have no singles.'
              renderItem={(li) => (
                <div key={li.id} className="list-group-item" >
                  <i className="fa fa-bars mr-2"></i> {li.name}
                </div>
              )}
            />
          </div>
        </div>

        <div className="border-bottom border-dark my-3"></div>

        <ConfirmationButton
          ownProps={{ className: 'btn btn-danger' }}
          confirmText='Really Delete Album?'
          isLoading={isLoading}
          onSuccess={onDeleteAlbum}
        >Delete Album</ConfirmationButton>
      </div>

    </React.Fragment>
  )
}

export default connect((state, ownProps) => ({
  album: selectModel('albums', _.get(ownProps, 'match.params.id'), Resources.album, state) || {},
}), {
  getArtist,
  deleteAlbum,
  updateAlbum,
  artistOrganizeSongs,
})(EditAlbumPage)
