import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'

import { Switch, Route } from 'react-router-dom'

import { getCurrentUser } from 'actions/api/users'
import { selectCurrentUser } from 'selectors'
import { useGoogleAnalytics } from 'hooks/GoogleAnalytics'

import LoginPage from 'components/main/login/LoginPage'
import RegisterPage from 'components/main/register/RegisterPage'
import RegisterArtistPage from 'components/main/register/RegisterArtistPage'

import SendPasswordResetPage from 'components/main/register/SendPasswordResetPage'
import ResetPasswordPage from 'components/main/register/ResetPasswordPage'

import ConfirmEmailPage from 'components/main/register/ConfirmEmailPage'
import EmailConfirmationPage from 'components/main/register/EmailConfirmationPage'
import EmailConfirmedPage from 'components/main/register/EmailConfirmedPage'

import AccountPage from 'components/main/users/AccountPage'

import ArtistDashboard from 'components/main/artist/ArtistDashboard'

import NewAlbumPage from 'components/main/albums/NewAlbumPage'
import AlbumPage from 'components/main/albums/AlbumPage'
import EditAlbumPage from 'components/main/albums/EditAlbumPage'

import NewSongPage from 'components/main/songs/NewSongPage'
import SongAddStems from 'components/main/songs/SongAddStems'
import SongPage from 'components/main/songs/SongPage'
import SongEditPage from 'components/main/songs/SongEditPage'

import StemEditPage from 'components/main/stems/StemEditPage'

import HomePage from 'components/main/HomePage'
import ExplorePage from 'components/main/ExplorePage'
import SearchPage from 'components/main/SearchPage'
import ContactPage from 'components/main/ContactPage'
import WaitlistPage from 'components/main/WaitlistPage'
import FaqPage from 'components/main/FaqPage'

import AdminDashboard from 'components/main/admin/AdminDashboard'
import AdminUserPage from 'components/main/admin/AdminUserPage'
import AdminUserEditPage from 'components/main/admin/AdminUserEditPage'
import AdminArtistPage from 'components/main/admin/AdminArtistPage'
import AdminArtistEditPage from 'components/main/admin/AdminArtistEditPage'
import AdminSongPage from 'components/main/admin/AdminSongPage'
import AdminCommentPage from 'components/main/admin/AdminCommentPage'

import AdminFaqSectionsPage from 'components/main/admin/faq/AdminFaqSectionsPage'
import AdminNewFaqSectionPage from 'components/main/admin/faq/AdminNewFaqSectionPage'
import AdminEditFaqSectionPage from 'components/main/admin/faq/AdminEditFaqSectionPage'

import AdminNewFaqEntryPage from 'components/main/admin/faq/AdminNewFaqEntryPage'
import AdminEditFaqEntryPage from 'components/main/admin/faq/AdminEditFaqEntryPage'

import LoadingPage from 'components/main/LoadingPage'
import NotFound from 'components/main/NotFound'

export const AdminRoutes = ({ user, children }) => {
  if (_.isNil(_.get(user, 'id')) || !_.get(user, 'isAdmin')) {
    return children || null
  }

  return (
    <Switch>
      <Route exact path='/admin' component={AdminDashboard} />
      <Route exact path='/admin/artists' component={AdminDashboard} />
      <Route exact path='/admin/users' component={AdminDashboard} />
      <Route exact path='/admin/songs' component={AdminDashboard} />
      <Route exact path='/admin/comments' component={AdminDashboard} />
      <Route exact path='/admin/explore' component={AdminDashboard} />
      <Route exact path='/admin/search' component={AdminDashboard} />

      <Route exact path='/admin/users/:id' component={AdminUserPage} />
      <Route exact path='/admin/users/:id/edit' component={AdminUserEditPage} />
      <Route exact path='/admin/songs/:id' component={AdminSongPage} />
      <Route exact path='/admin/artists/:id' component={AdminArtistPage} />
      <Route exact path='/admin/artists/:id/edit' component={AdminArtistEditPage} />
      <Route exact path='/admin/comments/:id' component={AdminCommentPage} />

      <Route exact path='/admin/faq' component={AdminFaqSectionsPage} />
      <Route exact path='/admin/faq_sections/new' component={AdminNewFaqSectionPage} />
      <Route exact path='/admin/faq_sections/:id/edit' component={AdminEditFaqSectionPage} />

      <Route exact path='/admin/faq_sections/:id/faq_entries/new' component={AdminNewFaqEntryPage} />
      <Route exact path='/admin/faq_entries/:id/edit' component={AdminEditFaqEntryPage} />

      <Route render={() => children} />
    </Switch>
  )
}

export const LoggedInRoutes = ({ user, children }) => {
  if (_.isNil(_.get(user, 'id'))) { return children || null }
  if (!_.get(user, 'isEmailConfirmed')) {
    return (
      <Switch>
        <Route exact path='/user/edit' component={AccountPage} />
        <Route exact path='/confirm_email' component={ConfirmEmailPage} />
        <Route component={EmailConfirmationPage} />
      </Switch>
    )
  }
  return (
    <Switch>
      <Route exact path='/register_artist' component={RegisterArtistPage} />
      <Route exact path='/email_confirmed' component={EmailConfirmedPage} />
      <Route exact path='/artist' component={ArtistDashboard} />
      <Route exact path='/albums/new' component={NewAlbumPage} />
      <Route exact path='/albums/:id/' component={AlbumPage} />
      <Route exact path='/albums/:id/edit' component={EditAlbumPage} />
      <Route exact path='/albums/:albumId/songs/new' component={NewSongPage} />
      <Route exact path='/songs/new' component={NewSongPage} />
      <Route exact path='/songs/:id' component={SongPage} />
      <Route exact path='/songs/:id/edit' component={SongEditPage} />
      <Route exact path='/songs/:id/add_stems' component={SongAddStems} />
      <Route exact path='/stems/:id/edit' component={StemEditPage} />
      <Route exact path='/user/edit' component={AccountPage} />

      <Route render={() => children} />
    </Switch>
  )
}

export const LoggedOutRoutes = ({ user, children }) => {
  if (!_.isNil(_.get(user, 'id'))) { return children || null }
  return (
    <Switch>
      <Route exact path='/register' component={RegisterPage} />
      <Route exact path='/register_artist' component={RegisterArtistPage} />
      <Route exact path='/login' component={LoginPage} />
      <Route exact path='/send_password_reset' component={SendPasswordResetPage} />
      <Route exact path='/reset_password' component={ResetPasswordPage} />
      <Route exact path='/confirm_email' component={ConfirmEmailPage} />

      <Route render={() => children} />
    </Switch>
  )

}

const StaticRoutes = ({ children }) => {
  return (
    <Switch>
      <Route exact path='/explore' component={ExplorePage} />
      <Route exact path='/search' component={SearchPage} />
      <Route exact path='/contact' component={ContactPage} />
      <Route exact path='/waitlist' component={WaitlistPage} />
      <Route exact path='/faq' component={FaqPage} />
      <Route exact path='/' component={HomePage} />
      <Route render={() => children} />
    </Switch>
  )
}

const AppRoutes = ({ currentUser, getCurrentUser }) => {
  const user = currentUser || {}
  const [isLoading, setIsLoading] = useState(true)

  useGoogleAnalytics()
  useEffect(() => {
    getCurrentUser().then(() => setIsLoading(false))
  }, [])

  if (isLoading) {
    return(<LoadingPage />)
  }


  return (
    <StaticRoutes>
      <AdminRoutes user={user}>
        <LoggedOutRoutes user={user}>
          <LoggedInRoutes user={user}>
            <NotFound />
          </LoggedInRoutes>
        </LoggedOutRoutes>
      </AdminRoutes>
    </StaticRoutes>
  )
}

export default connect((state) => ({
  currentUser: selectCurrentUser(state),
}), {
  getCurrentUser
})(AppRoutes)
