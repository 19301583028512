import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Linkify from 'react-linkify'

import { connect } from 'react-redux'
import { faqData } from 'actions/api/faq'

import NavBar from 'components/main/NavBarV2'
import Accordion from 'components/main/Accordion'

const FaqPage = ({ faqData }) => {
  const [faqSections, setFaqSections] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const loadFaqData = () => {
    setIsLoading(true)
    faqData().then((res) => {
      setIsLoading(false)
      if (res.error) {
        setIsError(true)
      } else {
        setFaqSections(res.payload.faqSections)
      }
    })
  }

  useEffect(() => loadFaqData(), [])

  const parsedFaqSections = _.map(faqSections, (section) => ({
    key: `${section.key}`,
    title: section.title,
    entries: _.map(section.faqEntries, (entry) => ({
      key: entry.key,
      header: entry.question,
      body: entry.answer,
    })),
  }))

  return (
    <>
      <NavBar backgroundColor="#ffcf5a" />

      <div className="min-vh-100 width-100 background-yellow absolute-top-left">
        <div className='navbar-spacer'></div>
        <div className="container py-3">

          <h1 className="text-center mt-3">FAQ</h1>
          <h5 className='text-center mb-3'>Frequently Asked Questions</h5>

          {isError && (
            <div className="text-center alert alert-danger mb-2">
              An error has occurred, please try reloading the page.  If this error persists, please contact <a href="mailto:splitterfm@gmail.com">splitterfm@gmail.com</a>
            </div>
          )}

          {isLoading && (
            <div className="text-center">
              <div className="spinner-grow mb-2" role="status"></div>
              <h3>Loading...</h3>
            </div>
          )}

          {_.map(parsedFaqSections, (section) => (
            <div className='mb-5' key={section.key}>
              <h2 className="mb-3" id={section.key}>{section.title}</h2>
              <Accordion
                accordionKey={`${section.key}-accordion`}
                accordionData={section.entries}
              />
            </div>
          ))}

        </div>
      </div>
    </>
  )
}

export default connect(null, { faqData })(FaqPage)
