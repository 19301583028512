import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import FormInput from 'components/main/FormInput'
import LoadingButton from 'components/main/LoadingButton'

let ArtistEditForm = ({ handleSubmit, submitting, isSuccess }) => (
  <form onSubmit={handleSubmit}>
    <div className="form-group">
      <label htmlFor="artist_name">Artist Name</label>
      <Field name="artist_name" component={FormInput} type="text" classes="form-control" />
    </div>

    <div className="form-group">
      <label htmlFor="artist_url">Artist URL</label>
      <div className="input-group">
        <div className="input-group-prepend">
          <div className="input-group-text">{window.jsonVars.baseUrl}/</div>
        </div>
        <Field name="artist_url" component={FormInput} type="text" classes="form-control" />
      </div>
      <small id="artistUrlDesc" className="form-text">This is your unique splitter.fm URL</small>
    </div>

    <div className="form-group">
      <label htmlFor="patreon_url">Patreon URL</label>
      <div className="input-group">
        <div className="input-group-prepend">
          <div className="input-group-text">https://www.patreon.com/</div>
        </div>
        <Field name="patreon_url" component={FormInput} type="text" classes="form-control" />
      </div>
      <small id="patreonUrlDesc" className="form-text">
        This is the URL that users are sent to when they want to access locked songs.<br/>
        Only write your username in this field, we will automatically prepend "https://www.patreon.com/"
      </small>
    </div>

    <div className="form-group">
      <label htmlFor="artist_bio">Artist Description</label>
      <small id="artistBioDesc" className="form-text">This text will show up on your public artist page</small>
      <Field name="artist_bio" component={FormInput} type="textarea" classes="form-control" />
    </div>

    <div className="form-group form-check">
      <label>
        <Field name="is_hidden" component={FormInput} type="checkbox" classes="form-check-input" />
        Hide Profile
        <small className="form-text">
          When hidden, your artist profile will not appear on the splitter.fm "explore"
          page, but can still be viewed through the direct url.
        </small>
      </label>
    </div>

    <div>
      <LoadingButton
        ownProps={{ className: 'btn btn-primary', type: "submit"}}
        isLoading={submitting}
        isSuccess={isSuccess}
        successText="Updated!"
      >
        Update
      </LoadingButton>
    </div>
  </form>
)

const validate = (values) => {
  const errors = {}
  return errors
}

ArtistEditForm = reduxForm({
  form: 'artistEditForm',
  validate,
  enableReinitialize: true,
})(ArtistEditForm)

export default connect((state, ownProps) => ({
  initialValues: _.mapKeys(ownProps.artist, (v, k) => _.snakeCase(k)),
}))(ArtistEditForm)
