import _ from 'lodash'
import { denormalize } from 'normalizr'
import { Resources } from 'schema'

export function selectCurrentUser(state={}) {
  if (_.isNil(state.currentUserId)) { return {} }
  return denormalize(state.currentUserId, Resources.user, state.api.resources)
}

export function selectModel(modelName, id, schema, state={}) {
  const resources = _.get(state, 'api.resources', {})
  const selected = _.get(resources, `[${modelName}][${id}]`)
  return denormalize(selected, schema, resources)
}

