import _ from 'lodash'
import $ from 'jquery'
import { RSAA, getJSON } from 'redux-api-middleware'
import { normalize } from 'normalizr'
import { Resources } from 'schema'

import { setUrlParams } from 'utils'

export function isResourceAction(action) { return action.type.match(RESOURCE_POSTFIX) }
export function isRequestAction(action) { return action.type.match(RSAA_POSTFIX.REQUEST) }
export function isSuccessAction(action) { return action.type.match(RSAA_POSTFIX.SUCCESS) }
export function isFailureAction(action) { return action.type.match(RSAA_POSTFIX.FAILURE) }

export const RSAA_POSTFIX = {
  REQUEST: '//REQUEST',
  SUCCESS: '//SUCCESS',
  FAILURE: '//FAILURE',
}

export const RESOURCE_POSTFIX = '//RESOURCE'

export const SEARCH_ACTION = 'SEARCH'
export function basicSearch(query, params={}) {
  return get(`/api/search_data`, SEARCH_ACTION, {
    q: query,
    ...params,
  })
}

export const RESOURCE_GET_ACTION = 'RESOURCE_GET'
export function resourceGet(url, actionType) {
  return resourceRSAA(`${RESOURCE_GET_ACTION}//${actionType}`, url)
}

export const GET = 'GET'
export function get(url, actionType, data) {
  if (data) { url = setUrlParams(url, data) }
  return jsonRSAA(`${GET}//${actionType}`, url)
}

export const POST = 'POST'
export function post(url, actionType, data) {
  return jsonRSAA(`${POST}//${actionType}`, url, null, {
    body: JSON.stringify(data),
    method: POST,
  })
}

export const PUT = 'PUT'
export function put(url, actionType, data) {
  return jsonRSAA(`${PUT}//${actionType}`, url, null, {
    body: JSON.stringify(data),
    method: PUT,
  })
}

export const DELETE = 'DELETE'
export function destroy(url, actionType) {
  return jsonRSAA(`${DELETE}//${actionType}`, url, null, {
    method: DELETE,
  })
}

export const SEND_CLIENT_ERROR = 'SEND_CLIENT_ERROR'
export function sendClientError(error_message) {
  return post('/api/client_error/', SEND_CLIENT_ERROR, { error_message })
}

export const UPLOAD_FILE = 'UPLOAD_FILE'
export function uploadFile(url, data) {
  const formData = new FormData();
  _.each(_.keys(data), (key) => {
    formData.append(key, data[key])
  })

  return genRSAA(UPLOAD_FILE, url, (res) => {
    return res.text().then((text) => text)
  }, {
    method: 'POST',
    headers: {
      'Accept': 'application/xml',
      'Origin': `${window.location.protocol}//${window.location.hostname}`,
    },
    body: formData,
  })
}

function addResponseData(res, payload) {
  return _.merge({
    requestStatus: res.status,
    requestStatusText: res.statusText,
    requestUrl: res.url,
  }, payload)
}

export function genRSAA(actionType, endpoint, parseData=null, options={}) {
  return {
    [RSAA]: _.merge({
      credentials: 'same-origin',
      method: 'GET',
      endpoint: endpoint,
      types: [
        `${actionType}${RSAA_POSTFIX.REQUEST}`,
        {
          type: `${actionType}${RSAA_POSTFIX.SUCCESS}`,
          payload: (action, state, res) => {
            if (parseData) {
              return parseData(res)
            } else {
              return getJSON(res).then((json) => addResponseData(res, json))
            }
          }
        },
        {
          type: `${actionType}${RSAA_POSTFIX.FAILURE}`,
          payload: (action, state, res) => {
            return getJSON(res).then((json) => addResponseData(res, json))
          }
        }
      ],
    }, options)
  }
}

export function jsonRSAA(actionType, endpoint, parseData=null, options={}) {
  return genRSAA(actionType, endpoint, parseData, _.merge({
    headers: (state) => {
      const headerOut = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      }
      if (!_.isNil(state.urlAuthToken)) {
        header_out['Url-Auth-Token'] = state.urlAuthToken
      }
      return headerOut
    },
  }, options))
}

export function parseResources(res) {
  return getJSON(res).then((json) => {
    let output = { raw: json }

    _.each(_.keys(json), (key) => {
      const resource = Resources[key]
      if (resource) {
        const normalized = normalize(json[key], resource)
        output = _.merge(output, normalized)
      } else {
        output = _.merge(output, { result: { [key]: json[key] } })
      }
    })

    return addResponseData(res, output)
  })
}

export function resourceRSAA(actionType, endpoint, options={}) {
  return jsonRSAA(`${actionType}${RESOURCE_POSTFIX}`, endpoint, (res) => parseResources(res), options)
}

export const SUCCESS_STATUSES = [200, 201]

export function isSuccessStatus(status) {
  return _.includes(SUCCESS_STATUSES, status)
}
