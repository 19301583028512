import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import { DirectUploadProvider } from 'react-activestorage-provider'
import Dropzone from 'react-dropzone'

const getReadableFileSizeString = (fileSizeInBytes) => {
  let i = 0;
  let byteUnits = ['bytes', 'kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
  while (fileSizeInBytes > 1024) {
    fileSizeInBytes = fileSizeInBytes / 1024
    i++
  }
  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
}


const FileUploadRow = ({ upload }) => {
  switch (upload.state) {
    case 'waiting':
      return <div className="list-group-item">Preparing to upload {upload.file.name}</div>
    case 'uploading':
      return (
        <div className="list-group-item">
          <div className="mb-2">
            Uploading {upload.file.name}: {_.round(upload.progress)}%
          </div>
          <div className="progress">
            <div className="progress-bar"
              role="progressbar"
              style={{'width': `${upload.progress}%`}}
              aria-valuenow={upload.progress}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      )
    case 'error':
      return (
        <div className="list-group-item list-group-item-danger">
          Error uploading {upload.file.name}: {upload.error}
        </div>
      )
    case 'finished':
      return (
        <div className="list-group-item list-group-item-success">Finished uploading {upload.file.name}</div>
      )
  }
  return null
}

export default ({
  onUploadSuccess,
  setFileData,
  fileData,
  fileTypes,
  allowMultiple,
  isDisabled,
}) => {
  const isFilesInvalid = () => _.reduce(fileData, (memo, file) => memo || isFileInvalid(file), false)

  const isFileInvalid = (file) => {
    // if there are no fileTypes, then allow all
    if (_.isNil(fileTypes) || _.isEmpty(fileTypes)) { return false }

    let isValid = false // assume invalid
    // look through fileTypes for a match
    _.each(_.keys(fileTypes), (fileType) => {
      if (file.type === fileType) {
        if (_.isNil(fileTypes[fileType])) {
          isValid = true // if match has no filesize limit, it's valid!
        } else {
          isValid = file.size < fileTypes[fileType] // otherwise, it's valid if the file is below the size limit
        }
      }
    }, false)
    return !isValid
  }

  return (
    <DirectUploadProvider
      onSuccess={onUploadSuccess}
      render={({ handleUpload, uploads, ready }) => (
        <div>
          {ready && (
            <Dropzone onDrop={acceptedFiles => setFileData(acceptedFiles)} multiple={allowMultiple}>
              {({getRootProps, getInputProps}) => (
                <div {...getRootProps()} className="mb-3 p-3 rounded border border-primary bg-light text-center">
                  <input {...getInputProps()} />
                  <i className="fa fa-files-o icon-large" aria-hidden="true"></i>
                  <div>Drag and drop (or click here to browse your files)</div>
                </div>
              )}
            </Dropzone>
          )}

          {_.isEmpty(uploads) && (
            <div className="mb-3 list-group">
              {_.map(fileData, (f, i) => (
                <div key={`${f.name}-${i}`} className={classnames("list-group-item", { "list-group-item-danger": isFileInvalid(f) })}>
                  {f.name} - {getReadableFileSizeString(f.size)}
                  {isFileInvalid(f) && (
                    <span className="ml-2">
                      {_.isNil(fileTypes[f.type]) ? `invalid file type` : `cannot be larger than ${getReadableFileSizeString(fileTypes[f.type])}`}
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}

          {!_.isNil(fileData) && !_.isEmpty(fileData) && (
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                if (!_.isNil(fileData) && !isFilesInvalid() && !isDisabled) { return handleUpload(fileData) }
              }}
              disabled={_.isNil(fileData) || !_.isEmpty(uploads) || isFilesInvalid() || isDisabled}
            >Upload</button>
          )}

          {!_.isEmpty(uploads) && (
            <div className="list-group mt-2">
              {uploads.map(upload => (<FileUploadRow key={upload.id} upload={upload} />))}
            </div>
          )}
        </div>
      )}
    />
  )
}
