import React, { useState } from 'react'

import LoadingButton from 'components/main/LoadingButton'

export default ({ onSuccess, ownProps={}, confirmText, isLoading, children }) => {
  const [isConfirmation, setIsConfirmation] = useState(false)

  if (isConfirmation) {
    return (
      <React.Fragment>
        <LoadingButton
          ownProps={{
            ...ownProps,
            onClick: onSuccess,
          }}
          isLoading={isLoading}
        >
          {confirmText}
        </LoadingButton>
        <button className="btn btn-secondary ml-2" onClick={() => setIsConfirmation(false)} disabled={isLoading}>
          Cancel
        </button>
      </React.Fragment>
    )
  } else {
    return (
      <LoadingButton
        ownProps={{
          ...ownProps,
          onClick: () => setIsConfirmation(true),
        }}
        isLoading={isLoading}
      >
        {children}
      </LoadingButton>
    )
  }
}
