import { useEffect } from 'react'
import _ from 'lodash'

import { useHistory } from 'react-router-dom'

export const useGoogleAnalytics = (googleAnalyticsId = window.GOOGLE_ANALYTICS_ID) => {
  const history = useHistory()
  const listen = _.get(history, 'listen')

  useEffect(() => {
    if (_.isNil(listen)) { return null }
    return listen((location) => {
      if (_.isNil(window.gtag)) { return null }
      if (_.isNil(googleAnalyticsId)) { return null }

      window.gtag('config', googleAnalyticsId, { page_path: location.pathname })
    })
  }, [googleAnalyticsId, listen])
}
