import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import moment from 'moment'

import { connect } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'

import { adminSearch } from 'actions/api/admin'

import Pagination from 'components/main/Pagination'

import { parseUrlParams, setUrlParams } from 'utils'

let mounted

const AdminSearchPage = ({
  adminSearch,
}) => {
  const location = useLocation()
  const history = useHistory()

  const urlData = parseUrlParams(location.search)
  const urlParams = urlData.params || {}
  const urlPage = parseInt(urlParams.page, 10)
  const defaultPage = _.isNaN(urlPage) ? 0 : urlPage

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [query, setQuery] = useState('')
  const [listPages, setListPages] = useState([])
  const [currentPageNum, setCurrentPageNum] = useState(defaultPage)
  const [numPages, setNumPages] = useState(0)

  const prefixUrl = (url) => `/admin${url}`

  const handleSearchResponse = (res) => {
    const resPageNum = _.get(res, 'payload.page')
    const resNumPages = _.get(res, 'payload.numPages')
    const resQuery = _.get(res, 'payload.query')
    const resPage = _.get(res, 'payload.results')
    const newPages = [ ...listPages ]
    newPages[resPageNum] = resPage
    setListPages(newPages)
    setCurrentPageNum(resPageNum)
    setNumPages(resNumPages)
    setQuery(resQuery)
  }

  const onSearch = (e) => {
    if (!_.isNil(e)) { e.preventDefault() }
    setIsLoading(true)
    setCurrentPageNum(0)
    adminSearch({ q: query, page: 0 }).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (res.error) {
          setIsError(true)
        } else {
          handleSearchResponse(res)
        }
      }
    })
  }

  const onLoadPage = (page) => {
    setIsLoading(true)
    setCurrentPageNum(page)
    adminSearch({ q: query, page: page }).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (res.error) {
          setIsError(true)
        } else {
          handleSearchResponse(res)
        }
      }
    })
  }

  useEffect(() => {
    const urlData = parseUrlParams(location.search)
    history.replace(setUrlParams(urlData.url, { page: currentPageNum, q: query }))
  }, [currentPageNum, query])

  useEffect(() => {
    mounted = true
    return () => {
      mounted = false
    }
  }, [])

  const columns = [
    { name: 'ID', key: 'id' },
    { name: 'Type', key: 'type' },
    { name: 'Name', key: 'name', link: 'url' },
    { name: 'Created', key: 'createdAt', getValue: (i) => moment(i.createdAt).fromNow() },
  ]

  const currentPage = listPages[currentPageNum] || []

  return (
    <React.Fragment>

      <form onSubmit={(e) => onSearch(e)}>
        <div className="input-group my-3">
          <input type="search" className="form-control" placeholder="Search" value={query} onChange={(e) => setQuery(e.target.value)} />
          <div className="input-group-append">
            <button className="input-group-button btn btn-primary" onClick={() => onSearch()}>
              Search
            </button>
          </div>
        </div>
      </form>

      <table className="table table-striped table-hover">
        <thead>
          <tr>
            {_.map(columns, (col) => (<th key={col.key} scope="col">{col.name}</th>))}
          </tr>
        </thead>
        <tbody>
          {_.map(currentPage, (item) => (
            <tr key={item.id}>
              {_.map(columns, (col) => (col.link && _.get(item, col.link)) ? (
                <td key={`${item.id}-${col.key}`}>
                  <Link to={prefixUrl(_.get(item, col.link))}>
                    {_.get(item, col.key)}
                  </Link>
                </td>
              ) : (
                <td key={`${item.id}-${col.key}`}>{!_.isNil(col.getValue) ? col.getValue(item) : _.get(item, col.key)}</td>
              ))}
            </tr>
          ))}
          {!isLoading && _.isEmpty(currentPage) && (
            <tr>
              <td colSpan={columns.length} className="text-center">
                There are no search results.  Try another query!
              </td>
            </tr>
          )}
          {isLoading && (
            <tr>
              <td colSpan={columns.length} className="text-center">
                <span className="spinner-grow spinner-border-sm" role="status" aria-hidden="true">
                  <span className="sr-only">Loading...</span>
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagination onPageClick={onLoadPage} currentPageNum={currentPageNum} numPages={numPages} />

    </React.Fragment>
  )
}

export default connect(null, {
  adminSearch,
})(AdminSearchPage)
