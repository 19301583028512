import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import NavBar from 'components/main/NavBarV2'

export default () => {
  const history = useHistory()
  const [countdown, setCountdown] = useState(4)

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (countdown <= 0) {
        history.push("/")
      } else {
        setCountdown(countdown - 1)
      }
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [countdown])

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffcf5a" />

      <div className="min-vh-100 width-100 background-yellow absolute-top-left">
        <div className='navbar-spacer'></div>
        <div className="container py-3 text-center">

          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-8 col-lg-6">
              <div className="mx-3">
                <h1 className="my-3">Page Not Found</h1>
                You will be redirected to the homepage automatically in {countdown} seconds.
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
