import React from 'react'

import NavBar from 'components/main/NavBarV2'

const ContactPage = () => (
  <>
    <NavBar backgroundColor="#ffcf5a" />

    <div className="min-vh-100 width-100 background-yellow absolute-top-left">
      <div className='navbar-spacer'></div>

      <div className="container text-center py-3">
        <h1 className="mt-3 mb-4">Contact Us</h1>

        <p>
          You can contact us by emailing <a href="mailto:splitterfm@gmail.com">splitterfm@gmail.com</a>.
        </p>

        <p>
          Please let us know if you have any questions, comments, or concerns!
        </p>
      </div>

    </div>
  </>
)

export default ContactPage
