import { get, put } from 'actions/api'

export const ADMIN_GET_ARTIST_INDEX_ACTION = 'ADMIN_GET_ARTIST_INDEX'
export function adminGetArtistIndex(page) {
  return get(`/api/admin/artists/?page=${page}`, ADMIN_GET_ARTIST_INDEX_ACTION)
}

export const ADMIN_GET_ARTIST_ACTION = 'ADMIN_GET_ARTIST'
export function adminGetArtist(id) {
  return get(`/api/admin/artists/${id}`, ADMIN_GET_ARTIST_ACTION)
}

export const ADMIN_UPDATE_ARTIST_ACTION = 'ADMIN_UPDATE_ARTIST'
export function adminUpdateArtist(id, data) {
  return put(`/api/admin/artists/${id}`, ADMIN_UPDATE_ARTIST_ACTION, data)
}

export const ADMIN_GET_HIGHLIGHTED_ARTISTS_ACTION = 'ADMIN_GET_HIGHLIGHTED_ARTISTS'
export function adminGetHighlightedArtists() {
  return get(`/api/admin/highlighted_artists`, ADMIN_GET_HIGHLIGHTED_ARTISTS_ACTION)
}

export const ADMIN_ORDER_HIGHLIGHTED_ARTISTS_ACTION = 'ADMIN_ORDER_HIGHLIGHTED_ARTISTS'
export function adminOrderHighlightedArtists(data) {
  return put(`/api/admin/highlighted_artists`, ADMIN_ORDER_HIGHLIGHTED_ARTISTS_ACTION, data)
}
