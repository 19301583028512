import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import { SONG_TYPES } from 'static'

import FormInput from 'components/main/FormInput'
import LoadingButton from 'components/main/LoadingButton'

let SongEditForm = ({ handleSubmit, submitting, artist, album, buttonText, isCreate, currentValues, initialValues }) => {
  const history = useHistory()
  const isSongHidden = _.get(album, 'isHidden', false) || _.get(currentValues, 'isHidden', false)
  return (
    <form onSubmit={handleSubmit}>

      <div className="form-group">
        <label htmlFor="name">Song Name</label>
        <Field name="name" component={FormInput} type="text" classes="form-control" />
      </div>

      {artist.isMixComparisonEnabled && (
        <div className="form-group">
          <label htmlFor="song_type">Song Type</label>
          <Field name="song_type" component={FormInput} type="select" classes="form-control">
            {_.map(_.values(SONG_TYPES), (songType) => (
              <option value={songType.enumValue}>{songType.name}</option>
            ))}
          </Field>
        </div>
      )}

      <div className="form-group">
        <label htmlFor="url_string">Custom Song URL</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">{window.jsonVars.baseUrl}{artist.publicUrl}/</div>
          </div>
          <Field name="url_string" component={FormInput} type="text" classes="form-control" />
        </div>
        <small id="urlStringDesc" className="form-text">If left blank, a random URL will be generated automatically</small>
      </div>

      <div className="form-group">
        <label htmlFor="description">Song Description</label>
        <Field name="description" component={FormInput} type="textarea" classes="form-control" />
      </div>

      <div className="form-group">
        <label htmlFor="tempo">Tempo <small>(BPM)</small></label>
        <Field name="tempo" component={FormInput} type="number" classes="form-control" />
        <small>optional &ndash; this allows users to more easily loop sections</small>
      </div>

      <div className="form-group form-check">
        <label>
          <Field name="is_artist_comments_only" component={FormInput} type="checkbox" classes="form-check-input" />
          Artist Comments Only
          <small className="form-text">
            When this is checked, only you (the artist) can leave comments on your song.
            Fans can still view comments you have left.
          </small>
        </label>
      </div>

      <div className="form-group form-check">
        <label>
          <Field name="is_downloadable" component={FormInput} type="checkbox" classes="form-check-input" />
          Allow Downloads
          <small className="form-text">
            When this is checked, users will be able to download your stems from the player page.
          </small>
        </label>
      </div>

      <div className="form-group form-check">
        <label>
          <Field name="is_comments_disabled" component={FormInput} type="checkbox" classes="form-check-input" />
          Disable All Comments
          <small className="form-text">
            When this is checked, comments will be fully disabled on this song.
          </small>
        </label>
      </div>

      <div className="form-group form-check">
        <label>
          <Field name="is_looping_disabled" component={FormInput} type="checkbox" classes="form-check-input" />
          Disable Looping
          <small className="form-text">
            When this is checked, users will NOT be able to loop sections of your song in the stem player.
          </small>
        </label>
      </div>

      {_.get(album, 'isHidden', false) ? (
        <div className="alert alert-warning">
          <p>
            This song is on a hidden album.
          </p>
          <i>Songs on hidden albums do not appear on your public artist page or the splitter.fm "explore" page, but can still be viewed through the direct url.</i>
        </div>
      ) : (
        <div className="form-group form-check">
          <label>
            <Field name="is_hidden" component={FormInput} type="checkbox" classes="form-check-input" />
            Hide Song
            <small className="form-text">
              Hidden songs do not appear on your public artist page or the Splitter.FM "explore" page,
              but can still be viewed through the direct url
            </small>
          </label>
        </div>
      )}

      {_.isEmpty(_.get(artist, 'patreonUrl')) ? (
        <React.Fragment>
          <div className="alert alert-warning">
            <i className='fa fa-exclamation-triangle'></i> You must set a Patreon URL in your <Link to="/artist/edit">artist settings</Link> before using this feature.
          </div>
          <div className="form-group form-check">
            <label>
              <input name="is_patreon_only" checked={_.get(currentValues, 'isPatreonOnly', false)} type="checkbox" className="form-check-input" disabled />
              Lock Song (Patreon Only)
              <small className="form-text">
                Locked songs are visible on the artist page, but will have a key logo and link to Patreon.
              </small>
            </label>
          </div>
        </React.Fragment>
      ) : isSongHidden ? (
        <React.Fragment>
          <div className="alert alert-warning">
            <i className='fa fa-exclamation-triangle'></i> This song is hidden, and cannot be "Locked".  If you would like this song to be "Locked", please uncheck the "Hide Song" box above.
          </div>
          <div className="form-group form-check">
            <label>
              <input name="is_patreon_only" checked={_.get(currentValues, 'isPatreonOnly', false)} type="checkbox" className="form-check-input" disabled />
              Lock Song (Patreon Only)
              <small className="form-text">
                Locked songs are visible on the artist page, but will have a key logo and link to Patreon.
              </small>
            </label>
          </div>
        </React.Fragment>
      ) : (
        <div className="form-group form-check">
          <label>
            <Field name="is_patreon_only" component={FormInput} type="checkbox" classes="form-check-input" disabled={isSongHidden} />
            Lock Song (Patreon Only)
            <small className="form-text">
              Locked songs are visible on the artist page, but will have a key logo and link to Patreon.
            </small>
          </label>
        </div>
      )}

      {isCreate && (
        <div className="alert alert-warning">
          On the next page, you will upload your audio files.
        </div>
      )}

      <div className='mb-3'>
        <LoadingButton
          ownProps={{ className: 'btn btn-primary mr-2', type: "submit"}}
          isLoading={submitting}
        >
          {buttonText}
        </LoadingButton>
        <a className='btn btn-secondary' onClick={() => history.goBack()}>Cancel</a>
      </div>
    </form>
  )
}

const validate = (values) => {
  const errors = {}
  return errors
}


SongEditForm = reduxForm({
  form: 'songEditForm',
  validate,
  enableReinitialize: true,
})(SongEditForm)

const fvs = formValueSelector('songEditForm')

export default connect((state, ownProps) => ({
  initialValues: _.mapKeys(_.get(ownProps, 'song', {}), (v, k) => _.snakeCase(k)),
  currentValues: {
    isHidden: fvs(state, 'is_hidden'),
    isPatreonOnly: fvs(state, 'is_patreon_only'),
  },
}))(SongEditForm)
