import { get, post, put, destroy } from 'actions/api'

export const ADMIN_GET_FAQ_SECTIONS_ACTION = 'ADMIN_GET_FAQ_SECTIONS'
export function adminGetFaqSections() {
  return get('/api/admin/faq_sections', ADMIN_GET_FAQ_SECTIONS_ACTION)
}

export const ADMIN_ORDER_FAQ_SECTIONS_ACTION = 'ADMIN_ORDER_FAQ_SECTIONS'
export function adminOrderFaqSections(data) {
  return put('/api/admin/faq_sections/order', ADMIN_ORDER_FAQ_SECTIONS_ACTION, data)
}

export const ADMIN_GET_FAQ_SECTION_ACTION = 'ADMIN_GET_FAQ_SECTION'
export function adminGetFaqSection(id) {
  return get(`/api/admin/faq_sections/${id}`, ADMIN_GET_FAQ_SECTION_ACTION)
}

export const ADMIN_UPDATE_FAQ_SECTION_ACTION = 'ADMIN_UPDATE_FAQ_SECTION'
export function adminUpdateFaqSection(id, data) {
  return put(`/api/admin/faq_sections/${id}`, ADMIN_UPDATE_FAQ_SECTION_ACTION, data)
}

export const ADMIN_CREATE_FAQ_SECTION_ACTION = 'ADMIN_CREATE_FAQ_SECTION'
export function adminCreateFaqSection(data) {
  return post(`/api/admin/faq_sections/`, ADMIN_CREATE_FAQ_SECTION_ACTION, data)
}

export const ADMIN_DELETE_FAQ_SECTION_ACTION = 'ADMIN_DELETE_FAQ_SECTION'
export function adminDeleteFaqSection(id, data) {
  return destroy(`/api/admin/faq_sections/${id}`, ADMIN_DELETE_FAQ_SECTION_ACTION)
}

export const ADMIN_ORDER_FAQ_ENTRIES_ACTION = 'ADMIN_ORDER_FAQ_ENTRIES'
export function adminOrderFaqEntries(sectionId, data) {
  return put(`/api/admin/faq_sections/${sectionId}/order_entries`, ADMIN_ORDER_FAQ_ENTRIES_ACTION, data)
}

export const ADMIN_GET_FAQ_ENTRY_ACTION = 'ADMIN_GET_FAQ_ENTRY'
export function adminGetFaqEntry(id) {
  return get(`/api/admin/faq_entries/${id}`, ADMIN_GET_FAQ_ENTRY_ACTION)
}

export const ADMIN_UPDATE_FAQ_ENTRY_ACTION = 'ADMIN_UPDATE_FAQ_ENTRY'
export function adminUpdateFaqEntry(id, data) {
  return put(`/api/admin/faq_entries/${id}`, ADMIN_UPDATE_FAQ_ENTRY_ACTION, data)
}

export const ADMIN_CREATE_FAQ_ENTRY_ACTION = 'ADMIN_CREATE_FAQ_ENTRY'
export function adminCreateFaqEntry(sectionId, data) {
  return post(`/api/admin/faq_sections/${sectionId}/faq_entries/`, ADMIN_CREATE_FAQ_ENTRY_ACTION, data)
}

export const ADMIN_DELETE_FAQ_ENTRY_ACTION = 'ADMIN_DELETE_FAQ_ENTRY'
export function adminDeleteFaqEntry(id, data) {
  return destroy(`/api/admin/faq_entries/${id}`, ADMIN_DELETE_FAQ_ENTRY_ACTION)
}
