import React, { useEffect, useState, useRef } from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import Linkify from 'react-linkify'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { adminGetSong, adminGetSongComments } from 'actions/api/admin/songs'
import {
  adminTriggerStemConversion,
  adminTriggerStemReconversion,
  adminRegenerateStemImage
} from 'actions/api/admin/stems'

import AdminBase from 'components/main/admin/AdminBase'
import LoadingButton from 'components/main/LoadingButton'
import PaginatedList from 'components/main/PaginatedList'

import { secondsToMinutes } from 'utils/time'
import { CONVERSION_STATUS } from 'static'

let mounted, buttonSuccessTimeout, copiedTimeout

const BUTTONS = {
  TRIGGER_RECONVERSION: "trigger_reconversion",
  TRIGGER_CONVERSION: "trigger_conversion",
  REGENERATE_IMAGE: "regen_image",
}

const SUCCESS_TIMEOUT = 5000

const AdminSongPage = ({
  match,
  adminGetSong,
  adminGetSongComments,
  adminTriggerStemConversion,
  adminTriggerStemReconversion,
  adminRegenerateStemImage,
}) => {
  const songId = _.get(match, 'params.id')
  const [song, setSong] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [buttonSuccess, setButtonSuccess] = useState({})

  const shareLinkEl = useRef(null)
  const [isCopiedSuccess, setIsCopiedSuccess] = useState(false)

  const clearButtonSuccess = () => setButtonSuccess({})

  const onTriggerStemConversion = (id) => {
    setIsLoading(true)
    adminTriggerStemConversion(id).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (!res.error) {
          setButtonSuccess({ stemId: id, button: BUTTONS.TRIGGER_CONVERSION })
          setTimeout(clearButtonSuccess, SUCCESS_TIMEOUT)
        }
      }
    })
  }

  const onTriggerStemReconversion = (id) => {
    setIsLoading(true)
    adminTriggerStemReconversion(id).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (!res.error) {
          setButtonSuccess({ stemId: id, button: BUTTONS.TRIGGER_RECONVERSION })
          setTimeout(clearButtonSuccess, SUCCESS_TIMEOUT)
        }
      }
    })
  }

  const onRegenerateStemImage = (id) => {
    setIsLoading(true)
    adminRegenerateStemImage(id).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (!res.error) {
          setButtonSuccess({ stemId: id, button: BUTTONS.REGENERATE_IMAGE })
          setTimeout(clearButtonSuccess, SUCCESS_TIMEOUT)
        }
      }
    })
  }

  const copyShareLink = () => {
    shareLinkEl.current.select()
    document.execCommand('copy')
    setIsCopiedSuccess(true)
    copiedTimeout = setTimeout(() => setIsCopiedSuccess(false), 1000)
  }

  const getSongComments = (page) => adminGetSongComments(songId, page)

  useEffect(() => {
    mounted = true
    setIsLoading(true)
    adminGetSong(songId).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (!res.error) {
          setSong(_.get(res, 'payload.song'))
        }
      }
    })
    return () => {
      mounted = false
      clearTimeout(buttonSuccessTimeout)
      clearTimeout(copiedTimeout)
    }
  }, [])

  return (
    <AdminBase breadcrumbs={[
      { label: "Admin", url: '/admin' },
      { label: "Songs", url: '/admin/songs' },
      { label: song.name, active: true },
    ]}>
      <h1>{song.name} ({songId})</h1>

      <div className="mb-3">
        <div className="row">
          <div className="col-3 col-lg-2">Artist:</div>
          <div className="col-9 col-lg-10">
            <Link to={`/admin${_.get(song, 'artist.url')}`}>{_.get(song, 'artist.artistName')}</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">User:</div>
          <div className="col-9 col-lg-10">
            <Link to={`/admin${_.get(song, 'artist.user.url')}`}>{_.get(song, 'artist.user.email')}</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">Album:</div>
          <div className="col-9 col-lg-10">
            {_.get(song, 'album.name')}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">Track #:</div>
          <div className="col-9 col-lg-10">
            {_.get(song, 'index') + 1}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2"># Comments:</div>
          <div className="col-9 col-lg-10">
            {_.get(song, 'numComments', 0)}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2"># Views:</div>
          <div className="col-9 col-lg-10">
            {_.get(song, 'totalViews', 0)}
          </div>
        </div>
        {_.get(song, 'isPatreonOnly', false) && (
          <div className="row">
            <div className="col-3 col-lg-2"># Patreon Clicks:</div>
            <div className="col-9 col-lg-10">
              {_.get(song, 'totalPatreonRedirects', 0)}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-3 col-lg-2">Created:</div>
          <div className="col-9 col-lg-10">
            {moment(song.createdAt).fromNow()}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">Last Edited:</div>
          <div className="col-9 col-lg-10">
            {moment(song.updatedAt).fromNow()}
          </div>
        </div>
      </div>

      {_.get(song, 'isHidden') && (
        <div className="alert alert-warning">
          <i className="fa fa-eye-slash mr-2"></i>
          This song is hidden
        </div>
      )}

      {_.get(song, 'album.isHidden') && (
        <div className="alert alert-warning">
          <i className="fa fa-eye-slash mr-2"></i>
          This song is on a hidden album
        </div>
      )}

      <div className="d-flex flex-row">
        <div className="flex-grow-0">
          <div className='btn-group mr-2'>
            <a target="_blank" className="btn btn-secondary" href={_.get(song, 'publicUrl')}>View</a>
            <button className="btn btn-primary" onClick={copyShareLink}>
              {isCopiedSuccess ? 'Copied!' : 'Copy'}
            </button>
          </div>
        </div>
        <div className="flex-grow-1">
          <div className="form-group">
            <input ref={shareLinkEl} type="text" className="share-url form-control" value={_.get(song, 'fullPublicUrl', '')} readOnly />
          </div>
        </div>
      </div>

      <div className="mb-3">
        <h5>Description:</h5>
        <Linkify className="border rounded prewrap break-word p-3 mt-1 d-block">
          {_.get(song, 'description')}
        </Linkify>
      </div>

      <h3>Stems</h3>
      <div className="list-group mb-4">
        {_.map(song.stems, (stem) => (
          <div key={stem.id} className={classnames("list-group-item", {
            "list-group-item-danger": stem.conversionStatus === CONVERSION_STATUS.FAILED,
            "list-group-item-info": stem.conversionStatus === CONVERSION_STATUS.PENDING,
          })}>
            <div>
              <strong>
                {stem.index}&nbsp;-&nbsp;{stem.name}
              </strong>
              <span className="stem-color ml-2" style={{ 'backgroundColor': stem.bgColor }}></span>
              {!_.isNil(stem.audioFileUrl) && (
                <a href={stem.audioFileUrl} target="_blank" className="badge ml-2 badge-dark">Audio File</a>
              )}
              {!_.isNil(stem.imageFileUrl) && (
                <a href={stem.imageFileUrl} target="_blank" className="badge ml-2 badge-dark">Waveform Image File</a>
              )}
              {!_.isNil(stem.cacheFileUrl) && (
                <a href={stem.cacheFileUrl} target="_blank" className="badge ml-2 badge-warning">Cache File</a>
              )}
            </div>
            <div className="mb-2">
              <div className="row">
                <div className="col-3 col-lg-2">Conversion Status:</div>
                <div className="col-9 col-lg-10">
                  {_.get(stem, 'conversionStatus')}
                </div>
              </div>
              <div className="row">
                <div className="col-3 col-lg-2">Filename:</div>
                <div className="col-9 col-lg-10">
                  {_.get(stem, 'originalFilename')}
                </div>
              </div>
              <div className="row">
                <div className="col-3 col-lg-2"># Comments:</div>
                <div className="col-9 col-lg-10">
                  {_.get(stem, 'numComments')}
                </div>
              </div>
            </div>
            <div>
              {!_.isNil(stem.cacheFileUrl) && (
                <LoadingButton
                  isLoading={isLoading}
                  isSuccess={stem.id === buttonSuccess.stemId && buttonSuccess.button === BUTTONS.TRIGGER_CONVERSION}
                  successText="Action Started"
                  ownProps={{
                    className: "btn btn-secondary btn-sm",
                    onClick: () => onTriggerStemConversion(stem.id),
                  }}
                >Start Conversion</LoadingButton>
              )}
              {!_.isNil(stem.audioFileUrl) && (
                <React.Fragment>
                  <LoadingButton
                    isLoading={isLoading}
                    isSuccess={stem.id === buttonSuccess.stemId && buttonSuccess.button === BUTTONS.TRIGGER_RECONVERSION}
                    successText="Action Started"
                    ownProps={{
                      className: "btn btn-secondary btn-sm mr-2",
                      onClick: () => onTriggerStemReconversion(stem.id),
                    }}
                  >Reconvert Audio</LoadingButton>
                  <LoadingButton
                    isLoading={isLoading}
                    isSuccess={stem.id === buttonSuccess.stemId && buttonSuccess.button === BUTTONS.REGENERATE_IMAGE}
                    successText="Action Started"
                    ownProps={{
                      className: "btn btn-secondary btn-sm",
                      onClick: () => onRegenerateStemImage(stem.id),
                    }}
                  >Regenerate Waveform Image</LoadingButton>
                </React.Fragment>
              )}
            </div>
          </div>
        ))}
      </div>

      <h3>Comments</h3>
      <PaginatedList
        getListRequest={getSongComments}
        listName='comments'
        urlPrefix='/admin'
        columns={[
          { name: 'ID', key: 'id', link: 'url' },
          { name: 'Username', key: 'user.username', link: 'user.url' },
          { name: 'Stem', key: 'stem.name' },
          { name: 'Time', key: 'stemTime', getValue: (s) => secondsToMinutes(s.stemTime) },
          { name: 'Created', key: 'createdAt', getValue: (i) => moment(i.createdAt).fromNow() },
        ]}
      />
    </AdminBase>
  )
}

export default connect(null, {
  adminGetSong,
  adminGetSongComments,
  adminTriggerStemConversion,
  adminTriggerStemReconversion,
  adminRegenerateStemImage,
})(AdminSongPage)
