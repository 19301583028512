import { schema } from 'normalizr'

// FAQ_ENTRY
const faqEntrySchema = new schema.Entity('faqEntries', {})

// FAQ_SECTION
const faqSectionSchema = new schema.Entity('faqSections', {
  faqEntries: [faqEntrySchema],
})

// STEM
const stemSchema = new schema.Entity('stems', {})

// SONG
const songSchema = new schema.Entity('songs', {
  stems: [stemSchema],
})

// ALBUM
const albumSchema = new schema.Entity('albums', {
  songs: [songSchema],
})

// ARTIST
const artistSchema = new schema.Entity('artists', {
  user: new schema.Entity('users'),
  songs: [songSchema],
  albums: [albumSchema],
})

// USER
const userSchema = new schema.Entity('users', {
  artist: artistSchema,
})

export const Resources = {
  user: userSchema,
  users: [userSchema],

  artist: artistSchema,
  artists: [artistSchema],

  album: albumSchema,
  albums: [albumSchema],

  song: songSchema,
  songs: [songSchema],

  stem: stemSchema,
  stems: [stemSchema],

  faqEntry: faqEntrySchema,
  faqEntries: [faqEntrySchema],

  faqSection: faqSectionSchema,
  faqSections: [faqSectionSchema],
}

