import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import { Link, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import AdminBase from 'components/main/admin/AdminBase'
import AdminArtistsList from 'components/main/admin/AdminArtistsList'
import AdminUsersList from 'components/main/admin/AdminUsersList'
import AdminSongsList from 'components/main/admin/AdminSongsList'
import AdminExploreList from 'components/main/admin/AdminExploreList'
import AdminCommentsList from 'components/main/admin/AdminCommentsList'
import AdminSearchPage from 'components/main/admin/AdminSearchPage'

const PATHS = {
  ARTISTS: "/admin/artists",
  SONGS: "/admin/songs",
  USERS: "/admin/users",
  COMMENTS: "/admin/comments",
  EXPLORE: "/admin/explore",
  SEARCH: "/admin/search",
}
const AdminDashboard = ({ match }) => {
  const path = _.get(match, 'path')
  let activePath = PATHS.ARTISTS
  _.each(_.values(PATHS), (val) => {
    if (val === path) { activePath = val }
  })

  return (
    <AdminBase breadcrumbs={[]}>
      <h1 className="my-3">Admin Dashboard</h1>

      <div className="mb-3">
        <Link to={'/admin/faq'}>Edit FAQ Pages</Link>
      </div>

      <ul className="nav nav-tabs">
        {_.map(_.keys(PATHS), (key) => (
          <li className="nav-item" key={key}>
            <Link className={classnames("nav-link", {
              active: activePath === PATHS[key]
            })} to={PATHS[key]}>{_.capitalize(_.toLower(key))}</Link>
          </li>
        ))}
      </ul>

      <Switch>
        <Route exact path="/admin/users" component={AdminUsersList} />
        <Route exact path="/admin/songs" component={AdminSongsList} />
        <Route exact path="/admin/comments" component={AdminCommentsList} />
        <Route exact path="/admin/explore" component={AdminExploreList} />
        <Route exact path="/admin/search" component={AdminSearchPage} />
        <Route component={AdminArtistsList} />
      </Switch>
    </AdminBase>
  )
}

export default connect(null, {
})(AdminDashboard)
