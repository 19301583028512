import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import Linkify from 'react-linkify'
import { connect } from 'react-redux'
import { Resources } from 'schema'
import { Link, useHistory } from 'react-router-dom'
import { selectModel } from 'selectors'
import { getArtist } from 'actions/api/artists'
import { getSong, orderStems, resetStemColors } from 'actions/api/songs'

import { CONVERSION_STATUS, LIST_TIMEOUT, STEM_NAME_WARNING_LENGTH } from 'static'

import { getSongTypeData } from 'utils'

import NavBar from 'components/main/NavBarV2'
import Breadcrumbs from 'components/main/Breadcrumbs'
import SortableList from 'components/main/SortableList'

let mounted, listUpdateTimeout, pendingTimeout, copiedTimeout
const PENDING_REFRESH_TIME = 5000

const SongPage = ({
  song={},
  album,
  match,
  getSong,
  getArtist,
  orderStems,
  resetStemColors,
}) => {
  const history = useHistory()
  const songId = _.get(match, 'params.id')

  const shareLinkEl = useRef(null)
  const [isCopiedSuccess, setIsCopiedSuccess] = useState(false)

  const breadcrumbs = [{ label: "Home", url: "/artist" }]
  if (!_.isEmpty(album)) { breadcrumbs.push({ label: album.name, url: album.url }) }
  breadcrumbs.push({ label: _.get(song, 'name'), active: true })

  const stems = _.get(song, 'stems', [])
  const isAlbumHidden = _.get(album, 'isHidden')
  const hasPending = _.reduce(stems, (memo, stem) => memo || _.get(stem, 'conversionStatus') === CONVERSION_STATUS.PENDING, false)
  const hasFailed = _.reduce(stems, (memo, stem) => memo || _.get(stem, 'conversionStatus') === CONVERSION_STATUS.FAILED, false)
  const hasLongStemName = _.reduce(stems, (memo, stem) => memo || _.get(stem, 'name.length') > STEM_NAME_WARNING_LENGTH, false)

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [stemList, setStemList] = useState([])

  const loadStemsFromRes = (res) => setStemList(_.get(res, 'payload.raw.song.stems', []))

  const reloadPendingStems = (res) => {
    const resStems = _.get(res, 'payload.raw.song.stems', [])
    const resPending = _.reduce(stems, (memo, stem) => memo || _.get(stem, 'conversionStatus') === CONVERSION_STATUS.PENDING, false)
    if (resPending) {
      pendingTimeout = setTimeout(() => {
        getSong(songId).then((res2) => {
          if (mounted && !res2.error) {
            loadStemsFromRes(res2)
            reloadPendingStems(res2)
          }
        })
      }, PENDING_REFRESH_TIME)
    }
  }

  const onStemOrder = () => {
    setIsLoading(true)
    clearTimeout(listUpdateTimeout)
    listUpdateTimeout = setTimeout(() => {
      orderStems(songId, {
        stems: _.map(stemList, (s, i) => ({ id: s.id, index: i })),
      }).then((res) => {
        if (mounted) {
          setIsLoading(false)
          setIsError(res.error)
          if (!res.error) { loadStemsFromRes(res) }
        }
      })
    }, LIST_TIMEOUT)
  }

  const onResetStemColors = () => {
    setIsLoading(true)
    resetStemColors(songId).then((res) => {
      if (mounted) {
        setIsLoading(false)
        setIsError(res.error)
        if (!res.error) { loadStemsFromRes(res) }
      }
    })
  }

  const copyShareLink = () => {
    shareLinkEl.current.select()
    document.execCommand('copy')
    setIsCopiedSuccess(true)
    copiedTimeout = setTimeout(() => setIsCopiedSuccess(false), 1000)
  }

  useEffect(() => {
    mounted = true
    setIsLoading(true)
    getArtist()
    getSong(songId).then((res) => {
      if (mounted) {
        setIsLoading(false)
        setIsError(res.error)
        if (!res.error) {
          loadStemsFromRes(res)
          reloadPendingStems(res)
        }
      }
    })

    return () => {
      mounted = false
      clearTimeout(pendingTimeout)
      clearTimeout(copiedTimeout)
    }
  }, [songId])

  const songTypeData = getSongTypeData(_.get(song, 'songType'))

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffffff" />

      <div className='container pb-5'>
        <Breadcrumbs crumbs={breadcrumbs} />

        <h1 className="mb-3">{_.get(song, 'name')}</h1>
        <Link className="d-block mb-2" to={`${song.url}/edit`}>Edit Song</Link>

        {!hasPending && (
          <div className="d-flex flex-row">
            <div className="flex-grow-0">
              <div className='btn-group mr-2'>
                <a target="_blank" className="btn btn-secondary" href={song.publicUrl}>View</a>
                <button className="btn btn-primary" onClick={copyShareLink}>
                  {isCopiedSuccess ? 'Copied!' : 'Copy'}
                </button>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="form-group">
                <input ref={shareLinkEl} type="text" className="share-url form-control" value={song.fullPublicUrl || ''} readOnly />
              </div>
            </div>
          </div>
        )}

        {!_.isEmpty(album) && (
          <div className="mb-2">
            {!_.isNil(song.prevSongUrl) && (
              <div>
                <strong>Previous Song:</strong>&nbsp;
                <Link to={song.prevSongUrl}>{song.prevSongName}</Link>
              </div>
            )}
            <div>
              <strong>Album:</strong>&nbsp;
              <Link to={album.url}>{album.name}</Link>
            </div>
            {!_.isNil(song.nextSongUrl) && (
              <div>
                <strong>Next Song:</strong>&nbsp;
                <Link to={song.nextSongUrl}>{song.nextSongName}</Link>
              </div>
            )}
          </div>
        )}

        {hasPending && (
          <div className='alert alert-warning mb-3'>
            <p>
              <i className='fa fa-clock-o mr-2'></i> Converting in progress...
            </p>
            <ul className="mb-0">
              <li>Congratulations, your upload is finished!</li>
              <li>Now we are just processing your files and generating waveforms.</li>
              <li>Feel free to leave or close this page and check back later!</li>
            </ul>
          </div>
        )}

        {hasFailed && (
          <div className='alert alert-danger d-flex flex-row align-items-center'>
            <div className='flex-grow-0 mr-3'>
              <i className="fa fa-exclamation-circle icon-large" aria-hidden="true"></i>
            </div>
            <div className='flex-grow-1'>
              Some stems have failed conversion.
              Contact us at <a target="_blank" href="mailto:splitterfm@gmail.com">splitterfm@gmail.com</a>
            </div>
          </div>
        )}

        {(song.isHidden || isAlbumHidden) && (
          <div className="alert alert-warning">
            <p>
              {isAlbumHidden ? (
                <strong>
                  <i className="fa fa-eye-slash mr-2"></i>
                  This song is currently on a hidden album.
                </strong>
              ) : (
                <strong>
                  <i className="fa fa-eye-slash mr-2"></i>
                  This song is currently hidden.
                </strong>
              )}
            </p>

            <p>
              This means it will not appear on your public artist page or on the splitter.fm "explore" page.  You can still share and access the player for hidden songs by sharing the url directly.
            </p>

            {isAlbumHidden ? (
              <p className="mb-0">
                To make this song public, go to the {album.name} album page and click the "EDIT" button,
                uncheck the "Hide Album" check box, and click update.
              </p>
            ) : (
              <p className="mb-0">
                To make this song public, click the "Edit Song" link above, uncheck the "Hide Song" box, and click update.
              </p>
            )}
          </div>
        )}

        {hasLongStemName && (
          <div className="alert alert-danger">
            <strong>Warning!</strong>  Your song has stems with names that are too long for our system to display properly.  You should make sure the names of your stems are 7 characters or less.
          </div>
        )}

        <div className='mb-3'>
          <div>
            <strong>Song Type:</strong> {songTypeData.name}
          </div>
          {!_.isNil(song.tempo) && (
            <div>
              <strong>Tempo:</strong> {song.tempo} bpm
            </div>
          )}
        </div>

        {!_.isNil(song.description) && (
          <div className='mb-3'>
            <h3>Description</h3>
            <Linkify className='prewrap break-word mb-2 d-block rounded p-3 bg-dark text-light'>
              {song.description}
            </Linkify>
          </div>
        )}

        <div className="mb-3">
          <SortableList
            title={songTypeData.stemTitlePlural}
            list={stemList}
            setList={setStemList}
            onChange={onStemOrder}
            isLoading={isLoading}
            emptyText='You have no stems.'
            renderItem={(li) => (
              <div key={_.get(li, 'id')} className={classnames("list-group-item", {
                "list-group-item-danger": _.get(li, 'conversionStatus') === CONVERSION_STATUS.FAILED,
                "list-group-item-info": _.get(li, 'conversionStatus') === CONVERSION_STATUS.PENDING,
              })}>
                <i className="fa fa-bars mr-2" aria-hidden="true"></i>
                <span className="stem-color mr-2" style={{ 'backgroundColor': li.bgColor }}></span>
                {li.name}
                {_.get(li, 'conversionStatus') === CONVERSION_STATUS.FAILED && (<span> - Conversion failed!</span>)}
                {_.get(li, 'conversionStatus') === CONVERSION_STATUS.PENDING && (<span> - Converting...</span>)}
                {li.isHidden && (<span className="badge badge-dark ml-2">hidden</span>)}
                {li.isMutedByDefault && (<span className="badge badge-warning ml-2">muted</span>)}
                <Link className="float-right" to={`${li.url}/edit`}>edit</Link>
              </div>
            )}
          />
        </div>

        {isError && (
          <div className="alert alert-danger">
            There was an error saving your changes.
          </div>
        )}

        <Link className="btn btn-primary mr-2 mb-3" to={`${song.url}/add_stems`}>Add Stems</Link>
        <button className="btn btn-secondary mb-3" onClick={onResetStemColors}>Reset Stem Colors</button>

      </div>

    </React.Fragment>
  )
}

export default connect((state, ownProps) => {
  const song = selectModel('songs', _.get(ownProps, 'match.params.id'), Resources.song, state)
  let album = null
  if (!_.isNil(_.get(song, 'albumId'))) {
    album = selectModel('albums', song.albumId, Resources.album, state)
  }
  return { song, album }
}, {
  getSong,
  getArtist,
  orderStems,
  resetStemColors,
})(SongPage)
