import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { Field, reduxForm } from 'redux-form'

import FormInput from 'components/main/FormInput'
import LoadingButton from 'components/main/LoadingButton'

let AlbumEditForm = ({ handleSubmit, submitting, buttonText }) => {
  const history = useHistory()
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="name">Album Name</label>
        <Field name="name" component={FormInput} type="text" classes="form-control" />
      </div>

      <div className="form-group form-check">
        <label>
          <Field name="is_hidden" component={FormInput} type="checkbox" classes="form-check-input" />
          Hide Album
          <small className="form-text">
            Hidden albums do not appear on your public artist page or the Splitter.FM "explore" page,
            but can still be viewed through the direct url
          </small>
        </label>
      </div>

      <div>
        <LoadingButton
          ownProps={{ className: 'btn btn-primary mr-2', type: "submit"}}
          isLoading={submitting}
        >
          {buttonText}
        </LoadingButton>
        <a className='btn btn-secondary' onClick={() => history.goBack()}>Cancel</a>
      </div>
    </form>
  )
}

const validate = (values) => {
  const errors = {}
  return errors
}

AlbumEditForm = reduxForm({
  form: 'albumEditForm',
  validate,
  enableReinitialize: true,
})(AlbumEditForm)

export default connect((state, ownProps) => ({
  initialValues: _.mapKeys(_.get(ownProps, 'album', {}), (v, k) => _.snakeCase(k)),
}))(AlbumEditForm)
