import React, { useState } from 'react'

import { connect } from 'react-redux'
import { sendEmailConfirmation } from 'actions/api/users'

import NavBar from 'components/main/NavBarV2'

const EmailConfirmationPage = ({ sendEmailConfirmation, isConfirmError }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmationEmailSent, setConfirmationEmailSent] = useState(false)
  const [isConfirmationEmailError, setConfirmationEmailError] = useState(false)

  const onSendEmailConfirmation = () => {
    setIsLoading(true)
    setConfirmationEmailError(false)
    sendEmailConfirmation().then((res) => {
      setIsLoading(false)
      if (res.error) {
        setConfirmationEmailError(true)
      } else {
        setConfirmationEmailSent(true)
      }
    })
  }

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffcf5a" />

      <div className="min-vh-100 width-100 background-yellow absolute-top-left">
        <div className='navbar-spacer'></div>
        <div className="container py-3">

          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8">
              <div className="mx-3">
                <h2 className="my-3">Please Confirm Your Email</h2>

                {isConfirmError && (
                  <div className="mb-3 alert alert-danger">
                    There was an error confirming your email.  Please try generating a new confirmation email and using the new link provided.  If this error persists, please contact <a href="mailto:splitterfm@gmail.com">splitterfm@gmail.com</a>
                  </div>
                )}

                <p>
                  Your email address is not yet confirmed.
                </p>

                <p>
                  Please check your inbox for an email containing the confirmation link.
                </p>

                <p>
                  If you do not see a confirmation email, you can use the button below to send a new confirmation email.
                </p>

                {!isConfirmationEmailSent && (
                  <button onClick={onSendEmailConfirmation} className='btn btn-light mb-3' disabled={isLoading}>
                    {isLoading ? (
                      <React.Fragment>
                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        Loading...
                      </React.Fragment>
                    ): (
                      <React.Fragment>Send Confirmation Email</React.Fragment>
                    )}
                  </button>
                )}

                {isConfirmationEmailSent && (
                  <div className='alert alert-success'>
                    Confirmation email sent!
                  </div>
                )}
                {isConfirmationEmailError && (
                  <div className='alert alert-danger'>
                    There was an error sending your confirmation email, please try refreshing the page.  If this error persists, please contact <a href="mailto:splitterfm@gmail.com">splitterfm@gmail.com</a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default connect(null, { sendEmailConfirmation })(EmailConfirmationPage)
