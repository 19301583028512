import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import moment from 'moment'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { adminGetUser, adminGetUserComments } from 'actions/api/admin/users'

import AdminBase from 'components/main/admin/AdminBase'
import PaginatedList from 'components/main/PaginatedList'

import { secondsToMinutes } from 'utils/time'

let mounted

const AdminUserPage = ({
  match,
  adminGetUser,
  adminGetUserComments,
}) => {
  const userId = _.get(match, 'params.id')
  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const getUserComments = (page) => adminGetUserComments(userId, page)

  useEffect(() => {
    mounted = true
    setIsLoading(true)
    adminGetUser(userId).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (!res.error) {
          setUser(_.get(res, 'payload.user'))
        }
      }
    })
    return () => {
      mounted = false
    }
  }, [])

  return (
    <AdminBase breadcrumbs={[
      { label: "Admin", url: '/admin' },
      { label: "Users", url: '/admin/users' },
      { label: user.username, active: true },
    ]}>
      <h1>{user.username} ({user.id})</h1>

      {_.get(user, 'isEmailConfirmed') ? (
        <strong className="d-block mb-2 text-success">EMAIL CONFIRMED</strong>
      ) : (
        <div className="alert alert-danger text-center">Email is NOT confirmed</div>
      )}

      <div className="mb-3">
        <div className="row">
          <div className="col-3 col-lg-2">Email:</div>
          <div className="col-9 col-lg-10">
            {_.get(user, 'email')}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">Artist:</div>
          <div className="col-9 col-lg-10">
            <Link to={`/admin${_.get(user, 'artist.url')}`}>{_.get(user, 'artist.artistName')}</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">Hubspot Contact ID:</div>
          <div className="col-9 col-lg-10">
            {_.get(user, 'hubspotContactId')}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">Created:</div>
          <div className="col-9 col-lg-10">
            {moment(user.createdAt).fromNow()}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">Last Edited:</div>
          <div className="col-9 col-lg-10">
            {moment(user.updatedAt).fromNow()}
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-lg-2">Hubspot Updated:</div>
          <div className="col-9 col-lg-10">
            {moment(_.get(user, 'hubspotUpdatedAt')).fromNow()}
          </div>
        </div>
      </div>

      <h3>Comments</h3>
      <PaginatedList
        getListRequest={getUserComments}
        listName='comments'
        urlPrefix='/admin'
        columns={[
          { name: 'ID', key: 'id', link: 'url' },
          { name: 'Song', key: 'song.name', link: 'song.url' },
          { name: 'Stem', key: 'stem.name' },
          { name: 'Time', key: 'stemTime', getValue: (s) => secondsToMinutes(s.stemTime) },
          { name: 'Created', key: 'createdAt', getValue: (i) => moment(i.createdAt).fromNow() },
        ]}
      />

    </AdminBase>
  )
}

export default connect(null, {
  adminGetUser, adminGetUserComments
})(AdminUserPage)
