import React, { useEffect } from 'react'
import classnames from 'classnames'

export default ({ accordionKey, accordionData, largeHeader }) => {

  useEffect(() => {
    $(`#${accordionKey}`).on('show.bs.collapse', function (e) {
      $(e.target).parent().find('.sfm-accordion-arrow').addClass('flipped')
    })
    $(`#${accordionKey}`).on('hide.bs.collapse', function (e) {
      $(e.target).parent().find('.sfm-accordion-arrow').removeClass('flipped')
    })
    return () => {
    }
  }, [])

  return (
    <div className="accordion sfm-accordion" id={accordionKey}>
      {_.map(accordionData, (data) => (
        <div className="sfm-accordion-entry" key={data.key}>
          <div className="sfm-accordion-header" id={`${data.key}-heading`}>
            <a className={classnames("sfm-accordion-link d-flex flex-row", {
              'large-font': largeHeader
            })}
              type="button"
              data-toggle="collapse"
              data-target={`#${data.key}`}
              aria-expanded="false"
              aria-controls={data.key}
            >
              <div className="flex-grow-1">
                {data.header}
              </div>
              <div className="flex-grow-0 text-right">
                <i className='fa fa-chevron-down sfm-accordion-arrow'></i>
              </div>
            </a>
          </div>

          <div className="collapse"
            id={data.key}
            aria-labelledby={`${data.key}-heading`}
            data-parent={`#${accordionKey}`}
          >
            <div className="sfm-accordion-body">
              <div dangerouslySetInnerHTML={{ __html: data.body }}></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
