import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Resources } from 'schema'
import { Link, useHistory } from 'react-router-dom'
import { SubmissionError } from 'redux-form'
import { processApiErrors } from 'utils'
import { selectCurrentUser, selectModel } from 'selectors'
import { getArtist } from 'actions/api/artists'
import { createSong } from 'actions/api/songs'

import NavBar from 'components/main/NavBarV2'
import Breadcrumbs from 'components/main/Breadcrumbs'
import SongEditForm from 'components/main/songs/SongEditForm'

const NewSongPage = ({
  artist,
  album,
  createSong,
  getArtist,
}) => {
  const history = useHistory()
  const breadcrumbs = [{ label: "Home", url: "/artist" }]
  if (!_.isNil(album)) { breadcrumbs.push({ label: album.name, url: album.url }) }
  breadcrumbs.push({ label: "New Song", active: true })
  const albumId = _.get(album, 'id')

  const onCreateSong = (values) => createSong({ song: { ...values, album_id: albumId } }).then((res) => {
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      const songUrl = _.get(res, 'payload.raw.song.url')
      history.push(`${songUrl}/add_stems`)
    }
  })

  useEffect(() => {
    getArtist()
  }, [])

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffffff" />

      <div className='container pb-5'>
        <Breadcrumbs crumbs={breadcrumbs} />
        <h1 className="mb-3">New Song</h1>

        {!_.isNil(albumId) && (
          <div className="form-group">
            <label htmlFor="album_name">Album Name</label>
            <input name="album_name"
              type="text"
              value={_.get(album, 'name')}
              className="form-control"
              disabled
            />
          </div>
        )}
        <SongEditForm onSubmit={onCreateSong} artist={artist} album={album} buttonText="Create" isCreate />
      </div>

    </React.Fragment>
  )
}

export default connect((state, ownProps) => {
  const user = selectCurrentUser(state)
  const albumId = _.get(ownProps, 'match.params.albumId')
  let album = null
  if (!_.isNil(albumId)) { album = selectModel('albums', albumId, Resources.album, state) }
  return {
    artist: selectModel('artists', _.get(user, 'artistId'), Resources.artist, state) || {},
    album,
  }
}, {
  createSong,
  getArtist,
})(NewSongPage)
