import _ from 'lodash'
import 'whatwg-fetch'
import { setUrlParams } from 'utils/url'
import { v4 as uuidv4 } from 'uuid'

export const EVENT_TYPES = {
  // LOADING EVENTS
  BLANK_EVENT: 0,
  PAGE_LOADED: 1,
  STEMS_LOADED: 2,
  COMMENTS_LOADED: 3,

  // PLAYER EVENTS
  PLAY_ACTION: 10,
  PAUSE_ACTION: 11,
  STEM_MUTE_CLICK: 12,
  STEM_SOLO_CLICK: 13,
  RESET_STEMS_CLICK: 14,
  SHARE_LINK_CLICK: 15,
  SEEKBAR_CLICK: 16,

  // OVERLAY EVENTS
  INFO_OVERLAY_TRIGGER: 20,
  SETTINGS_OVERLAY_TRIGGER: 21,
  SONG_INFO_OVERLAY: 22,
  STEM_INFO_OVERLAY: 23,

  // COMMENT EVENTS
  COMMENTS_PANEL_BUTTON: 30,
  COMMENT_BUBBLE_CLICK: 31,
  REPLY_COMMENT_CLICK: 32,
  LIKE_COMMENT_CLICK: 33,
  ADD_COMMENT_CLICK: 34,
  COMMENT_SUBMIT: 35,
  COMMENT_STEM_TIME_CLICK: 36,
  COMMENT_STEM_SOLO_CLICK: 37,
  COMMENT_BUBBLE_HOVER: 38,
}

export const EVENT_PARAMS = {
  pageViewUuid: "e177",
  eventType: "a19f",
  //pageType: "ac8b",
  songId: "cf27",
  artistId: "efe4",
  userId: "eabf",
}

export const GOOGLE_TAG_EVENTS = {
  // LOADING EVENTS
  BLANK_EVENT: 'blankEvent',
  PAGE_LOADED: 'pageLoaded',
  STEMS_LOADED: 'stemsLoaded',
  COMMENTS_LOADED: 'commentsLoaded',

  // PLAYER EVENTS
  PLAY_ACTION: 'playClick',
  PAUSE_ACTION: 'pauseClick',
  STEM_MUTE_CLICK: 'stemMuteClick',
  STEM_SOLO_CLICK: 'stemSoloClick',
  RESET_STEMS_CLICK: 'resetStemsClick',
  SHARE_LINK_CLICK: 'shareLinkClick',
  SEEKBAR_CLICK: 'seekbarClick',

  // OVERLAY EVENTS
  INFO_OVERLAY_TRIGGER: 'infoOverlay',
  SETTINGS_OVERLAY_TRIGGER: 'settingsOverlay',
  SONG_INFO_OVERLAY: 'songInfoOverlay',
  STEM_INFO_OVERLAY: 'stemInfoOverlay',

  // COMMENT EVENTS
  COMMENTS_PANEL_BUTTON: 'commentsPanelOpen',
  COMMENT_BUBBLE_CLICK: 'commentBubbleClick',
  REPLY_COMMENT_CLICK: 'commentReplyClick',
  LIKE_COMMENT_CLICK: 'commentLikeClick',
  ADD_COMMENT_CLICK: 'commentAddClick',
  COMMENT_SUBMIT: 'commentSubmitClick',
  COMMENT_STEM_TIME_CLICK: 'commentStemTimeClick',
  COMMENT_STEM_SOLO_CLICK: 'commentStemSoloClick',
  COMMENT_BUBBLE_HOVER: 'commentBubbleHover',
}


export function analytics(params={}) {
  const baseUrl = _.get(window, 'jsonVars.sfma')
  if (_.isNil(baseUrl)) { return null }
  if (_.indexOf(_.values(EVENT_TYPES), _.get(params, 'eventType')) < 0) { return null }
  if (_.isNil(window.sfmaUuid)) { window.sfmaUuid = uuidv4() }

  const acceptedParams = {
    ..._.pick(params, _.keys(EVENT_PARAMS)),
    pageViewUuid: window.sfmaUuid,
  }

  const formattedParams = _.mapKeys(acceptedParams, (value, key) => EVENT_PARAMS[key])

  const formattedUrl = setUrlParams(baseUrl, formattedParams)
  return fetch(formattedUrl)
}

export function googleTagPushEvent(eventType) {
  googleTagPushDataLayer({ 'event': eventType })
}

export function googleTagPushDataLayer(params={}) {
  try {
    window.dataLayer.push(params)
  } catch (e) {
    // no-op - fail silently
  }
}

export function fullStorySetUserVars(user) {
  if (_.isNil(user) || _.isEmpty(user)) { return null }

  try {
    window.FS.setUserVars({
      uid: _.get(user, 'id'),
      email: _.get(user, 'email'),
      displayName: _.get(user, 'username'),
    })
  } catch (e) {
    // no-op
  }
}

export function fullStoryEvent(eventName, eventParams={}) {
  try {
    window.FS.event(eventName, eventParams)
  } catch (e) {
    // no-op
  }
}

