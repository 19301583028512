import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { Field, reduxForm } from 'redux-form'

import FormInput from 'components/main/FormInput'
import LoadingButton from 'components/main/LoadingButton'

let AdminFaqSectionEditForm = ({ handleSubmit, submitting, buttonText, isSuccess, successText }) => {
  const history = useHistory()
  return (
    <form onSubmit={handleSubmit}>

      <div className="form-group">
        <label htmlFor="title">Faq Section Title</label>
        <Field name="title" component={FormInput} type="text" classes="form-control" />
      </div>

      <div className="form-group">
        <label htmlFor="key">Faq Section Key</label>
        <Field name="key" component={FormInput} type="text" classes="form-control" />
        <small id="keyDesc" className="form-text">Must be unique</small>
      </div>

      <div>
        <LoadingButton
          ownProps={{ className: 'btn btn-primary mr-2', type: "submit"}}
          isLoading={submitting}
          isSuccess={isSuccess}
          successText={successText}
        >
          {buttonText}
        </LoadingButton>
        <a className='btn btn-secondary' onClick={() => history.goBack()}>Cancel</a>
      </div>

    </form>
  )
}

const validate = (values) => {
  const errors = {}
  return errors
}

AdminFaqSectionEditForm = reduxForm({
  form: 'faqSectionEditForm',
  validate,
  enableReinitialize: true,
})(AdminFaqSectionEditForm)

export default connect((state, ownProps) => ({
  initialValues: _.mapKeys(_.get(ownProps, 'faqSection', {}), (v, k) => _.snakeCase(k)),
}))(AdminFaqSectionEditForm)
