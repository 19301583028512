import React from 'react'
import _ from 'lodash'

import { Field, reduxForm } from 'redux-form'
import FormInput from 'components/main/FormInput'
import LoadingButton from 'components/main/LoadingButton'

import { EMAIL_REGEX, USERNAME_REGEX } from 'utils'

const NewArtistForm = ({ handleSubmit, submitting }) => (
  <form onSubmit={handleSubmit}>
    <div className="form-group">
      <label htmlFor="artist_name">Artist Name</label>
      <Field name="artist_name" component={FormInput} type="text" classes="form-control" />
    </div>

    <div className="form-group">
      <label htmlFor="artist_url">Artist URL</label>
      <div className="input-group">
        <div className="input-group-prepend">
          <div className="input-group-text">{window.jsonVars.baseUrl}/</div>
        </div>
        <Field name="artist_url" component={FormInput} type="text" classes="form-control" />
      </div>
      <small id="artistUrlDesc" className="form-text">This is your unique splitter.fm URL</small>
    </div>

    <div>
      <LoadingButton ownProps={{ className: 'btn btn-primary', type: "submit"}} isLoading={submitting}>
        Sign Up
      </LoadingButton>
    </div>
  </form>
)

const validate = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = "Email is required"
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = "Email is invalid"
  }

  if (!values.username) {
    errors.username = "Username is required"
  } else if (!USERNAME_REGEX.test(values.username)) {
    errors.username = "Username is invalid"
  }

  if (!values.password) {
    errors.password = "Password is required"
  }
  return errors
}

export default reduxForm({
  form: 'newArtistForm',
  validate,
})(NewArtistForm)
