import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { connect } from 'react-redux'
import { adminGetArtistIndex } from 'actions/api/admin/artists'

import PaginatedList from 'components/main/PaginatedList'

const AdminArtistsList = ({ location, adminGetArtistIndex }) => {
  return (
    <div className="mt-3">
      <PaginatedList
        getListRequest={adminGetArtistIndex}
        listName='artists'
        urlPrefix='/admin'
        columns={[
          { name: 'ID', key: 'id' },
          { name: 'Name', key: 'artistName', link: 'url' },
          { name: 'User Email', key: 'user.email' },
          { name: 'Username', key: 'user.username', link: 'user.url' },
          { name: 'Created', key: 'createdAt', getValue: (i) => moment(i.createdAt).fromNow() },
        ]}
      />
    </div>
  )
}

export default connect(null, {
  adminGetArtistIndex,
})(AdminArtistsList)
