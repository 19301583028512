import React from 'react'

import AdminBase from 'components/main/admin/AdminBase'

const AdminDashboard = ({ }) => (
  <AdminBase breadcrumbs={[{
    label: "Admin", active: true,
  }]}>
    <h1>TEMP</h1>
  </AdminBase>
)

export default AdminDashboard
