import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { SubmissionError } from 'redux-form'
import { processApiErrors } from 'utils'
import {
  adminGetFaqSection,
  adminUpdateFaqSection,
  adminDeleteFaqSection,
  adminOrderFaqEntries
} from 'actions/api/admin/faq'

import { LIST_TIMEOUT } from 'static'

import AdminBase from 'components/main/admin/AdminBase'
import AdminFaqSectionEditForm from 'components/main/admin/faq/AdminFaqSectionEditForm'
import ConfirmationButton from 'components/main/ConfirmationButton'
import SortableList from 'components/main/SortableList'

let mounted, successTimeout, listUpdateTimeout
const SUCCESS_TIMEOUT = 4000

const AdminEditFaqSectionPage = ({
  match,
  adminGetFaqSection,
  adminUpdateFaqSection,
  adminDeleteFaqSection,
  adminOrderFaqEntries,
}) => {
  const history = useHistory()
  const faqSectionId = _.get(match, 'params.id')

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [faqSection, setFaqSection] = useState({})
  const [faqEntries, setFaqEntries] = useState([])

  const processFaqSectionResponse = (res) => {
    setFaqSection(_.get(res, 'payload.faqSection'))
    setFaqEntries(_.get(res, 'payload.faqSection.faqEntries'), [])
  }

  const onUpdateFaqSection = (values) => adminUpdateFaqSection({ faq_section: values }).then((res) => {
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      if (mounted) {
        setIsSuccess(true)
        processFaqSectionResponse(res)
        setTimeout(() => setIsSuccess(false), SUCCESS_TIMEOUT)
      }
    }
  })

  const onOrderEntries = () => {
    setIsLoading(true)
    clearTimeout(listUpdateTimeout)
    listUpdateTimeout = setTimeout(() => {
      adminOrderFaqEntries(faqSectionId, {
        faq_entries: [
          ..._.map(faqEntries, (e, i) => ({ id: e.id, index: i })),
        ],
      }).then((res) => {
        if (mounted) {
          setIsLoading(false)
          if (!res.error) { processFaqSectionResponse(res) }
        }
      })
    }, LIST_TIMEOUT)
  }

  const onDeleteFaqSection = () => {
    setIsLoading(true)
    adminDeleteFaqSection(faqSectionId).then((res) => {
      if (mounted) { setIsLoading(false) }
      if (!res.error) {
        history.push('/admin/faq')
      }
    })
  }

  useEffect(() => {
    mounted = true
    setIsLoading(true)
    adminGetFaqSection(faqSectionId).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (!res.error) {
          processFaqSectionResponse(res)
        }
      }
    })

    return () => {
      mounted = false
      clearTimeout(successTimeout)
    }
  }, [])

  return (
    <AdminBase breadcrumbs={[
      { label: "Admin", url: '/admin' },
      { label: "FAQ", url: '/admin/faq' },
      { label: faqSection.title, active: true },
    ]}>
      <h1 className="mb-3">Create FAQ Section</h1>

      <div className="mb-2">
        <AdminFaqSectionEditForm
          onSubmit={onUpdateFaqSection}
          faqSection={faqSection}
          buttonText="Update"
          isSuccess={isSuccess}
          successText="Updated!"
        />
      </div>

      <div className="mb-5">
        <ConfirmationButton
          ownProps={{ className: 'btn btn-danger' }}
          confirmText='Really Delete FAQ Section?'
          isLoading={isLoading}
          onSuccess={onDeleteFaqSection}
        >Delete FAQ Section</ConfirmationButton>
      </div>

      <div className="mb-2">
        <SortableList
          title='FAQ Entries'
          list={faqEntries}
          setList={setFaqEntries}
          onChange={onOrderEntries}
          isLoading={isLoading}
          emptyText='This section has no entries.'
          renderItem={(li) => (
            <div key={li.id} className="list-group-item">
              <i className="fa fa-bars mr-2" aria-hidden="true"></i> {li.question}
              <Link className="float-right" to={`${li.url}/edit`}>edit</Link>
            </div>
          )}
        />
      </div>

      <div className="mb-2">
        <Link className="btn btn-primary" to={`${faqSection.url}/faq_entries/new`}>Add FAQ Entry</Link>
      </div>


    </AdminBase>
  )
}

export default connect(null, {
  adminGetFaqSection,
  adminUpdateFaqSection,
  adminDeleteFaqSection,
  adminOrderFaqEntries,
})(AdminEditFaqSectionPage)
