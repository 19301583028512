import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'
import classnames from 'classnames'

import NavBar from 'components/main/NavBarV2'
import Pagination from 'components/main/Pagination'

import { connect } from 'react-redux'
import { search } from 'actions/api/explore'

import { parseUrlParams } from 'utils'

const VIEW_TYPES = {
  ARTISTS: 'artists',
  ALBUMS: 'albums',
  SONGS: 'songs',
}

function assertViewType(viewType) {
  if (_.indexOf(_.values(VIEW_TYPES), viewType) >= 0) {
    return viewType
  } else {
    return VIEW_TYPES.ARTISTS
  }
}

const SearchPage = ({ search }) => {
  let history = useHistory()
  let location = useLocation()

  const urlData = parseUrlParams(location.search)
  const urlParams = urlData.params || {}
  const urlPage = parseInt(urlParams.page, 10)
  const defaultPage = _.isNaN(urlPage) ? 0 : urlPage
  const defaultQuery = urlParams.q || ''
  const defaultViewType = assertViewType(urlParams.type)

  let [viewType, setViewType] = useState(defaultViewType)
  let [pageNum, setPageNum] = useState(defaultPage)
  let [query, setQuery] = useState(defaultQuery)
  let [isError, setIsError] = useState(false)
  let [isLoading, setIsLoading] = useState(false)
  let [results, setResults] = useState({})
  let [isShowPatreonModal, setIsShowPatreonModal] = useState(false)
  let [patreonArtistName, setPatreonArtistName] = useState('')
  let [patreonUrl, setPatreonUrl] = useState('')

  const resultsForViewType = (vt) => _.get(results, `${vt}.entities`, [])
  const numPagesForViewType = (vt) => _.get(results, `${vt}.numPages`, 0)

  const searchRequest = (page) => {
    if (_.isNil(query)) {
      setResults({})
      setNumPages(0)
      setIsError(false)
      setIsLoading(false)
    } else {
      setIsLoading(true)
      search(query, page).then((res) => {
        setIsLoading(false)
        if (res.error) {
          setIsError(true)
        } else {
          setIsError(false)
          setResults(res.payload.results)
          setPageNum(res.payload.page)
        }
      })
    }
  }

  const onSearch = (e) => {
    e.preventDefault()
    searchRequest(0)
  }

  const onChangePageNum = (newPage) => {
    if (newPage < 0 || newPage >= numPagesForViewType(viewType)) { return null }
    searchRequest(newPage)
  }

  const onChangeViewType = (vt) => {
    setViewType(vt)
    const vtNumPages = _.get(results, `${vt}.numPages`, 0)
    if (pageNum >= vtNumPages) {
      searchRequest([vtNumPages - 1, 0].max)
    }
    history.push(`/search?q=${query}&type=${vt}&page=${pageNum}`)
  }

  const onPatreonClick = (r) => {
    setPatreonArtistName(r.artistName)
    setPatreonUrl(r.patreonUrl)
    setIsShowPatreonModal(true)
  }

  useEffect(() => {
    searchRequest(pageNum)
  }, [])

  useEffect(() => {
    let newViewType = viewType
    if (numPagesForViewType(viewType) <= 0) {
      let isNewViewType = false
      _.forEach(_.values(VIEW_TYPES), (vt) => {
        if (!isNewViewType && numPagesForViewType(vt) > 0) {
          newViewType = vt
          isNewViewType = true
        }
      })

      if (isNewViewType) {
        setViewType(newViewType)
      }
    }

    history.replace(`/search?q=${query}&type=${newViewType}&page=${pageNum}`)
  }, [results])

  return (
    <>
      <NavBar backgroundColor="#ffcf5a" />

      <div className="min-vh-100 width-100 background-yellow absolute-top-left">
        <div className='navbar-spacer'></div>
        <div className="container py-3 text-center">
          <h1 className="mb-4">Search</h1>

          <form className='mw-480 mb-3 mx-auto px-4' onSubmit={onSearch}>
            <div className="input-group px-2">
              <div className='input-group-prepend' style={{ zIndex: 1 }}>
                <button className="btn border-black text-black bg-transparent" onClick={onSearch}>
                  <i className='fa fa-search'></i>
                </button>
              </div>
              <input
                type="text"
                className="form-control border-black bg-transparent text-black"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </form>

          <div className='rounded-lg py-3 px-4'>
            <div className='row justify-content-center'>
              <div className='col-12 col-md-10 col-lg-8'>

                <ul className="nav nav-pills nav-fill mb-3">
                  {_.map(_.values(VIEW_TYPES), vt => {
                    if (_.get(results, `${vt}.numPages`, 0) === 0) { return null }
                    return (
                      <li className="nav-item" key={vt}>
                        <a
                          className={classnames('nav-link search-nav-pill', {
                            'active': vt === viewType,
                          })}
                          onClick={(e) => {
                            e.preventDefault()
                            onChangeViewType(vt)
                          }}
                        >{_.capitalize(vt)}</a>
                      </li>
                    )
                  })}
                </ul>

                {_.map(resultsForViewType(viewType), (r) => (
                  r.isPatreonOnly ? (
                    <a className="d-flex flex-row align-items-center mb-3 text-black"
                      key={r.id}
                      href="#"
                      onClick={() => onPatreonClick(r)}
                    >
                      <img className='flex-grow-0 mr-2 search-image rounded' src={r.imageUrl} />
                      <i className="fa fa-unlock-alt mr-2" aria-hidden="true"></i>
                      <div className='flex-grow-1 text-black large-text text-left'>{r.name}</div>
                    </a>
                  ) : (
                    <a className="d-flex flex-row align-items-center mb-3 text-black"
                      key={r.id}
                      href={r.url}
                      target="_blank"
                    >
                      <img className='flex-grow-0 mr-2 search-image rounded' src={r.imageUrl} />
                      <div className='flex-grow-1 text-black large-text text-left'>{r.name}</div>
                    </a>
                  )
                ))}

                {resultsForViewType(viewType).length <= 0 && (
                  <div className="text-center alert alert-warning">
                    No results were found!  Try a different search query.
                  </div>
                )}

                <Pagination
                  className='pagination-dark'
                  currentPageNum={pageNum}
                  numPages={numPagesForViewType(viewType)}
                  onPageClick={onChangePageNum}
                />

              </div>
            </div>

            {isError && (
              <div className="text-center alert alert-danger">
                An error has occurred, please try reloading the page.  If this error persists, please contact <a href="mailto:splitterfm@gmail.com">splitterfm@gmail.com</a>
              </div>
            )}
            {isLoading && (
              <div className="text-center">
                <div className="spinner-grow text-black mb-2" role="status"></div>
                <h3>Loading...</h3>
              </div>
            )}
          </div>

        </div>
      </div>

      {isShowPatreonModal && (
        <>
          <div className="modal fade show d-block"
               id="patreonModal"
               tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="patreonModalLabel">{patreonArtistName}</h5>
                  <button type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => setIsShowPatreonModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  In order to access this song, you'll need to sign up for {patreonArtistName}&#39;s Patreon.  Click the button below to visit their Patreon page!
                </div>
                <div className="modal-footer">
                  <button type="button"
                    className="btn btn-secondary"
                    onClick={() => setIsShowPatreonModal(false)}
                  >Close</button>
                  <a className="btn btn-primary" href={patreonUrl} target="_blank">Visit Patreon</a>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" onClick={() => setIsShowPatreonModal(false)}></div>
        </>
      )}
    </>
  )
}

export default connect(null, { search })(SearchPage)
