import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { connect } from 'react-redux'
import { adminGetSongIndex } from 'actions/api/admin/songs'

import PaginatedList from 'components/main/PaginatedList'

const AdminSongsList = ({ location, adminGetSongIndex }) => {
  return (
    <div className="mt-3">
      <PaginatedList
        getListRequest={adminGetSongIndex}
        listName='songs'
        urlPrefix='/admin'
        columns={[
          { name: 'ID', key: 'id' },
          { name: 'Name', key: 'name', link: 'url' },
          { name: 'Artist', key: 'artist.artistName', link: 'artist.url' },
          { name: 'Edited', key: 'updatedAt', getValue: (i) => moment(i.updatedAt).fromNow() },
          { name: 'Created', key: 'createdAt', getValue: (i) => moment(i.createdAt).fromNow() },
        ]}
      />
    </div>
  )
}

export default connect(null, {
  adminGetSongIndex,
})(AdminSongsList)
