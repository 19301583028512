import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Resources } from 'schema'
import { Link, useHistory } from 'react-router-dom'
import { SubmissionError } from 'redux-form'
import { processApiErrors } from 'utils'
import { selectCurrentUser, selectModel } from 'selectors'
import { getArtist } from 'actions/api/artists'
import { createAlbum } from 'actions/api/albums'

import NavBar from 'components/main/NavBarV2'
import Breadcrumbs from 'components/main/Breadcrumbs'
import AlbumEditForm from 'components/main/albums/AlbumEditForm'

const NewAlbumPage = ({
  createAlbum,
}) => {
  const history = useHistory()

  const onCreateAlbum = (values) => createAlbum({ album: values }).then((res) => {
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      const albumUrl = _.get(res, 'payload.raw.album.url')
      history.push(albumUrl)
    }
  })

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffffff" />

      <div className='container pb-5'>
        <Breadcrumbs crumbs={[
          { label: "Home", url: "/artist" },
          { label: "New Album", active: true },
        ]} />
        <h1 className="mb-3">New Album</h1>
        <AlbumEditForm onSubmit={onCreateAlbum} buttonText="Create" />
      </div>

    </React.Fragment>
  )
}

export default connect((state, ownProps) => {
  const user = selectCurrentUser(state)
  return {
    artist: selectModel('artists', _.get(user, 'artistId'), Resources.artist, state) || {},
  }
}, {
  createAlbum,
  getArtist,
})(NewAlbumPage)
