import React from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { SubmissionError } from 'redux-form'

import { registerArtist } from 'actions/api/users'
import { createArtist } from 'actions/api/artists'
import { selectCurrentUser } from 'selectors'

import NavBar from 'components/main/NavBarV2'
import NewArtistForm from 'components/main/register/NewArtistForm'
import RegisterArtistForm from 'components/main/register/RegisterArtistForm'

import { processApiErrors } from 'utils'

const RegisterArtistPage = ({ registerArtist, createArtist, currentUser }) => {
  const user = currentUser || {}
  const isCurrentUser = !_.isEmpty(user)
  const history = useHistory()

  if (!_.isNil(user.artistId)) {
    history.push("/artist")
  }

  const submitRegister = (values) => registerArtist({ ...values }).then((res) => {
    if (res.error) {
      let userErrors = processApiErrors(res.payload.errors)
      // SPECIAL CASE
      if (userErrors.email === "has already been taken") {
        userErrors.email = (
          <span>
            This email already has a user account associated with it.  Please <a href="/login" target="_blank">click here</a> to log in with your existing account and then reload this page to complete your artist registration.
          </span>
        )
      }
      throw new SubmissionError({ user: userErrors })
    } else {
      history.push("/artist/edit")
    }
  })

  const submitNewArtist = (values) => createArtist({ artist: { ...values } }).then((res) => {
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      history.push("/artist/edit")
    }
  })

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffffff" />

      <div className="container pb-5">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-8 col-lg-6">

            <div className="callout secondary">

              <h1 className="mb-4">Artist Sign Up</h1>

              {isCurrentUser ? (
                <React.Fragment>
                  <div htmlFor="email" className="form-group">
                    <label>Email</label>
                    <input name="email" type="email" value={user.email} className="form-control" disabled />
                  </div>
                  <NewArtistForm onSubmit={submitNewArtist} />
                </React.Fragment>
              ) : (
                <RegisterArtistForm onSubmit={submitRegister} />
              )}

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default connect((state) => ({
  currentUser: selectCurrentUser(state),
}), {
  registerArtist, createArtist
})(RegisterArtistPage)
