import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { connect } from 'react-redux'
import { adminGetUsersIndex } from 'actions/api/admin/users'

import PaginatedList from 'components/main/PaginatedList'

const AdminUsersList = ({ adminGetUsersIndex }) => {
  return (
    <div className="mt-3">
      <PaginatedList
        getListRequest={adminGetUsersIndex}
        listName='users'
        urlPrefix='/admin'
        columns={[
          { name: 'ID', key: 'id' },
          { name: 'Username', key: 'username', link: 'url' },
          { name: 'Email', key: 'email' },
          { name: 'Artist', key: 'artist.artistName', link: 'artist.url' },
          { name: 'Created', key: 'createdAt', getValue: (i) => moment(i.createdAt).fromNow() },
        ]}
      />
    </div>
  )
}

export default connect(null, {
  adminGetUsersIndex,
})(AdminUsersList)
