import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import { Link } from 'react-router-dom'

export default ({ crumbs=[] }) => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      {_.map(crumbs, (c, i) => (
        <li key={`${c.label}-${i}`} className={classnames('breadcrumb-item', {
          active: c.active,
        })} aria-current={c.active ? 'page' : undefined}>
          {_.isNil(c.url) ? c.label : (
            <Link to={c.url}>{c.label}</Link>
          )}
        </li>
      ))}
    </ol>
  </nav>
)
