import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { SubmissionError } from 'redux-form'
import { processApiErrors } from 'utils'
import {
  adminGetFaqEntry,
  adminUpdateFaqEntry,
  adminDeleteFaqEntry
} from 'actions/api/admin/faq'

import AdminBase from 'components/main/admin/AdminBase'
import AdminFaqEntryEditForm from 'components/main/admin/faq/AdminFaqEntryEditForm'
import ConfirmationButton from 'components/main/ConfirmationButton'

let mounted

const AdminEditFaqEntryPage = ({
  match,
  adminGetFaqEntry,
  adminUpdateFaqEntry,
  adminDeleteFaqEntry,
}) => {
  const history = useHistory()
  const faqEntryId = _.get(match, 'params.id')

  const [isLoading, setIsLoading] = useState(false)
  const [faqEntry, setFaqEntry] = useState({})

  const onUpdateFaqEntry = (values) => adminUpdateFaqEntry(faqEntryId, { faq_entry: values }).then((res) => {
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      history.push(`${_.get(faqEntry, 'faqSection.url')}/edit`)
    }
  })

  const onDeleteFaqEntry = () => {
    setIsLoading(true)
    adminDeleteFaqEntry(faqEntryId).then((res) => {
      if (mounted) { setIsLoading(false) }
      if (!res.error) {
        history.push(`${_.get(faqEntry, 'faqSection.url')}/edit`)
      }
    })
  }

  useEffect(() => {
    mounted = true
    setIsLoading(true)
    adminGetFaqEntry(faqEntryId).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (!res.error) {
          setFaqEntry(_.get(res, 'payload.faqEntry'))
        }
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  return (
    <AdminBase breadcrumbs={[
      { label: "Admin", url: '/admin' },
      { label: "FAQ", url: '/admin/faq' },
      { label: _.get(faqEntry, 'faqSection.title'), url: `${_.get(faqEntry, 'faqSection.url')}/edit` },
      { label: faqEntry.question, active: true },
    ]}>
      <h1 className="mb-3">Edit FAQ Entry</h1>

      <div className="mb-2">
        <AdminFaqEntryEditForm
          onSubmit={onUpdateFaqEntry}
          faqEntry={faqEntry}
          buttonText="Update"
        />
      </div>

      <div className="mb-5">
        <ConfirmationButton
          ownProps={{ className: 'btn btn-danger' }}
          confirmText='Really Delete FAQ Entry?'
          isLoading={isLoading}
          onSuccess={onDeleteFaqEntry}
        >Delete FAQ Entry</ConfirmationButton>
      </div>

      <div className="mb-2">
        <Link className="btn btn-primary" to={`${faqEntry.url}/faq_entries/new`}>Add FAQ Entry</Link>
      </div>


    </AdminBase>
  )
}

export default connect(null, {
  adminGetFaqEntry,
  adminUpdateFaqEntry,
  adminDeleteFaqEntry,
})(AdminEditFaqEntryPage)
