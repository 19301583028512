import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import { Link, useLocation, useHistory } from 'react-router-dom'

import Pagination from 'components/main/Pagination'

import { parseUrlParams, setUrlParams } from 'utils'

let mounted

export default ({ path, getListRequest, listName, columns, urlPrefix }) => {
  const location = useLocation()
  const history = useHistory()

  const urlData = parseUrlParams(location.search)
  const urlParams = urlData.params || {}
  const urlPage = parseInt(urlParams.page, 10)
  const defaultPage = _.isNaN(urlPage) ? 0 : urlPage

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [listPages, setListPages] = useState([])
  const [currentPageNum, setCurrentPageNum] = useState(defaultPage)
  const [numPages, setNumPages] = useState(0)

  const prefixUrl = (url) => !_.isNil(urlPrefix) ? `${urlPrefix}${url}` : url

  const handleListResponse = (res) => {
    const resPageNum = _.get(res, `payload.page`)
    const resNumPages = _.get(res, `payload.numPages`)
    const resPage = _.get(res, `payload.${listName}`)
    const newPages = [ ...listPages ]
    newPages[resPageNum] = resPage
    setListPages(newPages)
    setCurrentPageNum(resPageNum)
    setNumPages(resNumPages)
  }

  const loadPage = (page) => {
    setIsLoading(true)
    setCurrentPageNum(page)
    getListRequest(page).then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (res.error) {
          setIsError(true)
        } else {
          handleListResponse(res)
        }
      }
    })
  }

  useEffect(() => {
    const urlData = parseUrlParams(location.search)
    history.replace(setUrlParams(urlData.url, { page: currentPageNum }))
  }, [currentPageNum])

  useEffect(() => {
    mounted = true
    loadPage(currentPageNum)
    return () => {
      mounted = false
    }
  }, [])

  const currentPage = listPages[currentPageNum] || []

  return (
    <React.Fragment>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            {_.map(columns, (col) => (<th key={col.key} scope="col">{col.name}</th>))}
          </tr>
        </thead>
        <tbody>
          {_.map(currentPage, (item) => (
            <tr key={item.id}>
              {_.map(columns, (col) => (col.link && _.get(item, col.link)) ? (
                <td key={`${item.id}-${col.key}`}>
                  <Link to={prefixUrl(_.get(item, col.link))} target={col.newTab ? "_blank" : ""}>
                    {_.get(item, col.key)}
                  </Link>
                </td>
              ) : (
                <td key={`${item.id}-${col.key}`}>{!_.isNil(col.getValue) ? col.getValue(item) : _.get(item, col.key)}</td>
              ))}
            </tr>
          ))}
          {!isLoading && _.isEmpty(currentPage) && (
            <tr>
              <td colSpan={columns.length} className="text-center">
                This list is empty.
              </td>
            </tr>
          )}
          {isLoading && (
            <tr>
              <td colSpan={columns.length} className="text-center">
                <span className="spinner-grow spinner-border-sm" role="status" aria-hidden="true">
                  <span className="sr-only">Loading...</span>
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagination onPageClick={loadPage} currentPageNum={currentPageNum} numPages={numPages} />

    </React.Fragment>
  )
}
