import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import NavBar from 'components/main/NavBarV2'

import { connect } from 'react-redux'
import { exploreData } from 'actions/api/explore'

const ExplorePage = ({ exploreData }) => {
  let seeMoreRef = useRef(null)
  let history = useHistory()
  let [artistList, setArtistList] = useState([])
  let [pageNum, setPageNum] = useState(0)
  let [numPages, setNumPages] = useState(0)
  let [isError, setIsError] = useState(false)
  let [isLoading, setIsLoading] = useState(true)
  let [searchText, setSearchText] = useState('')

  let loadArtists = (page) => {
    setIsLoading(true)
    exploreData(page).then((res) => {
      setIsLoading(false)
      if (res.error) {
        setIsError(true)
      } else {
        setArtistList([
          ...artistList,
          ...res.payload.artists
        ])
        setPageNum(res.payload.page)
        setNumPages(res.payload.numPages)
      }
    })
  }

  const seeMoreArtists = () => {
    if (pageNum+1 < numPages) { loadArtists(pageNum+1) }
  }

  const onSearch = (e) => {
    e.preventDefault()
    history.push(`/search?q=${searchText}`)
  }

  const handleScroll = () => {
    if (_.isNil(seeMoreRef)) { return null }
    const seeMoreTop = $(seeMoreRef.current).offset().top
    const viewEnd = $(window).scrollTop() + $(window).height()
    const distance = seeMoreTop - viewEnd
    if (!isLoading && pageNum+1 < numPages && distance < 200) {
      seeMoreArtists()
    }
  }

  useEffect(() => loadArtists(0), [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <>
      <NavBar backgroundColor="#ffcf5a" />

      <div className="min-vh-100 width-100 background-yellow absolute-top-left">
        <div className='navbar-spacer'></div>
        <div className="container py-3 text-center">

          <h1 className="my-3">Explore</h1>

          <form className='mw-480 mb-3 mx-auto px-4' onSubmit={onSearch}>
            <div className="input-group px-2">
              <div className='input-group-prepend' style={{ zIndex: 1 }}>
                <button className="btn border-black bg-transparent text-black" onClick={onSearch}>
                  <i className='fa fa-search'></i>
                </button>
              </div>
              <input
                type="text"
                className="form-control border-black bg-transparent text-black"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </form>

          <div className='rounded-lg py-3 px-4'>
            <div className="row">
              {_.map(artistList, (artist) => (
                <div key={artist.id} className="col-sm-12 col-md-6 col-lg-4 mb-5">
                  <div className="artist-container" style={{ backgroundImage: `url('${artist.imageUrl}')` }}>
                    <a className="artist-link text-center" target="_blank" href={artist.url} >
                      <div className="artist-mask"></div>
                      <div className="artist-text">{artist.name}</div>
                    </a>
                  </div>
                  <div className="text-center mt-1 large-text">{artist.name}</div>
                </div>
              ))}
            </div>
          </div>

          {isError && (
            <div className="text-center alert alert-danger">
              An error has occurred, please try reloading the page.  If this error persists, please contact <a href="mailto:splitterfm@gmail.com">splitterfm@gmail.com</a>
            </div>
          )}
          {isLoading && (
            <div className="text-center">
              <div className="spinner-grow text-light mb-2" role="status"></div>
              <h3>Loading...</h3>
            </div>
          )}
          {!isLoading && pageNum+1 < numPages && (
            <div ref={seeMoreRef} className="btn btn-light" onClick={() => seeMoreArtists()}>
              See More Artists
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default connect(null, { exploreData })(ExplorePage)
