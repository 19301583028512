import {
  CURRENT_USER_ACTION,
  LOGOUT_ACTION,
  LOGIN_ACTION,
  REGISTER_ACTION,
  CONFIRM_EMAIL_ACTION,
  DELETE_USER_ACCOUNT
} from 'actions/api/users'

import {
  isSuccessAction,
  isFailureAction,
  isResourceAction
} from 'actions/api'

export default (state=null, action) => {
  if ((action.type.match(CURRENT_USER_ACTION) ||
       action.type.match(LOGIN_ACTION) ||
       action.type.match(REGISTER_ACTION) ||
       action.type.match(CONFIRM_EMAIL_ACTION) ||
       action.type.match(DELETE_USER_ACCOUNT)) &&
      isSuccessAction(action) &&
      isResourceAction(action) &&
      action.payload) {
    return action.payload.result
  }

  if (action.type.match(LOGOUT_ACTION) &&
      isSuccessAction(action)) {
    return null
  }

  if (action.payload && action.payload.setLoggedOut === true) {
    return null
  }

  return state
}
