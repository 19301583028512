import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { sendPasswordReset } from 'actions/api/users'
import { SubmissionError } from 'redux-form'
import { processApiErrors } from 'utils'

import NavBar from 'components/main/NavBarV2'
import SendPasswordResetForm from 'components/main/register/SendPasswordResetForm'

let mounted, successTimeout
const SUCCESS_TIME = 4000

const SendPasswordResetPage = ({ sendPasswordReset }) => {
  const [isSuccess, setIsSuccess] = useState(false)

  const onPasswordResetEmail = (values) => sendPasswordReset({ user: values }).then((res) => {
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      if (mounted) {
        setIsSuccess(true)
        successTimeout = setTimeout(() => setIsSuccess(false), SUCCESS_TIME)
      }
    }
  })

  useEffect(() => {
    mounted = true
    return () => {
      clearTimeout(successTimeout)
      mounted = false
    }
  }, [])

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffcf5a" />

      <div className="min-vh-100 width-100 background-yellow absolute-top-left">
        <div className='navbar-spacer'></div>
        <div className="container mt-5 py-3">

          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-8 col-lg-6">
              <div className="mx-3">
                <h2 className="my-3">Password Reset</h2>
                <div className="mb-3">
                  <SendPasswordResetForm onSubmit={onPasswordResetEmail} />
                </div>
                {isSuccess && (
                  <div className="alert alert-success">
                    Password Reset Email Sent!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}


export default connect(null, {
  sendPasswordReset
})(SendPasswordResetPage)
