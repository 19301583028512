export const AUTH_PARAM = "auth_key"
export const LIST_TIMEOUT = 100

export const STEM_NAME_WARNING_LENGTH = 7

export const SONG_MAX_STEMS = 22

export const CONVERSION_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILED: "failed",
}

export const AUDIO_FILE_TYPES = {
  "audio/mpeg": 15 * 1024 * 1024, // mp3 - 12MB max
  "audio/x-mpeg": 15 * 1024 * 1024, // mp3 - 12MB max
  "audio/wav": 150 * 1024 * 1024, // WAV - 120MB max
  "audio/x-wav": 150 * 1024 * 1024, // WAV - 120MB max
  "audio/aiff": 150 * 1024 * 1024, // aif - 120MB max
  "audio/x-aiff": 150 * 1024 * 1024, // aif - 120MB max
}

export const IMAGE_FILE_TYPES = {
  "image/png": 25 * 1024 * 1024, // png - 5MB max
  "image/gif": 25 * 1024 * 1024, // gif - 5MB max
  "image/jpeg": 25 * 1024 * 1024, // jpg - 5MB max
}

export const SONG_TYPES = {
  STEM_PLAYER: {
    name: 'Stem Player',
    enumValue: 'stem_player',
    stemTitle: 'Stem',
    stemTitlePlural: 'Stems',
  },
  MIX_COMPARISON: {
    name: 'Mix Comparison',
    enumValue: 'mix_comparison',
    stemTitle: 'Mix',
    stemTitlePlural: 'Mixes',
  }
}

export const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'
