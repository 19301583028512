import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { ReactSortable } from 'react-sortablejs'

export default ({ list, setList, onChange, title, isLoading, emptyText, renderItem, ownProps }) => {
  return (
    <React.Fragment>
      <h3>
        {title}
        {isLoading && (
          <div className="spinner-border spinner-border-sm text-dark ml-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </h3>
      <ReactSortable { ...ownProps } className="list-group" list={list} setList={setList} onEnd={onChange}>
        {_.map(list, (li) => _.isNil(renderItem) ? (
          <div key={li.id} className="list-group-item">
            <i className="fa fa-bars mr-2" aria-hidden="true"></i>
            {li.name}
          </div>
        ) : renderItem(li))}
      </ReactSortable>
      {_.isEmpty(list) && (
        <div className="list-group">
          <div className="list-group-item text-center">{emptyText}</div>
        </div>
      )}
    </React.Fragment>
  )
}
