import { resourceRSAA, jsonRSAA } from 'actions/api'

export const UPDATE_STEM_ACTION = 'UPDATE_STEM'
export function updateStem(id, data) {
  return resourceRSAA(UPDATE_STEM_ACTION, `/api/stems/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data)
  })
}

export const GET_STEM_ACTION = 'GET_STEM'
export function getStem(id) {
  return resourceRSAA(GET_STEM_ACTION, `/api/stems/${id}`, { method: 'GET' })
}


export const UPLOAD_STEM_ACTION = 'UPLOAD_STEM'
export function uploadStem(id, data) {
  return resourceRSAA(UPLOAD_STEM_ACTION, `/api/stems/${id}/upload`, {
    method: 'PUT',
    body: JSON.stringify(data)
  })
}

export const DELETE_STEM_ACTIOn = 'DELETE_STEM'
export function deleteStem(id) {
  return resourceRSAA(UPLOAD_STEM_ACTION, `/api/stems/${id}`, { method: 'DELETE' })
}
