import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { reset, SubmissionError } from 'redux-form'
import { selectCurrentUser } from 'selectors'
import {
  updateUserAccount,
  updateUserEmail,
  updateUserPassword,
  deleteUserAccount,
  sendEmailConfirmation
} from 'actions/api/users'

import NavBar from 'components/main/NavBarV2'
import LoadingButton from 'components/main/LoadingButton'
import AccountForm from 'components/main/users/AccountForm'
import UpdateEmailForm from 'components/main/users/UpdateEmailForm'
import UpdatePasswordForm from 'components/main/users/UpdatePasswordForm'

import { processApiErrors } from 'utils'

let successTimeout, emailTimeout, passwordTimeout, mounted

const AccountPage = ({
  user,
  reset,
  updateUserAccount,
  updateUserEmail,
  updateUserPassword,
  deleteUserAccount,
  sendEmailConfirmation,
}) => {

  const history = useHistory()
  const [isConfirmationEmailLoading, setIsConfirmationEmailLoading] = useState(false)
  const [isConfirmationEmailSent, setConfirmationEmailSent] = useState(false)
  const [isConfirmationEmailError, setConfirmationEmailError] = useState(false)
  const [isAccountSuccess, setIsAccountSuccess] = useState(false)
  const [isEmailSuccess, setIsEmailSuccess] = useState(false)
  const [isPasswordSuccess, setIsPasswordSuccess] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const generateUpdateFunction = (requestAction, responseFunction, timeout) => (values) => {
    mounted = true
    return requestAction({ current_user: { ...values } }).then((res) => {
      if (res.error) {
        throw new SubmissionError(processApiErrors(res.payload.errors))
      } else {
        if (mounted) {
          responseFunction(true)
          timeout = setTimeout(() => responseFunction(false), 2000)
        }
        reset('updatePasswordForm')
      }
    })
  }

  const onSendEmailConfirmation = () => {
    setIsConfirmationEmailLoading(true)
    setConfirmationEmailError(false)
    sendEmailConfirmation().then((res) => {
      setIsConfirmationEmailLoading(false)
      if (res.error) {
        setConfirmationEmailError(true)
      } else {
        setConfirmationEmailSent(true)
      }
    })
  }

  const updateUser = generateUpdateFunction(updateUserAccount, setIsAccountSuccess, successTimeout)
  const updateEmail = generateUpdateFunction(updateUserEmail, setIsEmailSuccess, emailTimeout)
  const updatePassword = generateUpdateFunction(updateUserPassword, setIsPasswordSuccess, passwordTimeout)
  const onDeleteUser = () => {
    mounted = true
    setIsDeleteLoading(true)
    deleteUserAccount().then((res) => {
      if (mounted) {
        setIsDeleteLoading(false)
      }
    })
  }

  useEffect(() => {
    successTimeout = null
    emailTimeout = null
    passwordTimeout = null
    return () => {
      mounted = false
      clearTimeout(successTimeout)
      clearTimeout(emailTimeout)
      clearTimeout(passwordTimeout)
    }
  }, [])

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffcf5a" />

      <div className="min-vh-100 width-100 background-yellow absolute-top-left">
        <div className='navbar-spacer'></div>
        <div className="container pb-5">

          <div className="row justify-content-center">
            <div className="col-xs-12 col-lg-10 col-xl-8">
              <div className="mx-3">
                <h1 className="mt-5 mb-3">Account Settings</h1>

                {!_.get(user, 'isEmailConfirmed', false) && (
                  <React.Fragment>
                    {!isConfirmationEmailSent && !isConfirmationEmailError && (
                      <div className="alert alert-warning">
                        <div className="mb-3">
                          <i className="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i> Your email address is not confirmed.  Please check your email for a confirmation link.  If you do not have one, click the button below to send yourself a new confirmation link.
                        </div>
                        <button onClick={onSendEmailConfirmation} className='btn btn-info' disabled={isConfirmationEmailLoading}>
                          {isConfirmationEmailLoading ? (
                            <React.Fragment>
                              <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                              Loading...
                            </React.Fragment>
                          ): (
                            <React.Fragment>Resend Confirmation Email</React.Fragment>
                          )}
                        </button>
                      </div>
                    )}

                    {isConfirmationEmailSent && (
                      <div className='alert alert-success'>
                        A new confirmation link was sent to your email.  Please check your email and click the link to confirm your account.
                      </div>
                    )}

                    {isConfirmationEmailError && (
                      <div className='alert alert-danger'>
                        There was an error sending your confirmation email, please try refreshing the page.  If this error persists, please contact <a href="mailto:splitterfm@gmail.com">splitterfm@gmail.com</a>
                      </div>
                    )}
                  </React.Fragment>
                )}

                <AccountForm user={user} onSubmit={updateUser} isSuccess={isAccountSuccess} />

                <div className="border-bottom border-dark my-3"></div>

                <h3>Update Email</h3>
                <UpdateEmailForm user={user} onSubmit={updateEmail} isSuccess={isEmailSuccess} />
                <div className='alert alert-warning'>
                  <strong>WARNING:</strong> When you update your email, you will be sent a new email confirmation and will not be able to make changes to your account until your new email is confirmed.
                </div>

                <div className="border-bottom border-dark my-3"></div>

                <h3>Update Password</h3>
                <UpdatePasswordForm user={user} onSubmit={updatePassword} isSuccess={isPasswordSuccess} />

                <div className="border-bottom border-dark my-3"></div>

                <h3>Delete Account</h3>
                <LoadingButton ownProps={{ className: 'btn btn-danger', onClick: onDeleteUser }} isLoading={isDeleteLoading}>
                  Delete Account
                </LoadingButton>

              </div>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  )
}

export default connect((state) => ({
  user: selectCurrentUser(state)
}), {
  reset,
  updateUserAccount,
  updateUserEmail,
  updateUserPassword,
  deleteUserAccount,
  sendEmailConfirmation
})(AccountPage)
