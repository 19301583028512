import React from 'react'

import NavBar from 'components/main/NavBarV2'

import Accordion from 'components/main/Accordion'

import { getStaticAsset } from 'utils'
import { Link } from 'react-router-dom'

export default () => (
  <>
    <NavBar
      isFixed
      backgroundColor="#ffcf5a"
    />

    <div className="min-vh-100 width-100 background-yellow absolute-top-left">
      <div className='navbar-spacer'></div>
      <div className='container py-3 mt-4'>
        <div className='row mt-4 mb-5'>
          <div className='col-12 col-lg-7 col-xl-6'>
            <div className="big-text mb-4">
              a director's cut for songs
            </div>

            <div className="mt-4">
              <p>
                Splitter is the first “creator commentary” tool for music... ever!
              </p>

              <p>
                Developed by the band Lawrence, Splitter enables any artist to invite
                their fans into the studio, allowing listeners to mute and isolate stems
                as well as read behind-the-scenes commentary left by band members.
              </p>
            </div>
          </div>

          <div className='d-none d-xl-block col-xl-1'></div>

          <div className='col-12 col-lg-5'>
            <div className="mt-lg-4">
              <Accordion
                accordionKey='home-accordion-basics'
                accordionData={[
                  {
                    key: 'mute-and-solo',
                    header: 'mute and solo instruments',
                    body: 'mute the vocals so you can sing over them, or solo the lead guitar so you can learn a riff',
                  },
                  {
                    key: 'read-bts',
                    header: 'read behind-the-scenes commentary',
                    body: 'learn more about your favorite tunes by reading notes left by the artist, specific to each instrument track',
                  },
                  {
                    key: 'ask-questions',
                    header: 'ask questions and engage',
                    body: 'comment on your favorite sections and talk to the artists by leaving questions on each stem ',
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <img className='img-fluid mb-5' src={getStaticAsset('demoScreenshot')} />
      </div>

      <div className='background-purple'>
        <div className='container pt-5 pb-3'>
          <h1 className='text-center mb-4'>check out some current splitter pages</h1>
          <div className='row mb-3'>
            <div className='col-12 col-lg-4 mb-5'>
              <div className='homepage-band-icon-container mb-2 d-flex align-items-center justify-content-center'>
                <img className='homepage-band-icon mb-3' src={getStaticAsset('scaryPocketsLogo')} />
              </div>
              <h3 className='text-center'>Scary Pockets</h3>
              <div className='mb-3'>
                Scary Pockets are a dynamic funk band formed from the LA music scene (Los Angeles, USA).
                The band consists of Ryan Lerman and Jack Conte who are joined by a continuously rotating
                line up of quality musicians to form Scary Pockets. Ryan Lerman spent his twenties touring
                as a bassist for the American singer-songwriter, Ben Folds. He also has performed as a
                guitarist for Micheal Bublé and as a musical director for John Legend. A very impressive
                back catalog of performance history. The other half of Scary Pockets, Jack Conte, is an
                American musician, singer-songwriter, disc jockey, entrepreneur, and filmmaker.
              </div>
              <div className='text-center'>
                <a
                  className='btn btn-home text-light'
                  href='/scarypockets'
                >check out their splitter page</a>
              </div>
            </div>

            <div className='col-12 col-lg-4 mb-5'>
              <div className='homepage-band-icon-container mb-2 d-flex align-items-center justify-content-center'>
                <img className='homepage-band-icon mb-3' src={getStaticAsset('lawrenceLogo')} />
              </div>
              <h3 className='text-center'>Lawrence</h3>
              <div className='mb-3'>
                As NPR writes, “siblings Clyde and Gracie Lawrence are not your typical pair." Clyde Lawrence
                and Gracie Lawrence have been writing songs and listening to countless Stevie Wonder, Randy
                Newman, and Aretha Franklin records in their family's New York City apartment since they were
                little kids. After years of playing together, they officially created Lawrence, an eight-piece
                soul-pop band comprised of musician friends from childhood and college. The band has since
                gained a devoted following for its high-energy, keyboard-driven sound, which features tight,
                energetic horns, explosive lead vocals, and fully-live arrangements."
              </div>
              <div className='text-center'>
                <a
                  className='btn btn-home text-light'
                  href='/lawrencetheband'
                >check out their splitter page</a>
              </div>
            </div>

            <div className='col-12 col-lg-4 mb-3 mb-lg-3'>
              <div className='homepage-band-icon-container mb-2 d-flex align-items-center justify-content-center'>
                <img className='homepage-band-icon mb-3' src={getStaticAsset('walkTheMoonLogo')} />
              </div>
              <h3 className='text-center'>WALK THE MOON</h3>
              <div className='mb-3'>
                WALK THE MOON first made a name for themselves by touring relentlessly in support of their
                self-titled debut, which featured the indie classic “Anna Sun.”  The alt-rock-pop group were
                then catapulted into the spotlight when “Shut Up and Dance” became a multi-platinum global
                smash, crossing sonic barriers to top three different Billboard charts before the end of 2015.
                After years of countless sold-out shows around the world, four studio albums, and billions of
                streams, WALK THE MOON continues to be known for their ecstatic live shows and welcoming,
                colorful community of fans.  Their latest album HEIGHTS, out now everywhere, offers up a
                vibrant array of self-love anthems, bold gestures, and songs of perseverance.
              </div>
              <div className='text-center'>
                <a
                  className='btn btn-home text-light'
                  href='/walkthemoon'
                >check out their splitter page</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container py-3 my-4'>
        <Accordion
          largeHeader
          accordionKey='home-accordion-about'
          accordionData={[
            {
              key: 'what-is-splitter',
              header: 'what is splitter.fm?',
              body: 'splitter.fm is an online interface that gives your fans the ability to isolate and combine the stems from all of your songs. It also lets you leave “behind-the-scenes” notes on each stem, so you can give your listeners an in-depth look at your process. Fun!',
            },
            {
              key: 'what-are-stems',
              header: 'what are \"stems\"?',
              body: 'a "stem" is a single track or group of tracks that, when combined with the other stems, make up a full recording.  These stems can be vocals, keys, guitar (or groups of guitars!), drums, sound effects, and more.',
            },
            {
              key: 'why-is-this-awesome',
              header: 'why is this awesome?',
              body: 'great question! artists like you are always looking for a way to connect with your listeners, whether it’s through a music video, a Reddit AMA, a remix contest…the list goes on. So, with a hard drive full of stems you spent days (months? years?) working on, why not put them to work too?',
            },
          ]}
        />
      </div>

      <div className='background-purple'>
        <div className='container pt-4 pb-3'>
          <h1 className='text-center mb-4'>useful for all sorts of marketing!</h1>
          <div className='row'>
            <div className='col-12 col-lg-6 mb-4'>
              <div className='row'>
                <div className='col-6'>
                  <img className='img-fluid' src={getStaticAsset('marketing1')} />
                </div>
                <div className='col-6'>
                  <img className='img-fluid' src={getStaticAsset('marketing2')} />
                </div>
              </div>
            </div>

            <div className='col-12 col-lg-6 mb-5 mb-lg-3'>
              <Accordion
                accordionKey='home-accordion-marketing'
                accordionData={[
                  {
                    key: 'ask-me-anything',
                    header: 'ask-me-anything (AMA)',
                    body: 'Invite listeners to “ask-me-anything” about your stems using the commenting feature.',
                  },
                  {
                    key: 'remix-contest',
                    header: 'remix contest',
                    body: 'Welcome your more musically-inclined fans to do a karaoke contest, or cover contest, by muting and playing over the vocal, guitar, keys, drums or other stems.',
                  },
                  {
                    key: 're-release',
                    header: 're-release',
                    body: 'Fire up your fanbase by re-releasing an old album, this time with stems and behind-the-scenes notes!',
                  },
                  {
                    key: 'awards-community',
                    header: 'awards community',
                    body: 'Step up your Grammy campaign by bringing voters into the world of your album!',
                  },
                  {
                    key: 'patreon-rewards',
                    header: 'Patreon Rewards',
                    body: 'If your band is using Patreon, use Splitter to surprise and delight your patrons.',
                  },
                  {
                    key: 'fan-love',
                    header: 'fan love',
                    body: 'If you’re not on Patreon, use Splitter to surprise and delight EVERYBODY!',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='container py-3 mt-4'>
        <h1 className='text-center mb-4'>it's easy to get started!</h1>
        <div className='d-flex flex-column flex-lg-row mb-5'>
          <div className='mb-4 mb-lg-3 mx-3'>
            <img className='img-fluid mb-1' src={getStaticAsset('screenshot1')} />
            <div>
              1. drag-and-drop your stems in
            </div>
          </div>
          <div className='mb-4 mb-lg-3 mx-3'>
            <img className='img-fluid mb-1' src={getStaticAsset('screenshot2')} />
            <div>
              2. tweak the settings for your songs and albums
            </div>
          </div>
          <div className='mb-2 mb-lg-3 mx-3'>
            <img className='img-fluid mb-1' src={getStaticAsset('screenshot3')} />
            <div>
              3. then release the song to your fans!
            </div>
          </div>
        </div>

        <div className='separator mb-5'></div>

        <div className='text-center mb-5'>
          <h3 className='mb-3'>give your fans something awesome</h3>
          <a className="btn btn-home mb-2" href="/waitlist">
            join the splitter.fm waitlist
          </a>
        </div>

        <div className='separator mb-5'></div>

      </div>
    </div>
  </>
)
