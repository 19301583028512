import React, { useState } from 'react'
import classnames from 'classnames'
import { Link, useHistory } from 'react-router-dom'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectCurrentUser } from 'selectors'
import { logout } from 'actions/api/users'

import { getStaticAsset } from 'utils'

const NavBar = ({ currentUser, logout, isFixed, backgroundColor }) => {
  const history = useHistory()
  const [isExpanded, setIsExpanded] = useState(false)
  const user = currentUser || {}
  const onLogout = () => logout().then((res) => {
    if (!res.error) {
      history.push("/")
    }
  })

  return (
    <header className='header-v2'>
      <div className={classnames('header-v2-wrapper', { 'fixed': isFixed })}
        style={{ backgroundColor: backgroundColor }}
      >
        <div className='header-v2-inner desktop'>
          <Link to="/" className='header-title'>
            <img src={getStaticAsset('logoBarCombo')} className='header-image' />
          </Link>

          <div className='header-nav'>
            <div className='header-nav-item'>
              <Link className="text-dark" to="/explore">Explore</Link>
            </div>
            <div className='header-nav-item'>
              <Link className="text-dark" to="/contact">Contact</Link>
            </div>
            <div className='header-nav-item'>
              <Link className="text-dark" to="/faq">FAQ</Link>
            </div>

            {_.isNil(user.id) ? (
              <>
                <div className="header-nav-item">
                  <a className="text-dark" href="/waitlist">For Artists</a>
                </div>
                <div className="header-nav-item">
                  <Link className="text-dark" to="/login">Sign In</Link>
                </div>
                <div className="header-nav-item">
                  <Link className="text-dark" to="/register">Sign Up</Link>
                </div>
              </>
            ) : (
              <>
                {user.isAdmin && (
                  <div className="header-nav-item">
                    <Link className="text-dark" to="/admin">Admin</Link>
                  </div>
                )}
                {!_.isNil(user.artistId) ? (
                  <div className="header-nav-item">
                    <Link className="text-dark" to="/artist">Dashboard</Link>
                  </div>
                ) : (
                  <div className="header-nav-item">
                    <a className="text-dark" href="/waitlist">For Artists</a>
                  </div>
                )}
                <div className="header-nav-item">
                  <Link className="text-dark" to="/user/edit">Account</Link>
                </div>
                <div className="header-nav-item">
                  <a className="text-dark" onClick={() => onLogout()}>Log Out</a>
                </div>
              </>
            )}
          </div>
        </div>

        <div className='header-v2-inner mobile'>
          <Link to="/" className='header-title'>
            <img src={getStaticAsset('logoBarCombo')} className='header-image' />
          </Link>

          <div className='header-nav'>
            <div
              className={classnames('burger', { 'active': isExpanded })}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <div className='burger-inner'>
                <div className='burger-part top'></div>
                <div className='burger-part middle'></div>
                <div className='burger-part bottom'></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classnames('header-v2-mobile-menu', {
            'open': isExpanded,
          })}
          style={{
            backgroundColor: backgroundColor
          }}
          onClick={() => setIsExpanded(false)}
        >
          <div className='header-nav'>
            <div className='header-nav-item'>
              <Link className="text-dark" to="/explore">Explore</Link>
            </div>
            <div className='header-nav-item'>
              <Link className="text-dark" to="/contact">Contact</Link>
            </div>
            <div className='header-nav-item'>
              <Link className="text-dark" to="/faq">FAQ</Link>
            </div>

            {_.isNil(user.id) ? (
              <>
                <div className="header-nav-item">
                  <a className="text-dark" href="/waitlist">For Artists</a>
                </div>
                <div className="header-nav-item">
                  <Link className="text-dark" to="/login">Sign In</Link>
                </div>
                <div className="header-nav-item">
                  <Link className="text-dark" to="/register">Sign Up</Link>
                </div>
              </>
            ) : (
              <>
                {user.isAdmin && (
                  <div className="header-nav-item">
                    <Link className="text-dark" to="/admin">Admin</Link>
                  </div>
                )}
                {!_.isNil(user.artistId) ? (
                  <div className="header-nav-item">
                    <Link className="text-dark" to="/artist">Dashboard</Link>
                  </div>
                ) : (
                  <div className="header-nav-item">
                    <a className="text-dark" href="/waitlist">For Artists</a>
                  </div>
                )}
                <div className="header-nav-item">
                  <Link className="text-dark" to="/user/edit">Account</Link>
                </div>
                <div className="header-nav-item">
                  <a className="text-dark" onClick={() => onLogout()}>Log Out</a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default connect((state) => ({
  currentUser: selectCurrentUser(state),
}), { logout })(NavBar)
