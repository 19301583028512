import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Resources } from 'schema'
import { Link, useHistory } from 'react-router-dom'
import { SubmissionError } from 'redux-form'
import { processApiErrors } from 'utils'
import { selectModel, selectCurrentUser } from 'selectors'
import { getArtist } from 'actions/api/artists'
import { getSong, updateSong, deleteSong } from 'actions/api/songs'

import NavBar from 'components/main/NavBarV2'
import Breadcrumbs from 'components/main/Breadcrumbs'
import SongEditForm from 'components/main/songs/SongEditForm'
import ConfirmationButton from 'components/main/ConfirmationButton'

let mounted

const SongEditPage = ({
  album,
  artist,
  song,
  match,
  getSong,
  getArtist,
  updateSong,
  deleteSong,
}) => {
  const history = useHistory()
  const songId = _.get(match, 'params.id')

  const [isLoading, setIsLoading] = useState(false)

  const breadcrumbs = [{ label: "Home", url: "/artist" }]
  if (!_.isEmpty(album)) { breadcrumbs.push({ label: album.name, url: album.url }) }
  breadcrumbs.push({ label: _.get(song, 'name'), url: _.get(song, 'url') })
  breadcrumbs.push({ label: 'Edit Song', active: true })

  const onUpdateSong = (values) => updateSong(songId, { song: values }).then((res) => {
    if (res.error) {
      throw new SubmissionError(processApiErrors(res.payload.errors))
    } else {
      history.push(_.get(song, 'url'))
    }
  })

  const onDeleteSong = () => {
    const albumUrl = _.get(album, 'url')
    setIsLoading(true)
    deleteSong(songId).then((res) => {
      if (mounted) { setIsLoading(false) }
      if (!res.error) {
        if (albumUrl) {
          history.push(albumUrl)
        } else {
          history.push('/artist')
        }
      }
    })
  }

  useEffect(() => {
    mounted = true
    getArtist()
    getSong(songId)

    return () => {
      mounted = false
    }
  }, [songId])

  return (
    <React.Fragment>
      <NavBar backgroundColor="#ffffff" />

      <div className='container pb-5'>
        <Breadcrumbs crumbs={breadcrumbs} />

        <h1 className="mb-3">{_.get(song, 'name')}</h1>
        <h3 className="mb-3">Edit Song</h3>

        <div className="mb-2 ">
          <SongEditForm onSubmit={onUpdateSong} artist={artist} album={album} song={song} buttonText="Update" />
        </div>

        <ConfirmationButton
          ownProps={{ className: 'btn btn-danger' }}
          confirmText='Really Delete Song?'
          isLoading={isLoading}
          onSuccess={onDeleteSong}
        >Delete Song</ConfirmationButton>

      </div>

    </React.Fragment>
  )
}

export default connect((state, ownProps) => {
  const user = selectCurrentUser(state)
  const song = selectModel('songs', _.get(ownProps, 'match.params.id'), Resources.song, state)
  let album = null
  if (!_.isNil(_.get(song, 'albumId'))) { album = selectModel('albums', _.get(song, 'albumId'), Resources.album, state) }
  return {
    artist: selectModel('artists', _.get(user, 'artistId'), Resources.artist, state) || {},
    song,
    album,
  }
}, {
  getSong,
  getArtist,
  updateSong,
  deleteSong,
})(SongEditPage)
