import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { connect } from 'react-redux'
import { secondsToMinutes } from 'utils/time'
import { adminCommentsIndex } from 'actions/api/admin/comments'

import PaginatedList from 'components/main/PaginatedList'

const AdminCommentsList = ({ location, adminCommentsIndex }) => {
  return (
    <div className="mt-3">
      <PaginatedList
        getListRequest={adminCommentsIndex}
        listName='comments'
        urlPrefix='/admin'
        columns={[
          { name: 'ID', key: 'id', link: 'url' },
          { name: 'Song', key: 'song.name', link: 'song.url' },
          { name: 'Stem', key: 'stem.name' },
          { name: 'Time', key: 'stemTime', getValue: (s) => secondsToMinutes(s.stemTime) },
          { name: 'Username', key: 'user.username', link: 'user.url' },
          { name: 'Created', key: 'createdAt', getValue: (i) => moment(i.createdAt).fromNow() },
        ]}
      />
    </div>
  )
}

export default connect(null, {
  adminCommentsIndex,
})(AdminCommentsList)
