import { jsonRSAA } from 'actions/api'

export const ADMIN_GET_USER_INDEX_ACTION = 'ADMIN_GET_USER_INDEX'
export function adminGetUsersIndex(page) {
  return jsonRSAA(ADMIN_GET_USER_INDEX_ACTION, `/api/admin/users/?page=${page}`, null, { method: 'GET' })
}

export const ADMIN_GET_USER_ACTION = 'ADMIN_GET_USER'
export function adminGetUser(id) {
  return jsonRSAA(ADMIN_GET_USER_ACTION, `/api/admin/users/${id}`, null, { method: 'GET' })
}

export const ADMIN_GET_USER_COMMENTS_ACTION = 'ADMIN_GET_USER_COMMENTS'
export function adminGetUserComments(id, page) {
  return jsonRSAA(ADMIN_GET_USER_COMMENTS_ACTION, `/api/admin/users/${id}/comments?page=${page}`, null, { method: 'GET' })
}
