import React from 'react'
import ReactDOM from 'react-dom'
import MainApp from 'components/main/MainApp'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    React.createElement(MainApp, { ...window.jsonVars }),
    document.body.appendChild(document.createElement('div')),
  )
})
