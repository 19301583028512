import React from 'react'

import { getStaticAsset } from 'utils'
import { Link } from 'react-router-dom'

export default () => (
  <div className="min-vh-100 width-100 fixed-top d-flex justify-content-center align-items-center text-light">
    <div className='flex-grow-1 text-center'>
      <div className="spinner-grow loading-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className='mb-2'>
        <img className="home-icon" src={getStaticAsset('logoWhite')} />
      </div>
      <div className="spinner-grow loading-grow" role="status">
      </div>
    </div>
  </div>
)
