import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { adminGetFaqSections, adminOrderFaqSections } from 'actions/api/admin/faq'
import { LIST_TIMEOUT } from 'static'

import AdminBase from 'components/main/admin/AdminBase'
import SortableList from 'components/main/SortableList'

let mounted, listUpdateTimeout

const AdminFaqSectionsPage = ({
  adminGetFaqSections,
  adminOrderFaqSections,
}) => {
  const [faqSections, setFaqSections] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onOrderSections = () => {
    setIsLoading(true)
    clearTimeout(listUpdateTimeout)
    listUpdateTimeout = setTimeout(() => {
      adminOrderFaqSections({
        faq_sections: [
          ..._.map(faqSections, (s, i) => ({ id: s.id, index: i })),
        ],
      }).then((res) => {
        if (mounted) {
          setIsLoading(false)
          if (!res.error) { loadSongsFromRes(res) }
        }
      })
    }, LIST_TIMEOUT)
  }

  useEffect(() => {
    mounted = true
    listUpdateTimeout = null
    setIsLoading(true)
    adminGetFaqSections().then((res) => {
      if (mounted) {
        setIsLoading(false)
        if (!res.error) {
          setFaqSections(_.get(res, 'payload.faqSections'))
        }
      }
    })
    return () => {
      mounted = false
    }
  }, [])

  return (
    <AdminBase breadcrumbs={[
      { label: "Admin", url: '/admin' },
      { label: "FAQ", active: true },
    ]}>
      <h1>Edit FAQ</h1>

      <div className="mb-2">
        <SortableList
          title='FAQ Sections'
          list={faqSections}
          setList={setFaqSections}
          onChange={onOrderSections}
          isLoading={isLoading}
          emptyText='There are no FAQ Sections.'
          renderItem={(li) => (
            <div key={li.id} className="list-group-item">
              <i className="fa fa-bars mr-2"></i> {li.title}
              <Link className="float-right" to={`${li.url}/edit`}>edit</Link>
            </div>
          )}
        />
      </div>

      <Link className="btn btn-primary" to='/admin/faq_sections/new'>Add FAQ Section</Link>

    </AdminBase>
  )
}

export default connect(null, {
  adminGetFaqSections,
  adminOrderFaqSections,
})(AdminFaqSectionsPage)
