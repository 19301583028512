import { jsonRSAA } from 'actions/api'

export const ADMIN_TRIGGER_STEM_CONVERSION = 'ADMIN_TRIGGER_STEM_CONVERSION'
export function adminTriggerStemConversion(id) {
  return jsonRSAA(ADMIN_TRIGGER_STEM_CONVERSION, `/api/admin/stems/${id}/trigger_conversion`, null, { method: 'PUT' })
}

export const ADMIN_TRIGGER_STEM_RECONVERSION = 'ADMIN_TRIGGER_STEM_RECONVERSION'
export function adminTriggerStemReconversion(id) {
  return jsonRSAA(ADMIN_TRIGGER_STEM_RECONVERSION, `/api/admin/stems/${id}/trigger_reconversion`, null, { method: 'PUT' })
}

export const ADMIN_REGENERATE_STEM_IMAGE = 'ADMIN_REGENERATE_STEM_IMAGE'
export function adminRegenerateStemImage(id) {
  return jsonRSAA(ADMIN_REGENERATE_STEM_IMAGE, `/api/admin/stems/${id}/regenerate_image`, null, { method: 'PUT' })
}

