import _ from 'lodash'
export * from './url'
export * from './validation'
export * from './songs'

export function getStaticAsset(assetName) {
  return _.get(window.jsonVars, `staticAssets.${assetName}`, null)
}

export function iff(f, ...args) {
  if (_.isFunction(f)) {
    f.apply(null, args)
  }
}
