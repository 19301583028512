import React from 'react'
import _ from 'lodash'

import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import FormInput from 'components/main/FormInput'
import LoadingButton from 'components/main/LoadingButton'

let AdminArtistEditForm = ({ handleSubmit, submitting, artist }) => {
  const history = useHistory()
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="artist_name">Artist Name</label>
        <Field name="artist_name" component={FormInput} type="text" classes="form-control" />
      </div>

      <div className="form-group">
        <label htmlFor="artist_url">Artist URL</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">{window.jsonVars.baseUrl}/</div>
          </div>
          <Field name="artist_url" component={FormInput} type="text" classes="form-control" />
        </div>
        <small id="artistUrlDesc" className="form-text">This is your unique splitter.fm URL</small>
      </div>

      <div className="form-group">
        <label htmlFor="artist_bio">Artist Description</label>
        <small id="artistBioDesc" className="form-text">This text will show up on your public artist page</small>
        <Field name="artist_bio" component={FormInput} type="textarea" classes="form-control" />
      </div>

      <div className="form-group form-check">
        <label>
          <Field name="is_hidden" component={FormInput} type="checkbox" classes="form-check-input" />
          Hide Profile
          <small className="form-text">
            When hidden, your artist profile will not appear on the splitter.fm "explore"
            page, but can still be viewed through the direct url.
          </small>
        </label>
      </div>

      <div className="form-group form-check">
        <label>
          <Field name="is_mix_comparison_enabled" component={FormInput} type="checkbox" classes="form-check-input" />
          Enable Mix Comparisons
          <small className="form-text">
            When checked, this artist will be able to create "Mix Comparison" players.  Mix Comparison songs
            are hidden from all public view, but can be shared privately among registered users.
          </small>
        </label>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group form-check">
            <label>
              <Field name="is_highlighted" component={FormInput} type="checkbox" classes="form-check-input" />
              Highlight on Expore Page
              <small className="form-text">
                Highlighted artists appear on the top of the explore page, and can be reordered in the "Explore" tab
                on the admin dashboard
              </small>
            </label>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="highlight_index">Highlight Rank</label>
            <input
              name="highlight_index"
              type="text"
              className="form-control"
              value={_.get(artist, 'highlightIndex', '')}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group form-check">
            <label>
              <Field name="ignore_rank_calculator" component={FormInput} type="checkbox" classes="form-check-input" />
              Ignore Nightly Rank Calculator
              <small className="form-text">
                This artist will not automatically recalculate their explore "rank" value
              </small>
            </label>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="explore_rank">Explore Rank</label>
            <Field name="explore_rank" component={FormInput} type="text" classes="form-control" />
          </div>
        </div>
      </div>

      <div>
        <LoadingButton
          ownProps={{ className: 'btn btn-primary mr-2', type: "submit"}}
          isLoading={submitting}
          successText="Updated!"
        >
          Update
        </LoadingButton>
        <a onClick={() => history.goBack()} className="btn btn-secondary">Cancel</a>
      </div>
    </form>
  )
}

const validate = (values) => {
  const errors = {}
  return errors
}

AdminArtistEditForm = reduxForm({
  form: 'adminArtistEditForm',
  validate,
  enableReinitialize: true,
})(AdminArtistEditForm)

export default connect((state, ownProps) => ({
  initialValues: _.mapKeys(ownProps.artist, (v, k) => _.snakeCase(k)),
}))(AdminArtistEditForm)
