import React from 'react'
import _ from 'lodash'

import { Field, reduxForm } from 'redux-form'
import FormInput from 'components/main/FormInput'
import LoadingButton from 'components/main/LoadingButton'

import { EMAIL_REGEX } from 'utils'

const SendPasswordResetForm = ({ handleSubmit, submitting }) => (
  <form onSubmit={handleSubmit}>
    <div className="form-group">
      <label htmlFor="email">Email address</label>
      <Field name="email" component={FormInput} type="email" classes="form-control" />
    </div>

    <div>
      <LoadingButton ownProps={{ className: 'btn btn-light', type: "submit"}} isLoading={submitting}>
        Send Email
      </LoadingButton>
    </div>
  </form>
)

const validate = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = "Email is required"
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = "Email is invalid"
  }

  return errors
}

export default reduxForm({
  form: 'sendPasswordReset',
  validate,
})(SendPasswordResetForm)
