import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { connect } from 'react-redux'
import { confirmEmail } from 'actions/api/users'

import LoadingPage from 'components/main/LoadingPage'
import EmailConfirmationPage from 'components/main/register/EmailConfirmationPage'

import { parseUrlParams } from 'utils'

const ConfirmEmailPage = ({ confirmEmail }) => {
  const location = useLocation()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    let mounted = true
    setIsLoading(true)
    const urlData = parseUrlParams(location.search)
    const urlParams = urlData.params || {}
    confirmEmail(urlParams.til).then((res) => {
      if (mounted) {
        setIsLoading(false)
        setIsError(res.error)
      }
      if (!res.error) {
        history.push("/email_confirmed")
      }
    })
    return () => { mounted = false }
  }, [])

  if (isLoading) {
    return (<LoadingPage />)
  }

  return ( <EmailConfirmationPage isConfirmError={isError} /> )
}

export default connect(null, { confirmEmail })(ConfirmEmailPage)
