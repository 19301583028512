import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { Field, reduxForm } from 'redux-form'

import { getSongTypeData, doesSongTypeMatch } from 'utils'
import { SONG_TYPES } from 'static'

import FormInput from 'components/main/FormInput'
import LoadingButton from 'components/main/LoadingButton'

let StemEditForm = ({ handleSubmit, onSubmit, submitting, stem, song, album, buttonText }) => {
  const history = useHistory()
  const submitAndNext = (values) => onSubmit({ ...values, saveAndNext: true, })
  const songType = _.get(song, 'songType')
  const songTypeData = getSongTypeData(songType)
  return (
    <form onSubmit={handleSubmit(submitAndNext)}>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="name">{songTypeData.stemTitle} Name</label>
            <Field name="name" component={FormInput} type="text" classes="form-control" />
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="bg_color">{songTypeData.stemTitle} Color</label>
            <Field name="bg_color" component={FormInput} type="color" classes="form-control" />
          </div>
        </div>
      </div>

      <div className="form-group form-check">
        <label>
          <Field name="is_hidden" component={FormInput} type="checkbox" classes="form-check-input" />
          Hide {songTypeData.stemTitle}
          <small className="form-text">
            Hidden {_.lowerCase(songTypeData.stemTitlePlural)} do not appear in the {_.lowerCase(songTypeData.stemTitle)} player
          </small>
        </label>
      </div>

      {doesSongTypeMatch(songType, SONG_TYPES.STEM_PLAYER) && (
        <div className="form-group form-check">
          <label>
            <Field name="is_muted_by_default" component={FormInput} type="checkbox" classes="form-check-input" />
            Mute {songTypeData.stemTitle} By Default
            <small className="form-text">
              If this checkbox is selected, this {_.lowerCase(songTypeData.stemTitle)} will load in the muted state anytime a
              new {_.lowerCase(songTypeData.stemTitle)} player is loaded
            </small>
          </label>
        </div>
      )}

      <div className="form-group">
        <label htmlFor="description">{songTypeData.stemTitle} Description</label>
        <Field name="description" component={FormInput} type="textarea" classes="form-control" />
      </div>

      <LoadingButton
        ownProps={{
          className: 'btn btn-primary mr-2',
          type: 'button',
          onClick: handleSubmit,
        }}
        isLoading={submitting}
      >
        Save
      </LoadingButton>

      {_.get(stem, 'nextStemUrl') && (
        <LoadingButton
          ownProps={{
            className: 'btn btn-primary mr-2',
            type: 'button',
            onClick: handleSubmit(submitAndNext),
          }}
          isLoading={submitting}
        >
          Save + Next
        </LoadingButton>
      )}
      <a className='btn btn-secondary' onClick={() => history.push(_.get(song, 'url'))}>Cancel</a>

    </form>
  )
}

const validate = (values) => {
  const errors = {}
  return errors
}

StemEditForm = reduxForm({
  form: 'stemEditForm',
  validate,
  enableReinitialize: true,
})(StemEditForm)

export default connect((state, ownProps) => ({
  initialValues: _.mapKeys(_.get(ownProps, 'stem', {}), (v, k) => _.snakeCase(k)),
}))(StemEditForm)
