import { resourceRSAA, jsonRSAA, get } from 'actions/api'

export const CREATE_ARTIST_ACTION = 'CREATE_ARTIST'
export function createArtist(data) {
  return resourceRSAA(CREATE_ARTIST_ACTION, `/api/artist`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export const GET_ARTIST_ACTION = 'GET_ARTIST'
export function getArtist() {
  return resourceRSAA(GET_ARTIST_ACTION, `/api/artist`, { method: 'GET' })
}

export const UPDATE_ARTIST_ACTION = 'UPDATE_ARTIST'
export function updateArtist(data) {
  return resourceRSAA(UPDATE_ARTIST_ACTION, `/api/artist`, {
    method: 'PUT',
    body: JSON.stringify(data)
  })
}


export const ARTIST_ORGANIZE_SONGS_ACTION = 'ARTIST_ORGANIZE_SONGS'
export function artistOrganizeSongs(data) {
  return resourceRSAA(ARTIST_ORGANIZE_SONGS_ACTION, `/api/artist/organize_songs`, {
    method: 'PUT',
    body: JSON.stringify(data)
  })
}

export const ARTIST_COMMENTS_INDEX = 'ARTIST_COMMENTS_INDEX'
export function artistCommentsIndex(page) {
  return get(`/api/artist/comments?page=${page}`, ARTIST_COMMENTS_INDEX)
}
