import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import FormInput from 'components/main/FormInput'
import LoadingButton from 'components/main/LoadingButton'

import { EMAIL_REGEX } from 'utils'

let UpdateEmailForm = ({ user, handleSubmit, submitting, isSuccess }) => (
  <form className="mb-2" onSubmit={handleSubmit}>
    <div className="form-group mb-3">
      <label htmlFor="email">Username</label>
      <Field name="email" component={FormInput} type="email" classes="form-control" />
    </div>

    <div className="mb-3">
      <LoadingButton
        ownProps={{ className: 'btn btn-light', type: "submit"}}
        isLoading={submitting}
        isSuccess={isSuccess}
        successText="Updated!"
      >
        Update Email
      </LoadingButton>
    </div>
  </form>
)

const validate = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = "Email is required"
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = "Email is invalid"
  }

  return errors
}

UpdateEmailForm = reduxForm({
  form: 'updateEmailForm',
  validate,
  enableReinitialize: true,
})(UpdateEmailForm)

export default connect((state, props) => ({
  initialValues: _.mapKeys(_.get(props, 'user', {}), (v, k) => _.snakeCase(k)),
}))(UpdateEmailForm)

