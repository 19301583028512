import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import { DirectUpload } from 'activestorage/src'
import Dropzone from 'react-dropzone'
import { DIRECT_UPLOAD_URL } from 'static'
import { fetchPost } from 'utils/request'

const getReadableFileSizeString = (fileSizeInBytes) => {
  let i = 0;
  let byteUnits = ['bytes', 'kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
  while (fileSizeInBytes > 1024) {
    fileSizeInBytes = fileSizeInBytes / 1024
    i++
  }
  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
}

class Upload {
  constructor(songId, file, updateObject) {
    this.songId = songId
    this.directUpload = new DirectUpload(file, DIRECT_UPLOAD_URL, this)
    this.updateObject = updateObject
  }

  beginUpload = (callback, errCallback) => {
    const promise = new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        const signedId = _.get(blob, 'signed_id')
        if (_.isNil(error) && !_.isNil(signedId)) {
          resolve(signedId)
        } else if (!_.isNil(error)) {
          reject(error)
        } else {
          reject(null)
        }
      })
    })

    this.handleUpdateStatus({
      status: 'uploading',
      progress: 0.0,
    })

    return promise.then((signedId) => {
      // SUCCESS
      fetchPost(
        `/api/songs/${this.songId}/upload_stems`,
        'PUT',
        { audio_files: [signedId] }
      ).then((res) => res.json()).then((data) => {
        if (data.errorMessage) {
          this.handleUpdateStatus({
            status: 'error',
            errorMessage: _.get(data, 'errorMessage'),
          })
        } else {
          this.handleUpdateStatus({ status: 'finished' })
        }

        if (!_.isNil(callback)) { callback() }
      })
    }, (error) => {
      // ERROR
      this.handleUpdateStatus({ status: 'error' })
      errCallback(error)
    })
  }

  handleProgress = (progressData) => {
    const total = _.get(progressData, 'total', 0)
    const loaded = _.get(progressData, 'loaded', 0)
    const percentComplete = _.round(parseFloat(loaded) / parseFloat(total) * 100.0, 2)

    this.handleUpdateStatus({
      status: 'uploading',
      progress: percentComplete,
    })
  }

  handleUpdateStatus = (update) => {
    this.updateObject.updateUploadStatus({
      [`${this.directUpload.id}`]: {
        id: `${this.directUpload.id}`,
        file: this.directUpload.file,
        ...update,
      }
    })
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener('progress', this.handleProgress)
  }

}


const FileUploadRow = ({ upload }) => {
  switch (upload.status) {
    case 'waiting':
      return <div className="list-group-item">Preparing to upload {upload.file.name}</div>
    case 'uploading':
      return (
        <div className="list-group-item">
          <div className="mb-2">
            Uploading {upload.file.name}: {_.round(upload.progress)}%
          </div>
          <div className="progress">
            <div className="progress-bar"
              role="progressbar"
              style={{'width': `${upload.progress}%`}}
              aria-valuenow={upload.progress}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      )
    case 'error':
      return (
        <div className="list-group-item list-group-item-danger">
          Error uploading {upload.file.name}: {upload.errorMessage}
        </div>
      )
    case 'finished':
      return (
        <div className="list-group-item list-group-item-success">Finished uploading {upload.file.name}</div>
      )
  }
  return null
}

let updateObject = {
  updateUploadStatus: (update) => {  },
  isUploadError: () => {  },
}

export default ({
  songId,
  onUploadFinish,
  onUploadError,
  setFileData,
  fileData,
  fileTypes,
  allowMultiple,
  isDisabled,
}) => {
  const isFilesInvalid = () => _.reduce(fileData, (memo, file) => memo || isFileInvalid(file), false)

  const [uploads, setUploads] = useState([])
  const [uploadStatuses, setUploadStatuses] = useState({})
  const [isUploading, setIsUploading] = useState(false)
  const [isUploadFinished, setIsUploadFinished] = useState(false)

  const isFileInvalid = (file) => {
    // if there are no fileTypes, then allow all
    if (_.isNil(fileTypes) || _.isEmpty(fileTypes)) { return false }

    let isValid = false // assume invalid
    // look through fileTypes for a match
    _.each(_.keys(fileTypes), (fileType) => {
      if (file.type === fileType) {
        if (_.isNil(fileTypes[fileType])) {
          isValid = true // if match has no filesize limit, it's valid!
        } else {
          isValid = file.size < fileTypes[fileType] // otherwise, it's valid if the file is below the size limit
        }
      }
    }, false)
    return !isValid
  }

  updateObject.isUploadError = () => _.reduce(uploadStatuses, (memo, u) => (memo || u.status === 'error'), false)

  updateObject.updateUploadStatus = (update) => {
    setUploadStatuses({ ...uploadStatuses, ...update })
  }

  const handleNextUpload = (index) => {
    if (_.get(uploads, 'length', 0) <= index) {
      setIsUploading(false)
      setIsUploadFinished(true)
      if (!updateObject.isUploadError()) {
        onUploadFinish()
      } else {
        onUploadError()
      }
    } else {
      const currentUpload = uploads[index]
      currentUpload.beginUpload((signedId) => {
        handleNextUpload(index+1)
      }, (error) => {
        handleNextUpload(index+1)
      })
    }
  }

  const handleUploads = (files) => {
    const mappedUploads = _.map(files, (file) => new Upload(songId, file, updateObject))
    setUploads(mappedUploads)
  }

  useEffect(() => {
    if (!isUploading && _.get(uploads, 'length', 0) > 0) {
      const mappedUploadStatuses = _.reduce(uploads, (memo, upload) => ({
        ...memo,
        [`${upload.directUpload.id}`]: {
          id: `${upload.directUpload.id}`,
          file: upload.directUpload.file,
          status: 'waiting',
          progress: 0.0,
        },
      }), {})
      setUploadStatuses(mappedUploadStatuses)
      setIsUploading(true)
    }
  }, [uploads])

  useEffect(() => {
    if (isUploading && _.get(uploads, 'length', 0) > 0 && !_.isEmpty(uploadStatuses)) {
      handleNextUpload(0)
    }
  }, [isUploading])

  return (
    <div className="mb-3">
      {_.isEmpty(uploads) && (
        <Dropzone onDrop={acceptedFiles => setFileData(acceptedFiles)} multiple={allowMultiple}>
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps()} className="mb-3 p-3 rounded border border-primary bg-light text-center">
              <input {...getInputProps()} />
              <i className="fa fa-files-o icon-large" aria-hidden="true"></i>
              <div>Drag and drop (or click here to browse your files)</div>
            </div>
          )}
        </Dropzone>
      )}

      {_.isEmpty(uploads) && (
        <div className="mb-3 list-group">
          {_.map(fileData, (f, i) => (
            <div key={`${f.name}-${i}`} className={classnames("list-group-item", { "list-group-item-danger": isFileInvalid(f) })}>
              {f.name} - {getReadableFileSizeString(f.size)}
              {isFileInvalid(f) && (
                <span className="ml-2">
                  {_.isNil(fileTypes[f.type]) ? `invalid file type` : `cannot be larger than ${getReadableFileSizeString(fileTypes[f.type])}`}
                </span>
              )}
            </div>
          ))}
        </div>
      )}

      {!_.isNil(fileData) && !_.isEmpty(fileData) && !isUploading && !isUploadFinished && (
        <button
          className="btn btn-primary mb-2"
          onClick={() => {
            if (!_.isNil(fileData) && !isFilesInvalid() && !isDisabled) { return handleUploads(fileData) }
          }}
          disabled={_.isNil(fileData) || !_.isEmpty(uploads) || isFilesInvalid() || isDisabled}
        >Upload</button>
      )}

      {!_.isEmpty(uploadStatuses) && (
        <div className="list-group mt-2">
          {_.map(uploadStatuses, (upload) => (<FileUploadRow key={upload.id} upload={upload} />))}
        </div>
      )}
    </div>
  )
}
